import React from 'react'

export default function AmountsByAllocationStatusReport() {
  const reportURL = process.env.REACT_APP_REPORT_AMOUNT_BY_ALLOCATION_STATUS_URL
  return (
    <div>
      <iframe title="Allocation Status" width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
    </div>
  )
}
