import { useEffect, useState } from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
} from "@mui/x-data-grid";
import {
    Tooltip,
    Stack,
    FormLabel,
    Input,
    Grid,
    MenuItem,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { CommonButton } from "../common/commonButton";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import instance from "../../redux/api";
import { getPaginationCount } from "../CashAllocation/utils";
import { useSelector } from "react-redux";
import { CommonSelect } from "../common";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import DialogBox from "../common/dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { GetUsers } from "../../Services/api";
export const selectStyleObj = {
    border: "1px solid #ccc",
    padding: 2,
    paddingLeft: 16,
    fontSize: 13,
    width: "100%",
    height: 38,
    borderRadius: 5,
};

//inline styles
const inlineStyles = {
    userName: {
        height: "38px",
        backgroundColor: "white",
        padding: "0px 10px",
        color: "grey",
        border: "1px solid #b8b8b8",
        borderRadius: "5px",
        boxSizing: "border-box",
        fontSize: "15px",
        fontWeight: 400,
    },
    userUpdate: {
        width: "90px",
        padding: "18px 0px",
        borderRadius: "20px",
        gap: "8px",
    },
};

const workflow_data = [
    {
        id: 1,
        step_number: 1,
        step_name: "abc",
        user_name: "User1",
        user_details: "user1@gmail.com",
    },
    {
        id: 2,
        step_number: 1,
        step_name: "abc",
        user_name: "User2",
        user_details: "user2@gmail.com",
    },
    {
        id: 3,
        step_number: 1,
        step_name: "abc",
        user_name: "User3",
        user_details: "user3@gmail.com",
    },
    {
        id: 4,
        step_number: 2,
        step_name: "def",
        user_name: "User11",
        user_details: "user11@gmail.com",
    },
];

const workflow_names = ["WF1", "WF2", "WF3", "WF4", "WF5"];
const workflowConfig = "workflowapi";
export default function WorkflowConfiguration() {
    const { userData } = useSelector((state) => state?.user);
    const toggle = useSelector((state) => state.toggleSideMenu);
    const [searchFieldsParams, setSearchFieldsParams] = useState({
        workflow_type: "WF1",
    });

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
    });
    const [paginationCount, setPaginationCount] = useState(0);
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
    const [isClearPerformed, setIsClearPerformed] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [txnMode, setTxnMode] = useState(null);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({
        step_name: "",
        user_name: "",
        user_details: "",
    });
    const [validationStatus, setValidationStatus] = useState({});
    const [addValidationStatus, setAddValidationStatus] = useState({});
    const [users, setUsers] = useState([]);

    const [workflowSteps, setWorkflowSteps] = useState([]);
    const [addUser, setAddUser] = useState({
        step_name: "",
        user_name: "",
    });

    const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
    const Container = {
        width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
        display: "flex",
        flexDirection: "column",
        gap: 8,
    };

    const currentSkipNumber = () => {
        return pageState?.page === 1
            ? 0
            : pageState?.page === 0
                ? pageState?.page
                : pageState?.page - 1;
    };

    const classes = useStyles();
    useEffect(() => {
        instance
            .get(GetUsers)
            .then((data) => {
                setUsers(data.data);
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
            });
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        // if (!value) {
        //   handleClearSearchList()
        // }
        console.log("name", name, "value", value);
        setSearchFieldsParams((v) => ({
            ...v,
            [name]: value,
        }));
    };

    useEffect(() => {
        loadData();
    }, [searchFieldsParams.workflow_type]);

    const handleClearSearchList = async () => {
        setIsSearchPerformed(false);
        setIsClearPerformed(true);
        setSearchFieldsParams({
            workflow_type: "",
        });
    };
    const isFilterBtnDisable = () => {
        let disabled = true;
        const { workflow_type } = searchFieldsParams;
        if (workflow_type === "") {
            disabled = false;
        }
        return disabled;
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setPageState((old) => ({
                ...old,
                isLoading: true,
            }));

            let queryParams = ``;

            // add search query param if the search is enabled
            if (isSearchPerformed) {
                const getSearchQueryParam = searchQueryParams();
                if (getSearchQueryParam) {
                    queryParams = `${queryParams}${getSearchQueryParam}`;
                }
            }

            const response = await instance.get(
                `${workflowConfig}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
                }${queryParams}`
            );

            setPageState((old) => ({
                ...old,
                isLoading: false,
            }));

            if (response.status === 200) {
                setPageState((old) => ({
                    ...old,
                    total: response?.data?.count,
                    data: response?.data?.data ?? [],
                }));
                const workflow_steps = workflow_data.map((ele) => ele.step_name);
                setWorkflowSteps([...new Set(workflow_steps)]);

                // setSteps(workflow_data.map(ele=>{step_number:ele.step_number))
            } else {
                setPageState((old) => ({
                    ...old,
                    total: response?.data?.count,
                    data: [],
                }));
                setPaginationCount(0);
            }
        } catch (err) {
            setPageState((old) => ({
                ...old,
                isLoading: false,
            }));
            setPaginationCount(0);
            console.error("err", err);
        }
    };

    const searchQueryParams = () => {
        let getSearchQueryParams = "";

        const { workflow_type } = searchFieldsParams;

        if (workflow_type) {
            getSearchQueryParams = `${getSearchQueryParams}&workflow_type=${workflow_type}`;
        }

        return getSearchQueryParams;
    };

    const getCurrentStepUserCount = (currentRow) => {
        const curr_step_number = currentRow.step_number;
        const user_count = workflow_data.filter(
            (ele) => ele.step_number === curr_step_number
        ).length;
        return user_count;
    };
    const handleActionButton = (currentRow, currentTxnMode) => {
        setCurrentRow(currentRow);
        setTxnMode(currentTxnMode);

        console.log("test");
        switch (currentTxnMode) {
            case "EDIT":
                setSelectedUser({
                    step_name: currentRow.step_name,
                    user_name: currentRow.user_name,
                    user_details: currentRow.user_details,
                });
                setIsOpenEditDialog(true);
                break;
            case "DELETE":
                const current_user_count = getCurrentStepUserCount(currentRow);
                if (current_user_count <= 1) {
                    toast.error("Atleast 1 user should remain in the step");
                    return;
                }
                console.log("delete user");
                setIsOpenDeleteDialog(true);
                break;
            case "ADD":
                setIsOpenAddDialog(true);
                break;
            default:
                break;
        }
    };
    const handleDeleteCancelled = () => {
        setCurrentRow(null);
        setTxnMode(null);
        setIsOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = async () => {
        const delete_id = currentRow.id;
        const response = await instance.delete("workflow", delete_id);
        if (response.status === 200) {
            toast.success("User deleted successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            loadData();
        } else {
            toast.error("User could not be deleted", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setIsOpenDeleteDialog(false);
        setCurrentRow(null);
        setTxnMode(null);
    };

    const handleInputChange = (name, value) => {
        console.log("name", name, "value", value);
        setSelectedUser((old) => ({
            ...old,
            [name]: value,
        }));
    };

    const handleAddInputChange = (name, value) => {
        console.log("name", name, "value", value);
        setAddUser((old) => ({
            ...old,
            [name]: value,
        }));
    };

    const isUpdateButtonDisabled = () => {
        let button_disabled = false;
        if (selectedUser.user_name === "" || selectedUser.user_details === "") {
            button_disabled = true;
        }
        return button_disabled;
    };

    const isAddButtonDisabled = () => {
        let button_disabled = false;
        if (addUser.step_name === "" || addUser.user_name === "") {
            button_disabled = true;
        }
        return button_disabled;
    };

    const validateUser = () => {
        const fieldValidations = {};
        if (
            !selectedUser ||
            !selectedUser.step_name ||
            selectedUser.step_name === ""
        ) {
            fieldValidations.step_name = "This field is required";
        }
        if (
            !selectedUser ||
            !selectedUser.user_name ||
            selectedUser.user_name === ""
        ) {
            fieldValidations.user_name = "This field is required";
        }
        if (
            !selectedUser ||
            !selectedUser.user_details ||
            selectedUser.user_details === ""
        ) {
            fieldValidations.user_details = "This field is required";
        }
        if (!selectedUser || !/^\S+@\S+\.\S+$/.test(selectedUser.user_details)) {
            fieldValidations.user_details = "Please enter a valid email address";
        }
        setValidationStatus(fieldValidations);
        return Object.keys(fieldValidations).length > 0;
    };

    const addValidateUser = () => {
        const fieldValidations = {};
        if (!addUser || !addUser.step_name || addUser.step_name === "") {
            fieldValidations.step_name = "This field is required";
        }
        if (!addUser || !addUser.user_name || addUser.user_name === "") {
            fieldValidations.user_name = "This field is required";
        }
        setAddValidationStatus(fieldValidations);
        return Object.keys(fieldValidations).length > 0;
    };

    const handleUpdateButton = async () => {
        const validateResult = validateUser();
        if (validateResult) {
            return;
        } else {
            //call api
            const update_user_id = currentRow.id;
            await instance.patch();
            //display success/error message
        }
    };

    const handleEditDialogClose = () => {
        setIsOpenEditDialog(false);
        setCurrentRow(null);
        setSelectedUser({
            step_name: "",
            user_name: "",
            user_details: "",
        });
        setTxnMode(null);
    };

    const handleAddUser = async () => {
        console.log("add user");
        const validateResult = addValidateUser();
        if (validateResult) {
            return;
        } else {
            //call api

            await instance.post();
            //display success/error message
        }
    };

    useEffect(() => {
        setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
    }, [pageState?.total]);

    const columns = [
        {
            field: "step_number",
            headerName: "Step Number",
            headerAlign: "center",
            flex: 0.8,
        },
        {
            field: "step_name",
            headerName: "Step Name",
            headerAlign: "center",
            flex: 0.8,
        },
        {
            field: "user_name",
            headerName: "User Name",
            headerAlign: "center",
            flex: 0.8,
        },
        {
            field: "user_details",
            headerName: "User Details",
            headerAlign: "center",
            flex: 0.8,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 0.6,
            description: "This column has a value getter and is not sortable.",
            cellClassName: "actions",
            getActions: ({ row }) => {
                let actionsList = [];

                actionsList.push(
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        }
                        className="textPrimary"
                        label="Edit"
                        onClick={() => handleActionButton(row, "EDIT")}
                        color="inherit"
                    />
                );

                actionsList.push(
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="delete"
                        className="textPrimary"
                        onClick={() => handleActionButton(row, "DELETE")}
                        color="inherit"
                    />
                );

                // actionsList.push(
                //   <GridActionsCellItem
                //     icon={
                //       <Tooltip title="Workflow Status">
                //         <SettingsTwoToneIcon className="action-icon" />
                //       </Tooltip>
                //     }
                //     label="Workflow Status"
                //     className="textPrimary"
                //     onClick={() => handleActionButton(row, "WORKFLOW")}
                //     color="inherit"
                //   />
                // );
                return actionsList;
            },
        },
    ];
    return (
        <div style={Container}>
            <Grid
                container
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={"12px"}
            >
                <Grid
                    container
                    xs={11}
                    gap={"6px"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    <Grid
                        item
                        lg={2}
                        xs={2}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"4px"}
                    >
                        <FormLabel>Workflow Type</FormLabel>

                        <Select
                            style={{ ...selectStyleObj }}
                            name="workflow_type"
                            onChange={handleFilterChange}
                            value={searchFieldsParams.workflow_type}
                        >
                            <MenuItem value={""} disabled selected>
                                Select Workflow Type
                            </MenuItem>
                            {workflow_names.map((ele) => (
                                <MenuItem key={ele} value={ele}>
                                    {ele}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid
                    xs={1}
                    container
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignSelf={"flex-end"}
                >
                    <Grid item>
                        <FormLabel></FormLabel>
                        <CommonButton
                            text="Add"
                            style={{ width: "80px", marginBottom: "5px" }}
                            handleClick={() => {
                                handleActionButton(null, "ADD");
                            }}
                            className="addFileBtn"
                            hoverColor="#EE3F00"
                            disabled={pageState?.isLoading}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <div
                style={{
                    height: "calc(100vh - 270px)",
                    position: "relative",
                    display: "flex",
                    flexDirection: "row-reverse",
                }}
            >
                <DataGrid
                    className={classes.pagination}
                    loading={pageState?.isLoading}
                    rows={workflow_data} //{pageState?.data ?? []}
                    getRowId={(row) => row.id}
                    rowCount={4} //{pageState?.total}
                    columns={columns}
                    disableRowSelectionOnClick
                    keepNonExistentRowsSelected
                    pagination
                    paginationMode="server"
                    disableSelectionOnClick
                    page={pageState.page - 1}
                    // componentsProps={{
                    //   pagination: { classes: null }
                    // }}
                    pageSize={pageState?.pageSize ?? 10}
                    onPageSizeChange={(newPageSize) => {
                        console.log("page size changed", newPageSize);
                        return setPageState((old) => {
                            setPaginationCount(
                                getPaginationCount(pageState?.total, newPageSize)
                            );
                            return { ...old, pageSize: newPageSize, page: 1 };
                        });
                    }}
                    hideFooter
                    hideFooterPagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    components={{
                        Toolbar: GridToolbarContainer,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Uploaded Files
                            </Stack>
                        ),
                    }}
                />
            </div>
            <Dialog
                open={isOpenEditDialog}
                onClose={() => handleEditDialogClose()}
                fullWidth
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: "500px",
                    },
                }}
            >
                <div>
                    <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
                        {"Edit User"}
                        <IconButton
                            aria-label="close"
                            onClick={handleEditDialogClose}
                            style={{ position: "absolute", right: 12, top: 12 }}
                        >
                            <CloseIcon className="CloseIconStyle" />
                        </IconButton>
                        <hr style={{ width: "100%" }} />
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            // overflowX: "hidden",
                            padding: "0px 24px 5px 24px",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "12px",
                        }}
                    >
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                            mt={"10px"}
                            padding={"5px"}
                            width={"100%"}
                        >
                            <FormLabel id="type-label">Step Name</FormLabel>
                            <CommonSelect
                                name="Step Name"
                                value={selectedUser.step_name}
                                handleChange={(event, value, reason) =>
                                    handleInputChange("step_name", value)
                                }
                                options={workflowSteps?.map((option) => option)}
                            />
                            {validationStatus?.step_name && (
                                <span style={{ color: "red" }}>
                                    {validationStatus?.step_name}
                                </span>
                            )}
                        </Grid>
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                            mt={"10px"}
                            padding={"5px"}
                            width={"100%"}
                        >
                            <FormLabel id="type-label">User Name</FormLabel>
                            <Input
                                type="text"
                                value={selectedUser.user_name}
                                onChange={(e) => {
                                    console.log("erty");
                                    handleInputChange("user_name", e.target.value);
                                }}
                                style={inlineStyles.userName}
                                placeholder="Enter Name Here"
                                disabled={false}
                            />
                            {validationStatus?.user_name && (
                                <span style={{ color: "red" }}>
                                    {validationStatus?.user_name}
                                </span>
                            )}
                        </Grid>
                        <Grid
                            item
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                            mt={"10px"}
                            padding={"5px"}
                            width={"100%"}
                        >
                            <FormLabel id="type-label">User Details</FormLabel>
                            <Input
                                type="text"
                                value={selectedUser.user_details}
                                onChange={(e) =>
                                    handleInputChange("user_details", e.target.value)
                                }
                                style={inlineStyles.userName}
                                placeholder="Enter Details Here"
                                disabled={false}
                            />
                            {validationStatus?.user_details && (
                                <span style={{ color: "red" }}>
                                    {validationStatus?.user_details}
                                </span>
                            )}
                        </Grid>
                    </DialogContent>
                </div>
                <DialogActions>
                    {txnMode === "EDIT" && (
                        <CommonButton
                            sx={inlineStyles.userUpdate}
                            text="Update"
                            handleClick={() => handleUpdateButton()}
                            disabled={isUpdateButtonDisabled()}
                            hoverColor="#FF5A01"
                        />
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenAddDialog}
                onClose={() => setIsOpenAddDialog(false)}
                fullWidth
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: "500px",
                    },
                }}
            >
                <div>
                    <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
                        {"Add User"}
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setIsOpenAddDialog(false);
                            }}
                            style={{ position: "absolute", right: 12, top: 12 }}
                        >
                            <CloseIcon className="CloseIconStyle" />
                        </IconButton>
                        <hr style={{ width: "100%" }} />
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            // overflowX: "hidden",
                            padding: "0px 24px 5px 24px",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "12px",
                        }}
                    >
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                            mt={"10px"}
                            padding={"5px"}
                            width={"100%"}
                        >
                            <FormLabel id="type-label">Step Name</FormLabel>
                            <CommonSelect
                                name="Step Name"
                                value={addUser.step_name}
                                handleChange={(event, value, reason) =>
                                    handleAddInputChange("step_name", value)
                                }
                                options={workflowSteps?.map((option) => option)}
                            />
                            {addValidationStatus?.step_name && (
                                <span style={{ color: "red" }}>
                                    {addValidationStatus?.step_name}
                                </span>
                            )}
                        </Grid>
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"5px"}
                            mt={"10px"}
                            padding={"5px"}
                            width={"100%"}
                        >
                            <FormLabel id="type-label">User Name</FormLabel>
                            <CommonSelect
                                name="assigning"
                                value={addUser.user_name}
                                handleChange={(event, value, reason) =>
                                    handleAddInputChange("user_name", value)
                                }
                                options={users?.map((option) => option?.user_name)}
                            />
                            {addValidationStatus?.user_name && (
                                <span style={{ color: "red" }}>
                                    {addValidationStatus?.user_name}
                                </span>
                            )}
                        </Grid>
                    </DialogContent>
                </div>
                <DialogActions>
                    {txnMode === "ADD" && (
                        <CommonButton
                            sx={inlineStyles.userUpdate}
                            text="Add"
                            handleClick={() => handleAddUser()}
                            disabled={isAddButtonDisabled()}
                            hoverColor="#FF5A01"
                        />
                    )}
                </DialogActions>
            </Dialog>
            <DialogBox
                title="Delete Confirmation"
                body="Are you sure you want to delete this item?"
                confirmText="Delete"
                isOpen={isOpenDeleteDialog}
                onCancel={handleDeleteCancelled}
                onConfirm={handleDeleteConfirmed}
            />
        </div>
    );
}
