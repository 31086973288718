import React from 'react';
import MyRoutes from './MyRoutes';
import './App.css';
import Navbar from './Components/Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Navbar />
      <MyRoutes />
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
      />
    </div>
  );
};

export default App;
