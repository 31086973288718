import React, { useState } from "react";
import API from '../../../redux/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getUsersList } from '../../../redux/slice/usersListSlice';
import DialogBox from '../../../Components/common/dialog';

const SwitchStatusDialog = ({ id, status, isOpen, setSwitchDialog }) => {
  // Hooks
  const dispatch = useDispatch();

  // Local State
  const [isDisableConfirm, setIsDisableConfirm] = useState(false);

  const statusSwitch = status === 'Active' ? 'Inactive' : 'Active';
  
  const handleClose = () => {
    setSwitchDialog({ id: '', status: '', isOpen: false });
  };

  const handleConfirm = async () => {
    setIsDisableConfirm(true);
    const response = await API.patch(`users/users_api/user/${id}/`, {
      id: id,
      status: statusSwitch,
    });
    setIsDisableConfirm(false);
    handleClose();

    if (response.status === 200) {
      toast.success('User status updated successfully', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      dispatch(getUsersList());
    } else {
      toast.error('Error updating user status', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <DialogBox
      title='Confirmation'
      body={`Are you sure you want to ${statusSwitch} this user`}
      confirmText='Submit'
      isOpen={isOpen}
      onCancel={handleClose}
      onConfirm={handleConfirm}
      isDisableConfirm={isDisableConfirm}
    />
  );
};

export default SwitchStatusDialog;
