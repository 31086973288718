import { useState } from "react";
import { styled } from "@mui/system";
import { TextValidator } from "react-material-ui-form-validator";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "../../assets/Images/Vector.svg";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
  position: "relative",
}));

export function PasswordInput({
  validators = [],
  value,
  handleChange,
  errorMessages = [],
  name,
  width = "80%",
  placeholder = "Enter your password",
}) {
  const [isVisible, setIsVisible] = useState(false);
  const Icon = isVisible ? VisibilityIcon : VisibilityOffIcon;
  return (
    <TextField
      sx={{ mb: "12px", width: "100%" }}
      variant="outlined"
      placeholder={placeholder}
      size="small"
      onChange={handleChange}
      name={name || "password"}
      value={value || ""}
      validators={[
        "required",
        "matchRegexp:^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*?&_=\\\\`~#^/|(|)|-])[A-Za-z\\d@$!%*?&_=\\\\`~#^/|(|)|-]{8,}$",
        ...validators,
      ]}
      errorMessages={[
        "Required field",
        "password must meet the requirements",
        ...errorMessages,
      ]}
      InputProps={{
        autoComplete: "new-password",
        startAdornment: (
          <InputAdornment position="start">
            <img alt="passwordicon" src={PasswordIcon} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              disableRipple={true}
              edge="end"
              onClick={() => setIsVisible(!isVisible)}
              sx={{ "&:focus": { outline: "none" } }}
            >
              <Icon />
            </IconButton>
          </InputAdornment>
        ),
        style: { color: "black" },
      }}
      type={isVisible ? "text" : "password"}
      style={{
        backgroundColor: "white",
        width,
        color: "black",
      }}
    />
  );
}
