import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CommonSelect } from "../common";

//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: "#262671",
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "50px",
  },
  TableCells: {
    color: "black",
    fontSize: "12px",
    height: "44px",
    padding: "0px",
  },
  TableBodyRows: {
    border: "1px solid #ddd",
    marginTop: "10px",
    paddingTop: "15px",
    verticalAlign: "top",
  },
  TableBodyRowCells: {
    height: "20px",
    padding: "0px",
  },
  PaymentReceiveDate: {
    width: "100px",
    height: "33px",
    padding: "0px",
  },
  BrokerBranchTableCell: {
    height: "20px",
    padding: "3px",
  },
  ReceivableAmountTableCell: {
    height: "20px",
    padding: "3px",
  },
  PaymentCurrencyCodeTableCell: {
    height: "20px",
    padding: "3px",
  },
  BankExchangeRateTableCell: {
    height: "20px",
    padding: "3px",
  },
  BankExchangeChargesTableCell: {
    height: "20px",
    padding: "3px",
  },
  PaymentReferenceTableCell: {
    height: "20px",
    padding: "3px",
  },
  PaymentReference: {
    position: "relative",
    width: "123px",
    height: "32",
  },
  MoreVertIconStyle: {
    color: "FF5A01",
    background: "transparent",
    cursor: "pointer",
    transform: "rotate(90deg)",
  },
  BankChargesTableCell: {
    height: "20px",
    padding: "3px",
  },
};
export const AddBnkTransactionDetails = ({
  brokerBranches,
  dropdownValuesCurreny,
  selectAll,
  handleSelectAll,
  handleInputChange,
  handleCheckboxChange,
  handleBrokerBranchDropdown,
  handleBlur,
  rows,
  viewMode,
  validationStatus,
  handlePaymentReferenceOpenDialog,
  selectedRows,
}) => {
  return (
    <Table>
      <TableHead style={InlineStyles.TableHeader}>
        <TableRow style={{ backgroundColor: "#FFC000" }}>
          <TableCell style={InlineStyles.TableCells}>
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ color: "black" }}
            />
          </TableCell>
          <TableCell className="TableCell">
            Payment <br />
            Receive Date
          </TableCell>
          <TableCell className="TableCell">Broker Branch</TableCell>
          <TableCell className="TableCell">
            Receivable <br />
            Amount
          </TableCell>
          <TableCell className="TableCell">
            Original <br />
            Currency Code
          </TableCell>
          <TableCell className="TableCell">
            {" "}
            Exchange <br />
            Rate
          </TableCell>
          <TableCell className="TableCell">
            {" "}
            Exchange <br /> Charges
          </TableCell>
          <TableCell className="TableCell">
            Payment <br />
            Reference
          </TableCell>
          <TableCell className="TableCell">
            Bank <br />
            Charges
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index} style={InlineStyles.TableBodyRows}>
            <TableCell sx={InlineStyles.TableBodyRowCells}>
              <Checkbox
                checked={selectedRows[index] || false}
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
              />
            </TableCell>
            <TableCell sx={{ padding: "3px" }}>
              <input
                type="date"
                value={
                  row.Payment_Receive_Date
                    ? row.Payment_Receive_Date.substring(0, 10)
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "Payment_Receive_Date",
                    e.target.value
                  )
                }
                style={InlineStyles.PaymentReceiveDate}
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.Payment_Receive_Date && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Payment_Receive_Date}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.BrokerBranchTableCell}>
              <CommonSelect
                name="brokerBranch"
                placeholder="Select Broker Branch"
                value={row.Broker_Branch}
                handleChange={(event, value, reason) =>
                  handleBrokerBranchDropdown(
                    index,
                    "Broker_Branch",
                    value,
                    reason,
                    event
                  )
                }
                options={brokerBranches?.map((option) => option)}
              />
              {validationStatus[index]?.Broker_Branch && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Broker_Branch}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.ReceivableAmountTableCell}>
              <TextField
                onChange={(e) =>
                  handleInputChange(index, "Receivable_Amount", e.target.value)
                }
                value={row.Receivable_Amount}
                onBlur={(e) =>
                  handleBlur(index, "Receivable_Amount", e.target.value)
                }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "text" to handle both negative and positive numbers
                InputProps={{
                  inputProps: {
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^-?\d*\.?\d{0,2}$/; // Updated regular expression for numeric input with up to 2 decimal places and negative numbers
                      if (regex.test(value) || value === "" || value === "-") {
                        handleInputChange(index, "Receivable_Amount", value);
                      }
                    },
                  },
                }}
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.Receivable_Amount && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Receivable_Amount}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.PaymentCurrencyCodeTableCell}>
              <CommonSelect
                disabled={viewMode}
                handleChange={(event, value, reason) =>
                  handleInputChange(
                    index,
                    "Payment_Currency_Code",
                    value,
                    reason,
                    event
                  )
                }
                name="payment currncy code"
                // Map through your list of dropdown values
                options={dropdownValuesCurreny?.map(
                  (option) => option.currency_code
                )}
                placeholder="Select Currency"
                value={row.Payment_Currency_Code}
              />
              {validationStatus[index]?.Payment_Currency_Code && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Payment_Currency_Code}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.BankExchangeRateTableCell}>
              <TextField
                value={row.Bank_Exchange_Rate}
                onChange={(e) =>
                  handleInputChange(index, "Bank_Exchange_Rate", e.target.value)
                }
                onBlur={(e) =>
                  handleBlur(index, "Bank_Exchange_Rate", e.target.value)
                }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "number"
                InputProps={{
                  inputProps: {
                    min: 0, // Set minimum value to 0 if needed
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      if (regex.test(value) || value === "") {
                        handleInputChange(index, "Bank_Exchange_Rate", value);
                      }
                    },
                  },
                }}
                disabled={viewMode}
              />
              {validationStatus[index]?.Bank_Exchange_Rate && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Bank_Exchange_Rate}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.BankExchangeChargesTableCell}>
              <TextField
                value={row.Bank_Exchange_Charges}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "Bank_Exchange_Charges",
                    e.target.value
                  )
                }
                onBlur={(e) =>
                  handleBlur(index, "Bank_Exchange_Charges", e.target.value)
                }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "number"
                InputProps={{
                  inputProps: {
                    min: 0, // Set minimum value to 0 if needed
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      if (regex.test(value) || value === "") {
                        handleInputChange(
                          index,
                          "Bank_Exchange_Charges",
                          value
                        );
                      }
                    },
                  },
                }}
                disabled={viewMode}
              />
              {validationStatus[index]?.Bank_Exchange_Charges && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Bank_Exchange_Charges}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.PaymentReferenceTableCell}>
              <TextField
                value={row.Payment_Reference}
                onChange={(e) =>
                  handleInputChange(index, "Payment_Reference", e.target.value)
                }
                sx={InlineStyles.PaymentReference}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
                InputProps={{
                  endAdornment: (
                    <MoreVertIcon
                      onClick={() =>
                        handlePaymentReferenceOpenDialog(
                          index,
                          row.Payment_Reference
                        )
                      }
                      style={InlineStyles.MoreVertIconStyle}
                    />
                  ),
                }}
              />
              <br />
              {validationStatus[index]?.Payment_Reference && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Payment_Reference}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.BankChargesTableCell}>
              <TextField
                value={row.Bank_Charges}
                onChange={(e) =>
                  handleInputChange(index, "Bank_Charges", e.target.value)
                }
                onBlur={(e) =>
                  handleBlur(index, "Bank_Charges", e.target.value)
                }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "number"
                InputProps={{
                  inputProps: {
                    min: 0, // Set minimum value to 0 if needed
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      if (regex.test(value) || value === "") {
                        handleInputChange(index, "Bank_Charges", value);
                      }
                    },
                  },
                }}
                disabled={viewMode}
              />
              {validationStatus[index]?.Bank_Charges && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.Bank_Charges}
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
