import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { workflowBankTransaction } from "../../Services/api";

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

const UpdateGenericWorkflowDialog = ({
  openUpdateGenericWorkflowDialog,
  handleUpdateGenericWorkflowDialog,
  workflowName,
  currentWorkflowDetails = {},
}) => {
  const { userData } = useSelector((state) => state?.user);

  const [fieldsData, setFieldsData] = useState({
    comments: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFieldsData({
      ...fieldsData,
      [fieldName]: value,
    });
  };

  const handleActionButton = async (buttonType) => {
    const { id, current_step, bank_txn_id } = currentWorkflowDetails;

    const body = {
      step_name: current_step?.toLowerCase(),
      bank_txn_id: bank_txn_id,
      comments: fieldsData?.comments ?? "",
      status: buttonType,
      user_id: userData?.id,
    };

    try {
      const response = await instance.patch(
        `${workflowBankTransaction}${id}/`,
        body
      );
      if (buttonType?.toLowerCase() === "approve") {
        toast.success("Workflow Approved Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handleUpdateGenericWorkflowDialog(false);
      } if (buttonType?.toLowerCase() === "reject") {
        toast.success("Workflow Rejected successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to approve Workflow", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  // const displayNewRequestedAmount = () => {
  //   return workflowTxnData?.writeoff_amt ?? 0;
  // };

  const displayAddedComments = () => {
    const currentUserId = userData?.id;
    const getWorkflowSteps =
      currentWorkflowDetails?.workflow_json_data?.workflow_step;

    let currentUserComments = "";
    for (let i = 0; i < getWorkflowSteps?.length; i++) {
      const stepInfo = getWorkflowSteps[i];
      const userInfo = stepInfo?.user;
      const getIndex = userInfo?.findIndex(
        (item) => item?.id === currentUserId
      );
      if (getIndex !== -1) {
        currentUserComments = stepInfo?.comments;
        break;
      }
    }

    return currentWorkflowDetails?.workflow_status?.toLowerCase() === "in process"
      ? currentUserComments
      : fieldsData?.comments ?? "";
  };

  const isWorkflowInProcessAndLoggedInUserExists = () => {
    const currentUserId = userData?.id;
    const { current_step, workflow_status } = currentWorkflowDetails;
    const getWorkflowSteps = currentWorkflowDetails?.workflow_json_data?.workflow_step;

    const getCurrentStepDetails = getWorkflowSteps?.filter((item) => item?.step_name === current_step);

    let isLoggedInUserExists = false;
    if (workflow_status?.toLowerCase() === 'in process') {
      if (getCurrentStepDetails?.length === 1) {
        const currentStepUsersList = getCurrentStepDetails[0]?.user;
        const getIndex = currentStepUsersList?.findIndex(item => item?.id === currentUserId);
        // Enable comments field if logged in user is part of the current step users list
        if (getIndex != -1) {
          isLoggedInUserExists = true;
        }
      }
    }
    return isLoggedInUserExists;
  }

  const handleCommentsAreaVisibility = () => {
    return !isWorkflowInProcessAndLoggedInUserExists()
  };

  const enableApproveAndRejectButtons = () => {
    return isWorkflowInProcessAndLoggedInUserExists();
  };

  const displyDialogTitle = () => {
    let title = "";

    switch (workflowName) {
      case "WF_WRITEOFF":
        title = "Write Off";
        break;
      case 'WF_REFUND':
        title = "Refund";
        break;
      case 'WF_CORRECTION_TYPES':
        title = "Correction Types";
        break;
      case 'WF_CORRECTIVE_TRANSFER':
        title = "Corrective Transfer";
        break;
      case 'WF_CROSS_ALLOCATION':
        title = "Cross Allocation";
        break;
      case 'WF_CFI':
        title = "CFI";
        break;
      case 'WF_PAYMENT_NOVARIANCE':
        title = 'Payment No Variance';
      default:
        break;
    }

    return title;
  };

  return (
    <Dialog open={openUpdateGenericWorkflowDialog} fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
        {displyDialogTitle()}
        <IconButton
          aria-label="close"
          onClick={() => handleUpdateGenericWorkflowDialog()}
          style={{ position: "absolute", right: 12, top: 12 }}
        >
          <CloseIcon style={CloseIconStyle} />
        </IconButton>
        <hr style={{ width: "100%" }} />
      </DialogTitle>

      <DialogContent
        style={{
          height: "auto",
          width: "auto",
          padding: "10px",
          paddingRight: "20px",
        }}
      >
        {/* <Box
          style={{
            paddingLeft: "12px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "55px",
          }}
        >
          <Typography>Amount</Typography>
          <TextField
            value={displayNewRequestedAmount()}
            placeholder="Enter new amount"
            size="small"
            disabled={true}
          />
        </Box> */}
        <Box
          style={{
            paddingLeft: "12px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography>Comments</Typography>
          <textarea
            rows="5"
            style={{
              height: "60px",
              minWidth: "455px",
              padding: "5px",
              boerderRadius: "5px",
              backgroundColor: handleCommentsAreaVisibility() ? "#afabaa" : "#ffffff",
            }}
            onChange={(e) => handleInputChange("comments", e.target.value)}
            placeholder="Add reason"
            disabled={handleCommentsAreaVisibility()}
            value={displayAddedComments()}
          >
          </textarea>
        </Box>

        {Object.keys(currentWorkflowDetails)?.length > 0 && (
          <Box
            style={{
              backgroundColor: "#afabaa",
              margin: "20px 0px 0px 10px",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <Typography style={{ color: "#000000" }}>
              Workflow Name :{" "}
              {currentWorkflowDetails?.workflow_json_data?.workflow_name}
            </Typography>
            <Typography style={{ color: "#000000" }}>
              Workflow Status : {currentWorkflowDetails?.workflow_status}
            </Typography>
            {currentWorkflowDetails?.current_step && (
              <Typography style={{ color: "#000000" }}>
                Current Step : {currentWorkflowDetails?.current_step}
              </Typography>
            )}
            <br />
            <DisplayStepsDetails
              stepsData={
                currentWorkflowDetails?.workflow_json_data?.workflow_step
              }
            />
          </Box>
        )}

        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={() => handleUpdateGenericWorkflowDialog()}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          {enableApproveAndRejectButtons() && (
            <>
              <CommonButton
                text="Reject"
                hoverColor="#FF5A01"
                handleClick={() => handleActionButton("Reject")}
                disabled={!fieldsData?.comments}
              />
              <CommonButton
                text="Approve"
                hoverColor="#FF5A01"
                handleClick={() => handleActionButton("Approve")}
                disabled={!fieldsData?.comments}
              />
            </>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateGenericWorkflowDialog;

const DisplayStepsDetails = ({ stepsData }) => {
  const formatStepUserNames = (currentStepData) => {
    let reviewerUserNames = "";
    currentStepData?.user?.forEach((user, index) => {
      const item = user?.user_name;
      if (index === 0) {
        reviewerUserNames = `${item}`;
      } else {
        reviewerUserNames = `${reviewerUserNames}, ${item}`;
      }
    });
    return reviewerUserNames;
  };

  return (
    <>
      {stepsData.length > 0 &&
        stepsData.map((item, index) => {
          return (
            <div id={`step_${index}_details`}>
              <Typography style={{ color: "#000000" }}>
                Step Name : {item?.step_name}
              </Typography>
              <Typography style={{ color: "#000000", marginTop: "5px" }}>
                <span style={{ textTransform: "capitalize" }}>
                  {item?.step_name}
                </span>{" "}
                Comments : {item?.comments}
              </Typography>
              <Typography style={{ color: "#000000" }}>
                <span style={{ textTransform: "capitalize" }}>
                  {item?.step_name}
                </span>{" "}
                Name : {formatStepUserNames(item)}
              </Typography>
              <Typography style={{ color: "#000000" }}>
                Date/Time : {item?.ctime}
              </Typography>
              <Typography style={{ color: "#000000" }}>
                Status : {item?.status}
              </Typography>
              <br />
            </div>
          );
        })}
    </>
  );
};
