import {
  Box,
  FormLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { getBankReconciliationTable } from "../../Services/api";
import { useEffect, useState } from "react";
import {
  getAccountsList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import instance from "../../redux/api";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { numberFormatter } from "../../utils/generalFunctions";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "../../assets/scss/common.scss";
import { getPaginationCount } from "../CashAllocation/utils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export const BankReconciliation = () => {
  const toggle = useSelector((state) => state.toggleSideMenu);
  const accountsList = useSelector(
    (state) => state?.bankStatmentTransactions?.accountsList
  );
  const { userData } = useSelector((state) => state?.user);

  const dispatch = useDispatch();

  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Receiving_Bank_Account: "",
  });

  const [tabValue, setTabValue] = useState(1);

  //For Date picker
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [isBankAccountNoDeSelected, setIsBankAccountNoDeSelected] =
    useState(false);

  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [openCommentsDialog, setOpenCommentsDialog] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [dialogCommentsValue, setDialogCommentsValue] = useState("");
  const [oldConcatenatedComment, setOldConcatenatedComment] = useState("");

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  useEffect(() => {
    setPaginationCount(
      getPaginationCount(pageState?.total, pageState?.pageSize)
    );
  }, [pageState?.pageSize, pageState?.total]);

  const loadData = async (variance = tabValue) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${
        pageState?.pageSize
      }`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      const response = await instance.get(
        `${getBankReconciliationTable}?variance=${variance}&${queryParams}`
      );

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleClearSearchList = async () => {
    setIsClearPerformed(true);
    setIsSearchPerformed(false);
    // clear fields
    setSearchFieldsPrams({
      Receiving_Bank_Account: "",
    });
    setFromDateValue("");
    setToDateValue("");
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Receiving_Bank_Account } = searchFieldsPrams;
    if (
      Receiving_Bank_Account !== "" ||
      fromDateValue !== "" ||
      toDateValue !== ""
    ) {
      disabled = false;
    }
    return disabled;
  };

  const classes = useStyles();

  const handleTabChange = async (event, newValue) => {
    setTabValue(newValue);
    setPageState((old) => ({
      ...old,
      page: 1,
    }));
  };

  const columns = [
    {
      headerName: "",
      width: 50,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <RadioButtonCheckedIcon
          sx={{
            color: params?.row?.migrated_data === 1 ? "#808080" : "#FFBF00",
          }}
        />
      ),
    },
    {
      field: "file_date",
      headerName: "Payment Received Date",
      headerAlign: "center",
      width: 170,
      valueFormatter: (params) => {
        // Format the date using date-fns or any other date formatting library
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
    {
      field: "Entity",
      headerName: "Entity",
      headerAlign: "center",
      width: 80,
      valueGetter: (params) => params?.row?.bank_details?.entity_name,
      renderCell: (params) => (
        <Tooltip title={params?.row?.bank_details?.entity_name}>
          <span>{params?.row?.bank_details?.entity_name.substr(0, 10)}</span>
        </Tooltip>
      ),
    },
    {
      field: "currency",
      headerName: "Bank Currency",
      headerAlign: "center",
      width: 130,
      valueGetter: (params) => params?.row?.bank_details?.currency,
      renderCell: (params) => {
        return <span>{params?.row?.bank_details?.currency}</span>;
      },
    },
    {
      field: "bank_account_no",
      headerName: "Bank Account",
      headerAlign: "center",
      width: 110,
    },
    {
      field: "credit_amount",
      headerName: "Bank Stmt Amount(CR)",
      headerAlign: "center",
      width: 175,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "ct_amount",
      headerName: "Amount on Cash Tracker",
      headerAlign: "center",
      width: 180,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "ct_amount_car",
      headerName: "Variance",
      headerAlign: "center",
      width: 100,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "bank_charges",
      headerName: "Bank Charges (CR)",
      headerAlign: "center",
      width: 165,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "ct_bank_charges",
      headerName: "Bank Charges (CT)",
      headerAlign: "center",
      width: 165,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "ct_bank_charges_var",
      headerName: "Bank Charges (Var)",
      headerAlign: "center",
      width: 165,
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      cellClassName: "amount-table-cell",
    },
    {
      field: "ct_category",
      headerName: "Category",
      headerAlign: "center",
      width: 130,
    },
    {
      field: "ct_comments",
      headerName: "Comments",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          onClick={() =>
            handleCommentsOpenDialog(params?.row?.id, params?.row?.ct_comments)
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: 200,
              gap: 5,
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textWrap: "nowrap",
              }}
            >
              {params?.row?.ct_comments?.toLowerCase() === "nan"
                ? ""
                : params?.row?.ct_comments}
            </div>
            <MoreHorizIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleCommentsOpenDialog = (currentRowId, currentRowCommentData) => {
    setOpenCommentsDialog(true);
    setCurrentRowId(currentRowId);
    setOldConcatenatedComment(currentRowCommentData ?? "");
  };

  const handleCloseDialogs = () => {
    setOpenCommentsDialog(false);
    setDialogCommentsValue("");
    setCurrentRowId(null);
  };

  const handleDialogInputChange = (e) => {
    setDialogCommentsValue(e.target.value);
  };

  const handleSaveComments = () => {
    setOpenCommentsDialog(false);
    let updatedComment = ``;
    if (dialogCommentsValue) {
      let currentTimestamp = new Date();
      currentTimestamp = dayjs(currentTimestamp).format("DD-MM-YYYY HH:mm:ss");
      let newCommentString = `${currentTimestamp} - ${userData.user_name}\n${dialogCommentsValue}`;
      updatedComment = `${newCommentString}\n\n${oldConcatenatedComment}`;
    } else {
      updatedComment = `${oldConcatenatedComment}`;
    }
    console.log("updated comment", updatedComment);

    handleCommentsChange(updatedComment);
    handleCloseDialogs();
  };

  const handleCommentsChange = async (updatedComment) => {
    const payload = {
      id: currentRowId,
      ct_comments: updatedComment,
    };
    const response = await instance.patch(
      `bankmanagement/bank_reconciliation/${currentRowId}/`,
      payload
    );
    if (response.status === 201) {
      toast.success("Comment successfully saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      loadData();
    } else {
      toast.error("Comment could not be saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const bankRecoiliationContainer = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    height: "525px",
  };
  const bankReconciliationText = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "400",
    marginTop: 5,
  };

  const handleBankTnxSelectAccountDropdown = (name, value, reason) => {
    if (reason === "clear") {
      setIsBankAccountNoDeSelected(true);
    } else {
      setIsBankAccountNoDeSelected(false);
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        [name]: value ?? "",
      });
    }
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { Receiving_Bank_Account } = searchFieldsPrams;
    if (Receiving_Bank_Account) {
      getSearchQueryParams = `${getSearchQueryParams}&accountNo=${Receiving_Bank_Account}`;
    }

    let formattedFromDateValue = "",
      formattedToDateValue = "";

    if (fromDateValue && toDateValue) {
      formattedFromDateValue = format(fromDateValue, "dd/MM/yyyy");
      formattedToDateValue = format(toDateValue, "dd/MM/yyyy");
      getSearchQueryParams = `${getSearchQueryParams}&fromDate=${formattedFromDateValue}&toDate=${formattedToDateValue}`;
    }
    return getSearchQueryParams;
  };

  const handleSearchReconciliationList = () => {
    setIsSearchPerformed(true);
  };

  useEffect(() => {
    loadData();
  }, [
    tabValue,
    isSearchPerformed,
    pageState?.pageSize,
    pageState?.page,
    isBankAccountNoDeSelected,
    isClearPerformed,
  ]);

  useEffect(() => {
    dispatch(getAccountsList());
  }, []);

  return (
    <Box style={bankRecoiliationContainer}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={bankReconciliationText}>
          <Typography
            variant="h3"
            style={{
              color: "#FF5A01",
              fontSize: "24px",
              margin: "10px 0px 15px 0px",
            }}
          >
            Bank Reconciliation
          </Typography>
        </div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <RadioButtonCheckedIcon
              sx={{ color: "#FFBF00", marginRight: "10px" }}
            />{" "}
            New Data
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <RadioButtonCheckedIcon
              sx={{ color: "#808080", marginRight: "10px" }}
            />{" "}
            Migrated Data
          </div>
        </div>
      </div>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "10px",
        }}
        xs={12}
        gap={"10px"}
      >
        <Grid
          item
          xs={2.9}
          display={"flex"}
          flexDirection={"column"}
          gap={"8px"}
        >
          <FormLabel>Bank Account No</FormLabel>
          <CommonSelect
            placeholder="Select Account No"
            value={searchFieldsPrams?.Receiving_Bank_Account}
            handleChange={(event, value, reason) =>
              handleBankTnxSelectAccountDropdown(
                "Receiving_Bank_Account",
                value,
                reason,
                event
              )
            }
            options={
              accountsList?.map((option) => option?.account_number) || []
            }
          />
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"6px"}
          xs={2.5}
        >
          <FormLabel>Transaction Period</FormLabel>
          <CustomDateRangePicker
            startDate={fromDateValue}
            setStartDate={setFromDateValue}
            endDate={toDateValue}
            setEndDate={setToDateValue}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item alignSelf={"flex-end"} ml={"15px"}>
          <CommonButton
            text="Search"
            handleClick={handleSearchReconciliationList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
          />
        </Grid>
        <Grid item xs={2.9} alignSelf={"flex-end"}>
          <CommonButton
            text="Clear"
            handleClick={handleClearSearchList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
          />
        </Grid>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            border: " 1px solid black",
            borderRadius: "9px",
            marginTop: "30px",
          }}
          aria-label="disabled tabs example"
        >
          <Tab
            style={{
              borderRight: "1px solid black",
              padding: "5px",
              backgroundColor: tabValue === 0 ? "#FF5A01" : "",
              color: tabValue === 0 ? "black" : "",
            }}
            label="Show All"
          />

          <Tab
            label="Variance"
            style={{
              padding: "5px",
              backgroundColor: tabValue === 1 ? "#FF5A01" : "",
              color: tabValue === 1 ? "black" : "",
            }}
          />
        </Tabs>
      </Grid>

      <div
        style={{
          height: "calc(100vh - 313px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(
                getPaginationCount(pageState?.total, newPageSize)
              );
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          sx={{
            "& .MuiDataGrid-cellContent": {
              textAlign: "start",
            },
            "& .MuiDataGrid-columnHeader[data-field='Comments']": {
              minWidth: toggle?.isOpen
                ? "209.137px !important"
                : "209.137px !important",
            },
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      <Dialog open={openCommentsDialog} onClose={handleCloseDialogs}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent sx={{ overflow: "hidden", textAlign: "center" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogCommentsValue}
            onChange={handleDialogInputChange}
            className=""
            style={{ width: "350px", minWidth: "350px" }}
          />
          {oldConcatenatedComment && (
            <div
              style={{
                textWrap: "wrap",
                backgroundColor: "rgb(175, 171, 170)",
                marginTop: "10px",
                padding: "20px",
                borderRadius: "5px",
                whiteSpace: "pre-wrap",
                textAlign: "left",
                maxHeight: "300px",
                overflowY: "scroll",
                maxWidth: "400px",
              }}
            >
              {oldConcatenatedComment}
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseDialogs}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSaveComments}
            hoverColor="#FF5A01"
            disabled={!dialogCommentsValue}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};
