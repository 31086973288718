import React, { useEffect, useState } from "react";

import "./dashboard.css";
import instance from "../../redux/api";
import { DashboardHeader, BankBalanceDashboardHeader, BankNameHeader, BankAccountHeader } from "../../Services/api";
import {
  Avatar,
  CircularProgress,
  FormLabel,
  Grid,
  Typography,
  Select,
  MenuItem
} from "@mui/material";
import { Box } from "@mui/system";

import CustomDateRangePicker from "../common/CustomDateRangePicker";
import format from "date-fns/format";
import ROUTES from "../../utils/routes";

import GroupIcon from "../../assets/icons/dashboard/Group.svg";
import TransactionIcon from "../../assets/icons/dashboard/transaction.svg";
import MobileBankingIcon from "../../assets/icons/dashboard/mobile-banking.svg";
import MobileBankingNegativeIcon from "../../assets/icons/dashboard/mobile-banking-neg.svg";
import ErrorIcon from "../../assets/icons/dashboard/error 1.svg";
import CheckIcon from "../../assets/icons/dashboard/check.svg";

import TargetGoalsNewChart from "./TargetGoalsNewChart";
import SLADonutChartNew from "./SLAChartNew";
import EmployeeAllocationTable from "./EmployeeAllocationTable";
import EmployeeStatusBarChart from "./EmployeeStatusBarChart";
import { AllocationStatusChartNew } from "./AllocationStatusChartNew";
import DateWiseAllocationChart from "./DateWiseAllocationChart";
import { ReactComponent as NODataDonut } from "../../assets/icons/NodataDonuts.svg";
import { useNavigate } from "react-router-dom";
import { CreditDebitNewBarChart } from "./CreditDebitNewBarChart";

export default function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [employeeAllocationData, setEmployeeAllocationData] = useState(null);
  const [employeeStatusWiseData, setEmployeeStatusWiseData] = useState([]);
  const [sla, setSla] = useState(null);
  const [dateWiseAllocation, setDateWiseAllocation] = useState(null);
  const [creditDebitAmount, setCreditDebitAmount] = useState(null);
  const [statusWiseData, setStatusWiseData] = useState(null);
  const [targetGoalsData, setTargetGoalsData] = useState(null);
  const [selectedActionBarItem, setSelectedActionBarItem] = useState("Weekly")
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [bankNames, setBankNames] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  //Date Information
  const fromWeekData = new Date();
  fromWeekData.setDate(fromWeekData.getDate() - 7);

  const [fromDateValue, setFromDateValue] = useState(fromWeekData);
  const [toDateValue, setToDateValue] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      let queryParams = ``;
      let bankQueryParams = ``;
      let formattedFromDateValue = "",
        formattedToDateValue = "";

      if (fromDateValue && toDateValue) {
        formattedFromDateValue = format(fromDateValue, "yyyy-MM-dd");
        formattedToDateValue = format(toDateValue, "yyyy-MM-dd");
        queryParams = `${queryParams}?date_range=${formattedFromDateValue}_${formattedToDateValue}`;
        bankQueryParams = `${bankQueryParams}?date_range=${formattedFromDateValue}_${formattedToDateValue}`;
      }

      if (selectedBankAccount) {
        bankQueryParams += `&bank_account=${selectedBankAccount}`;
      }
      if (selectedBankName) {
        bankQueryParams += `&bank_name=${selectedBankName}`;
      }

      const response = await instance.get(`${DashboardHeader}${queryParams}`);
      const bankBalanceResponse = await instance.get(`${BankBalanceDashboardHeader}${bankQueryParams}`);
      const bankANameResponse = await instance.get(`${BankNameHeader}${queryParams}`);
      const bankAccountResponse = await instance.get(`${BankAccountHeader}${bankQueryParams}`);
      setLoading(false);
      setData(response.data);
      setEmployeeAllocationData(response.data.employee_allocation_data);
      setSla(response.data.sla);
      setTargetGoalsData({
        receivable_amount: response?.data?.target_goals.receivable_amt_sum ?? 0,
        allocated_amount: response?.data?.target_goals.allocated_amt_sum ?? 0,
      });
      setEmployeeStatusWiseData(response.data.employee_status_wise_count);
      setStatusWiseData(response?.data?.allocation_status_count);
      setDateWiseAllocation(response.data.datewise_total_amount);
      // setCreditDebitAmount(response.data.credit_debit_amount);
      setBankNames(bankANameResponse.data.bank_names);
      setBankAccounts(bankAccountResponse.data.bank_accounts);
      setCreditDebitAmount(bankBalanceResponse.data.credit_debit_total_amount);
    } catch (error) {
      setLoading(false);
      console.error("err", error);
    }
  };

  const loadBankData = async () => {
    let queryParams = ``;
    let bankQueryParams = ``;
    let formattedFromDateValue = "",
      formattedToDateValue = "";

    if (fromDateValue && toDateValue) {
      formattedFromDateValue = format(fromDateValue, "yyyy-MM-dd");
      formattedToDateValue = format(toDateValue, "yyyy-MM-dd");
      queryParams = `${queryParams}?date_range=${formattedFromDateValue}_${formattedToDateValue}`;
      bankQueryParams = `${bankQueryParams}?date_range=${formattedFromDateValue}_${formattedToDateValue}`;
    }

    if (selectedBankAccount) {
      bankQueryParams += `&bank_account=${selectedBankAccount}`;
    }
    if (selectedBankName) {
      bankQueryParams += `&bank_name=${selectedBankName}`;
    }
    const bankBalanceResponse = await instance.get(`${BankBalanceDashboardHeader}${bankQueryParams}`);
    const bankNameResponse = await instance.get(`${BankNameHeader}${queryParams}`);
    const bankAccountResponse = await instance.get(`${BankAccountHeader}${bankQueryParams}`);
    setBankNames(bankNameResponse.data.bank_names);
    setBankAccounts(bankAccountResponse.data.bank_accounts);
    setCreditDebitAmount(bankBalanceResponse.data.credit_debit_total_amount);
  }

  useEffect(() => {
    loadBankData();
  }, [selectedBankAccount, selectedBankName]);

  useEffect(() => {
    loadData();
  }, []);

  const toUnassignedTransaction = () => {
    navigate(ROUTES.MUBST, {
      state: {
        assigned: "Unassigned",
        fromDateValue: fromDateValue || "",
        toDateValue: toDateValue || "",
      },
    });
  };

  const toAssignedTransaction = () => {
    navigate(ROUTES.MUBST, {
      state: {
        assigned: "Assigned",
        fromDateValue: fromDateValue || "",
        toDateValue: toDateValue || "",
      },
    });
  };

  const toAllocatedTransaction = () => {
    navigate(ROUTES.MUCASHTRACKER, {
      state: {
        allocated: "Allocated",
        fromDateValue: fromDateValue || "",
        toDateValue: toDateValue || "",
      },
    });
  };

  const toUnallocatedTransaction = () => {
    navigate(ROUTES.MUCASHTRACKER, {
      state: {
        allocated: "Unallocated",
        fromDateValue: fromDateValue || "",
        toDateValue: toDateValue || "",
      },
    });
  };

  const ShowLoader = () => {
    return (
      <>
        {loading && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} />
          </Box>
        )}
      </>
    );
  };

  const convertToMillions = (inputAmount) => {
    if (inputAmount) {
      const inMillions = parseFloat(inputAmount / 1000000).toFixed(2);
      return `${inMillions} M`;
    }
    return inputAmount;
  };

  return (
    <Box padding={1}>
      <Grid
        container
        p={"5px"}
        columns={12}
        style={{ backgroundColor: "rgb(207,206,206,0.2)" }}
      >
        <Grid container sm={12} md={3} lg={1.9} mt={2}>
          <Grid item>
            <FormLabel className="select-daterange-text">Select Date Range</FormLabel>
            <CustomDateRangePicker
              startDate={fromDateValue}
              setStartDate={setFromDateValue}
              endDate={toDateValue}
              setEndDate={setToDateValue}
              maxDate={new Date()}
              width=""
              handleSubmit={loadData}
              displayActionBar={true}
              defaultActionBarItem={"Weekly"}
              setSelectedActionBarItem={setSelectedActionBarItem}
            />
          </Grid>
        </Grid>

        <Grid
          container
          gap={1.5}
          sm={12}
          md={9}
          lg={10}
          className="card-container"
        >
          <Grid
            item
            p={1}
            borderRadius={2}
            sm={5.8}
            md={3.4}
            lg={2.3}
            xl={2.3}
            bgcolor={"white"}
          >
            <Avatar
              className="icons fileIcon"
              sx={{ bgcolor: "rgba(255, 215, 154, 0.42)" }}
              src={GroupIcon}
            />
            <Typography className="commonText-Value" color="#ff9c07">
              {data?.open_statement_count ?? 0} (
              {convertToMillions(
                data?.open_statement_amount?.credit_amount__sum
              ) ?? 0}
              )
            </Typography>
            <Typography className="text-size" color="#ff9c07">
            Uploaded stmt/Cash Rcvb amt
            </Typography>
          </Grid>

          <Grid
            item
            p={1}
            borderRadius={2}
            sm={5.8}
            md={3.5}
            lg={2.3}
            xl={2.3}
            bgcolor={"white"}
            onClick={toAssignedTransaction}
            className="tiles-Container"
          >
            <Avatar
              className="icons mobileIcon"
              sx={{ bgcolor: "rgba(220, 175, 255, 1)" }}
              src={MobileBankingIcon}
            />
            <Typography className="commonText-Value" color="#7f08dc">
              {data?.assigned_transaction_count ?? 0} (
              {convertToMillions(
                data?.assigned_transaction_amount?.receivable_amt
              ) ?? 0}
              )
            </Typography>
            <Typography className="text-size" color="#7f08dc">
              Assigned Transactions
            </Typography>
          </Grid>

          <Grid
            item
            p={1}
            borderRadius={2}
            sm={5.8}
            md={3.5}
            lg={2.3}
            xl={2.3}
            bgcolor={"white"}
            onClick={toUnassignedTransaction}
            className="tiles-Container"
          >
            <Avatar
              className="icons mobileIcon"
              sx={{ bgcolor: "rgba(255, 185, 185, 1)" }}
              src={MobileBankingNegativeIcon}
            />
            <Typography className="commonText-Value" color="#ff0000">
              {data?.non_assigned_transaction_count ?? 0} (
              {convertToMillions(
                data?.non_assigned_transaction_amount?.receivable_amt
              ) ?? 0}
              )
            </Typography>
            <Typography className="text-size" color="#ff0000">
              Unassigned Transactions
            </Typography>
          </Grid>

          <Grid
            item
            p={1}
            borderRadius={2}
            sm={5.8}
            md={3.5}
            lg={2.3}
            xl={2.3}
            bgcolor={"white"}
            onClick={toAllocatedTransaction}
            className="tiles-Container"
          >
            <Avatar
              className="icons"
              sx={{ bgcolor: "rgba(201, 245, 252, 1)" }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TransactionIcon} className="transaction-icon" alt="transaction" />
                <img src={CheckIcon} className="success-icon" alt="success" />
              </Box>
            </Avatar>
            <Typography className="commonText-Value" color="#0bc255">
              {data?.allocated_transaction_count ?? 0} (
              {convertToMillions(
                data?.allocated_transaction_amount?.receivable_amt
              ) ?? 0}
              )
            </Typography>
            <Typography className="text-size" color=" #0bc255">
              Allocated Cash Items
            </Typography>
          </Grid>

          <Grid
            item
            p={1}
            borderRadius={2}
            sm={5.8}
            md={3.5}
            lg={2.3}
            xl={2.3}
            bgcolor={"white"}
            onClick={toUnallocatedTransaction}
            className="tiles-Container"
          >
            <Avatar
              className="icons"
              sx={{ bgcolor: "rgba(201, 245, 252, 1)" }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TransactionIcon} className="transaction-icon" alt="transaction" />
                <img src={ErrorIcon} className="error-icon" alt="error" />
              </Box>
            </Avatar>

            <Typography className="commonText-Value" color="#2560f9">
              {data?.unallocated_transaction_count ?? 0} (
              {convertToMillions(
                data?.unallocated_transaction_amount?.receivable_amt
              ) ?? 0}
              )
            </Typography>
            <Typography className="text-size" color="#2560f9">
              Unallocated Cash Items
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ backgroundColor: "#cfcece33" }}
        gap={2}
        columns={12}
        p={1}
      >
        <Grid
          item
          style={{ backgroundColor: "white" }}
          borderRadius={3}
          lg={5.6}
          md={5.5}
          sm={12}
        >
          <div className="main-box">
            <div>
              <Grid className="widgetTitle">Bank Balance</Grid>
            </div>
            <div>
                {bankNames.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item className="grid-item">
                    <FormLabel>Bank Name</FormLabel>
                    <Select
                      value={selectedBankName}
                      onChange={(e) => {
                        setSelectedBankName(e.target.value === "" ? null : e.target.value);
                        setSelectedBankAccount(null);
                      }}
                      className="select-box"
                    >
                      <MenuItem value="">None</MenuItem>
                      {bankNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item className="grid-item">
                    <FormLabel>Bank Account</FormLabel>
                    <Select
                      value={selectedBankAccount}
                      onChange={(e) => setSelectedBankAccount(e.target.value === "" ? null : e.target.value)}
                      className="select-box"
                    >
                      <MenuItem value="">None</MenuItem>
                      {bankAccounts.map((account) => (
                        <MenuItem key={account} value={account}>
                          {account}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
              <div>
                <Typography sx={{ m: 1 }} >Use scroll to Zoom-In and Zoom-Out</Typography>
              </div>
          <Grid >
            {loading ? (
              <ShowLoader />
            ) : (
              <>
                {creditDebitAmount?.length > 0 ? (
                  <CreditDebitNewBarChart data={creditDebitAmount} selectedActionBarItem={selectedActionBarItem} />
                ) : (
                  <NODataDonut />
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          style={{ backgroundColor: "white" }}
          borderRadius={3}
          lg={6.2}
          md={6.3}
          sm={12}
        >
          <Grid className="widgetTitle">Receivable Trend</Grid>
          <Grid>
            {loading ? (
              <ShowLoader />
            ) : (
              <DateWiseAllocationChart data={dateWiseAllocation} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ backgroundColor: "#cfcece33" }}
        gap={2}
        columns={12}
        p={1}
      >
        <Grid
          item
          style={{ backgroundColor: "#fff" }}
          className="chart"
          borderRadius={3}
          md={3.6}
          sm={12}
        >
          <Grid className="widgetTitle">Target Goals</Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {loading ? (
              <ShowLoader />
            ) : (
              <TargetGoalsNewChart data={targetGoalsData} />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          style={{ backgroundColor: "#fff" }}
          borderRadius={3}
          md={3.7}
          sm={12}
        >
          <Grid className="widgetTitle">SLA</Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingTop={5}
          >
            {loading ? <ShowLoader /> : <SLADonutChartNew data={sla} />}
          </Grid>
        </Grid>

        <Grid
          item
          style={{ backgroundColor: "white" }}
          borderRadius={3}
          md={4.3}
          sm={12}
        >
          <Grid className="widgetTitle">Allocation Status</Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingTop={5}
          >
            {loading ? (
              <ShowLoader />
            ) : (
              <AllocationStatusChartNew data={statusWiseData} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ backgroundColor: "#cfcece33" }}
        gap={2}
        columns={12}
        p={1}
      >

        <Grid
          item
          sx={{ backgroundColor: "white" }}
          borderRadius={3}
          xl={12}
          lg={12}
          md={12}
          sm={12}
        >
          <Grid className="widgetTitle">Employee Performance Table</Grid>
          <Grid className="employeeAllocationContainer">
            {loading ? (
              <ShowLoader />
            ) : (
              <>
                {employeeAllocationData?.length ? (
                  <EmployeeAllocationTable
                    columns={[
                      "Employee Name",
                      "Assigned Txn",
                      "Rcvbl Amt",
                      "Allocated #",
                      "Allocated Amount",
                      "Unallocated #",
                      "Unallocated Amount",
                      "Rating",
                    ]}
                    data={employeeAllocationData}
                  />
                ) : (
                  <Grid
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    No data to display
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          style={{ backgroundColor: "white" }}
          borderRadius={3}
          xl={12}
          lg={12}
          md={12}
          sm={12}
        >
          <Grid className="widgetTitle">Progress Chart</Grid>
          <Grid>
            {loading ? (
              <ShowLoader />
            ) : (
              <EmployeeStatusBarChart data={employeeStatusWiseData} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
