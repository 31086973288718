import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Grid, Stack, TextField, Typography, FormLabel, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import format from "date-fns/format";
import {
  getBanlTransactionDetailsURL,
  getCashAllocationWriteOffURL,
  getCashAllocationRefundURL,
  getCashAllocationCorrectionTypeURL,
  getCashAllocationCorrectiveTransferURL,
  getCashAllocationCrossAllocationURL,
  getCashAllocationCFIURL,
  getPaymentNoVarianceURL,
  workListTableData
} from "../../Services/api";
import instance from "../../redux/api";
import { ViewAndEditBankTransaction } from "../bankTransactions/ViewAndEditTxnDialog";
import { CommonButton } from "../common/commonButton";
import { customDateFormat, getPaginationCount } from "../CashAllocation/utils";
import { NAVIGATION_PAGE_NAME } from '../../constants';
import EditPriceDialog from '../bankTransactions/EditPriceDialog';
import {
  getAccountsList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import { CommonPaginationContainer } from "../common";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { ViewGenericWorkflowDialog } from "../Workflow/ViewGenericWorkflowDialog";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";
import CustomDateRangePicker from "../common/CustomDateRangePicker";

export const WorkList = () => {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  // Dispatch
  const dispatch = useDispatch();
  const location = useLocation();

  // State
  const [isOpenTxnDialog, setIsOpenTxnDialog] = useState(false);
  const [workflowTxnData, setWorkflowTxnData] = useState({});

  const [isOpenEditPriceDialog, setIsOpenEditPriceDialog] = useState(false);

  const [currentWorkflowDetails, setCurrentWorkflowDetails] = useState({});
  const [genericWorkflowDialog, setGenericWorkflowDialog] = useState(false);
  const [workflowSelectedTxnData, setWorkflowSelectedTxnData] = useState("")

  const [updateGenericWorkflowDialog, setUpdateGenericWorkflowDialog] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");

  //workflow search
  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Bank_Transaction_Id: "",
  });

//datepicker from and toDates
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");

  //styles
  const classes = useStyles();

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);

  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.total]);

  useEffect(() => {
    if (userData?.id) {
      getWorklistTableData();
    }
  }, [pageState?.pageSize, pageState?.page]);

  const getWorklistTableData = async () => {
    try {
      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true
      }));
      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;
      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      const response = await instance.get(`${workListTableData}?${queryParams}&user_id=${userData?.id}`);

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: false
      }));

      if (response?.data) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
        setPaginationCount(Math.floor(response?.data?.count / pageState?.pageSize));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  useEffect(() => {
    // Fetch Banks List
    dispatch(getAccountsList());
  }, []);

  useEffect(() => {
    if (userData?.id) {
      getWorklistTableData();
    }
  }, [workListTableData, userData?.id]);

  const handleLinkClick = async (workflowName, id) => {
    setCurrentWorkflowName(workflowName);

    let workflowAPIURL = '';
    switch (workflowName) {
      case "CHANGE_BANK_TRANSACTION_AMOUNT":
        workflowAPIURL = getBanlTransactionDetailsURL;
        break;
      case "WF_WRITEOFF":
        workflowAPIURL = getCashAllocationWriteOffURL;
        break;
      case "WF_REFUND":
        workflowAPIURL = getCashAllocationRefundURL;
        break;
      case "WF_CORRECTION_TYPES":
        workflowAPIURL = getCashAllocationCorrectionTypeURL;
        break;
      case "WF_CORRECTIVE_TRANSFER":
        workflowAPIURL = getCashAllocationCorrectiveTransferURL;
        break;
      case "WF_CROSS_ALLOCATION":
        workflowAPIURL = getCashAllocationCrossAllocationURL;
        break;
      case "WF_CFI":
        workflowAPIURL = getCashAllocationCFIURL;
        break;
      case "WF_PAYMENT_NOVARIANCE":
        workflowAPIURL = getPaymentNoVarianceURL;
        break;
      default:
        break;
    }

    try {
      const response = await instance.get(`${workflowAPIURL}${id}`);
      if (response?.data) {
        if (workflowName === 'CHANGE_BANK_TRANSACTION_AMOUNT') {
          setWorkflowTxnData(response?.data);
          setIsOpenTxnDialog(true);
        } else {
          setWorkflowSelectedTxnData(response?.data);
          setGenericWorkflowDialog(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleActionsBtn = async (rowData, id) => {
    try {
      const { workflow_name } = rowData?.workflow_json_data;
      setCurrentWorkflowName(workflow_name);

      let workflowAPIURL = '';
      switch (workflow_name) {
        case "CHANGE_BANK_TRANSACTION_AMOUNT":
          workflowAPIURL = getBanlTransactionDetailsURL;
          const response = await instance.get(`${workflowAPIURL}${rowData?.bank_txn_id}`);
          setWorkflowTxnData(response?.data);
          break;
        default:
          break;
      }

      // Set workflow steps data
      setCurrentWorkflowDetails(rowData);

      // open dialog
      if (workflow_name === 'CHANGE_BANK_TRANSACTION_AMOUNT') {
        setIsOpenEditPriceDialog(true);
      } else {
        setUpdateGenericWorkflowDialog(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleUpdateGenericWorkflowDialog = () => {
    // Load Data
    setUpdateGenericWorkflowDialog(false);
    getWorklistTableData();
  }

  const handleTextFieldchange = (name, value) => {
    if (!value) {
      handleClearWorkflowList()
    }
    setSearchFieldsPrams({
      ...searchFieldsPrams,
      [name]: value?.trim(),
    });
  };

  const handlePriceEditCloseDialog = () => {
    setIsOpenEditPriceDialog(false);
    getWorklistTableData();
  }

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Bank_Transaction_Id } = searchFieldsPrams;
    if (Bank_Transaction_Id !== "" || fromDateValue !== "" || toDateValue !== "") {
      disabled = false;
    }
    return disabled;
  }


  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { Bank_Transaction_Id } = searchFieldsPrams;

    if (Bank_Transaction_Id) {
      getSearchQueryParams = `${getSearchQueryParams}&transactionId=${Bank_Transaction_Id}`;
    }

    let fromDateReceived = "",
    toDateReceived = "";

    if (fromDateValue && toDateValue) {
      fromDateReceived = format(fromDateValue, "yyyy-MM-dd");
      toDateReceived = format(toDateValue, "yyyy-MM-dd");
      getSearchQueryParams = `${getSearchQueryParams}&fromDateReceived=${fromDateReceived}&toDateReceived=${toDateReceived}`;
    }

    return getSearchQueryParams;
  }

  const handleSearchWorkflowList = async () => {
    try {

      let queryParams = `?user_id=${userData?.id}&skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      if (location?.state?.fromDateValue && location?.state?.toDateValue && !queryParams.includes("fromDateReceived")) {
        let fromDateReceived = format(location?.state?.fromDateValue, "yyyy-MM-dd");
        let toDateReceived = format(location?.state?.toDateValue, "yyyy-MM-dd");
        queryParams = `${queryParams}&fromDateReceived=${fromDateReceived }&toDateReceived=${toDateReceived }`;
      }


      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true
      }));

      const response = await instance.get(`${workListTableData}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1
        }));
        setIsSearchPerformed(true);
        setIsClearPerformed(false);
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.log("error", error);
    }
  };

  const handleClearWorkflowList = async () => {
    setIsClearPerformed(true);
    setIsSearchPerformed(false);
    // clear fields
    setSearchFieldsPrams({
      Bank_Transaction_Id: "",
      Transaction_Received_Date: "",
    });
    setFromDateValue("");
    setToDateValue("");
  };

  const columns = [
    {
      field: "Date Received",
      headerName: "Date Received",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.workflow_json_data?.workflow_step[0]?.ctime,
      renderCell: (params) => (
        <span>
          {params?.value ? customDateFormat(params?.value) : ""}
        </span>
      ),
    },
    {
      field: "bank_txn_id",
      headerName: " Workflow ID",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.bank_txn_id,
      renderCell: (params) => {
        const { workflow_name } = params?.row?.workflow_json_data;
        return (
          <Tooltip
            title={params?.value}
          >
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => handleLinkClick(workflow_name, params?.value)}
            >
              {params?.formattedValue}
            </span>
          </Tooltip>
        )
      }
    },
    {
      field: "workflow_json_data",
      headerName: "Workflow Name",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.workflow_json_data?.workflow_name,
      renderCell: (params) => (
        <span>{params?.value}</span>
      ),
    },
    {
      field: "workflow_status",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.workflow_status,
      renderCell: (params) => {
        let statusInfo = params?.row?.workflow_status;
        let fontColor = "blue";
        switch (statusInfo?.toLowerCase()) {
          case "completed":
            fontColor = "#28a745";
            break;
          case "approved":
            fontColor = "#FF5A00";
            break;
          case "rejected":
            fontColor = "#dc3545";
            break;
          case "in process":
            const workflowStepData = params?.row?.workflow_json_data?.workflow_step;
            if (workflowStepData?.length > 0) {
              const initiaterStepData = workflowStepData.filter(item => item?.step_name?.toLowerCase() === "initiater");

              let isInitiatorLoggedIn = false;
              initiaterStepData[0]?.user?.forEach((user, index) => {
                if (user?.id === userData?.id) {
                  isInitiatorLoggedIn = true;
                }
              });
              if (!isInitiatorLoggedIn) {
                statusInfo = "Review/Reject";
              }
            }
            break;
          default:

        }

        return (
          <span
            style={{ color: fontColor, cursor: "pointer" }}
            onClick={() => handleActionsBtn(params?.row, params?.row?.bank_txn_id)}
          >
            {statusInfo}
          </span>
        )
      }
    },
  ];

  useEffect(() => {
    if (isClearPerformed) {
      getWorklistTableData()
    }
  }, [pageState?.pageSize, pageState?.page, isClearPerformed]);


  return (
    <>
      <Box
        sx={{
          width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
          height: "525px",
        }}
      >
        <Typography
          style={{
            color: "#FF5A01",
            fontSize: "24px",
            margin: "5px 0px",
          }}
        >
          Workflow Tasklist
        </Typography>

        <Grid
          container
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          mb={"10px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid
            container
            className="bankStatementSearchContainer"
            xs={10}
            gap={"5px"}
          >
            <Grid
              item
              xs={2.9}
              display={"flex"}
              flexDirection={"column"}
              gap={"8px"}
            >
              <FormLabel>Workflow ID</FormLabel>
              <TextField
                value={searchFieldsPrams?.Bank_Transaction_Id}
                onChange={(e) =>
                  handleTextFieldchange("Bank_Transaction_Id", e.target.value)
                }
                sx={{ width: "auto" }}
                size="small"
              />
            </Grid>
            <Grid
              item
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              xs={3}
            >
              <FormLabel style={{marginTop:"4px"}}>Date</FormLabel>
              <CustomDateRangePicker
                startDate={fromDateValue}
                setStartDate={setFromDateValue}
                endDate={toDateValue}
                setEndDate={setToDateValue}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item alignSelf={"flex-end"} ml={"15px"}>
              <CommonButton
                text="Search"
                className="bankStatementBtn"
                handleClick={handleSearchWorkflowList}
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>
            <Grid item alignSelf={"flex-end"}>
              <CommonButton
                text="Clear"
                handleClick={handleClearWorkflowList}
                className="bankStatementBtn"
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            height: "calc(100vh - 302px)",
            position: "relative",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <DataGrid
            className={classes.pagination}
            loading={pageState?.isLoading}
            rows={pageState?.data ?? []}
            columns={columns}
            rowCount={pageState?.total}
            keepNonExistentRowsSelected
            disableSelectionOnClick
            pagination
            paginationMode="server"
            getRowId={(row) => row?.id}
            page={pageState.page - 1}
            pageSize={pageState?.pageSize ?? 25}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={(newPageSize) => {
              return setPageState((old) => {
                setPaginationCount(
                  getPaginationCount(pageState?.total, newPageSize)
                );
                return { ...old, pageSize: newPageSize, page: 1 };
              });
            }}
            sx={{
              "& .MuiDataGrid-columnHeader[data-field='Actions']": {
                minWidth: toggle?.isOpen
                  ? "315.137px !important"
                  : "366px !important",
              },
            }}
            components={{
              Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Work List
                </Stack>
              ),
            }}
          />
          <Stack
            style={{
              position: "absolute",
              bottom: "8px",
            }}
          >
            <CommonPaginationContainer
              count={paginationCount + 1}
              pageState={pageState}
              setPageState={setPageState}
            />
          </Stack>
        </div>
      </Box>

      {isOpenTxnDialog && (
        <ViewAndEditBankTransaction
          isOpenTxnDialog={isOpenTxnDialog}
          setIsOpenTxnDialog={setIsOpenTxnDialog}
          txnMode={"VIEW"}
          txnData={workflowTxnData}
        />
      )}

      {isOpenEditPriceDialog && (
        <EditPriceDialog
          openEditPriceDialog={isOpenEditPriceDialog}
          handlePriceEditCloseDialog={handlePriceEditCloseDialog}
          txnData={workflowTxnData}
          currentWorkflowDetails={currentWorkflowDetails}
          navigatedFrom={NAVIGATION_PAGE_NAME.WORK_LIST}
          disabled={false}
        />
      )}

      {genericWorkflowDialog && (
        <ViewGenericWorkflowDialog
          workFlowDialogOpen={genericWorkflowDialog}
          workFlowDialogClose={setGenericWorkflowDialog}
          workflowTxnData={workflowSelectedTxnData}
          workflowSelectedName={currentWorkflowName}
        />
      )}

      {updateGenericWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={updateGenericWorkflowDialog}
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentWorkflowDetails}
        />
      )}
    </>
  );
};
