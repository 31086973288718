import React, { useState, useEffect } from "react";
import GenericDonutChart from "./SLADonutChart";
import { ReactComponent as NODataDonut } from '../../assets/icons/NodataDonuts.svg'

const SLADonutChartNew = ({ data }) => {
    const [noData, setNoData] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (!data || data?.sla_met == 0 && data?.sla_not_met == 0) {
            setNoData(true);
        } else {
            setNoData(false);
            setTotalCount(data?.sla_met + data?.sla_not_met);
        }
    }, [data]);

    const formatData = (allocationData) => {
        let disableTooltipsObj = {};

        if (!allocationData || !allocationData[0]) {
            allocationData = [0, 1];
            disableTooltipsObj = {
                disableTooltips: true
            };
        }

        return {
            ...disableTooltipsObj,
            datasets: [
                {
                    data: allocationData,
                    backgroundColor: ['#0DA59D', '#0DA59D'],
                    hoverBackgroundColor: ['#0DA59D', '#0DA59D'],
                    cutout: 64,
                },
            ],
        };
    };

    const slaData = formatData([
        { count: data?.sla_met ?? 0, title: 'SLA Met' },
        { count: data?.sla_not_met ?? 0, title: 'SLA Not Met' },
    ]);

    return (
        <>
            {noData ?
                <NODataDonut />
                :
                <div style={{
                    display: 'flex', flexDirection: "column", justifyContent: "space-betweencen",
                    alignItems: "baseline",
                }}>
                    <GenericDonutChart
                        ariaLabel="Donut for allocation status records"
                        colorsList={['#005D5D', '#42788F']}
                        data={slaData}
                        displayDonutInnerText={false}
                        title={"SLA"}
                    />
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", width: 300 }}>
                        <span style={{ color: '#003B5C', fontWeight: 600 }}>{totalCount}</span>
                        <span style={{ color: '#425A70' }}>SLA %</span>
                    </div>
                </div>
            }
        </>
    )
}

export default SLADonutChartNew;
