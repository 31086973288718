import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Broker } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableContainer,
  Tooltip,
} from "@mui/material";
import { ViewAndEditEntity } from "./ViewAndEditEntity";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddBrokerDetails } from "./AddBinding";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";

//styles

const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};
const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
};

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const dialogStyle = {
  maxWidth: "90vw",
  width: "90vw",
  margin: "auto",
};
const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};
const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};
const EditPageContainerTransactionsList = {
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};
const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

export default function BrokerContainer() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [rows, setRows] = useState([
    {
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    },
  ]);
  const [EntityDialog, setEntityDialog] = useState(false);
  const [editRow, setEditRow] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");
  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const classes = useStyles();

  //For pagination
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const response = await instance.get(
        `${Broker}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      console.error("err", err);
    }
  };

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setEntityDialog(false);
    setEditMode(false);
    setViewMode(false);
    if (!editMode && !viewMode) {
      setRows([
        {
          broker_name: "",
          broker: "",
          branch: "",
          duplicate_count: "",
          soa_received_from_broker: "",
          name: "",
          email: "",
          phone_number: "",
          broker_branch_location: "",
        },
      ]);
      setSelectedRows([false]);
      setValidationStatus([true]);
    }
  };

  useEffect(() => {
    if (selectAll) {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.fill(selectAll);
      setSelectedRows(updatedSelectedRows);
    }
  }, [selectAll, selectedRows]);

  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    }));
    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRows([rowData]);
      setEditRow(rowData);
      setEditMode(true);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRows([rowData]);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRows(emptyRows);
      setIsCreated(true);
      setEntityDialog(true);
    }
    setEntityDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "broker_name",
      headerName: "Broker",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "duplicate_count",
      headerName: "Duplicate Count",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <span>{parseInt(params?.row?.duplicate_count)}</span>
      ),
    },
    {
      field: "soa_received_from_broker",
      headerName: "Soa Received",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "broker_branch_location",
      headerName: "Broker Branch Location",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        if (UserAuthorisation?.["Edit"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  {" "}
                  <EditIcon />{" "}
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleActionButton(row, true)}
              color="inherit"
            />
          );
        }

        if (UserAuthorisation?.["View"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, false)}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    },
  ];
  const UserAuthorisation =
    userData?.user_permissions?.permissions_list["Admin Table Maintenance"];
  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      setRows(updatedRows);

      // Edit Mode
      if (editMode) {
        setCurrentUpdatedFiledsInEditMode({
          ...currentUpdatedFiledsInEditMode,
          [colName]: value,
        });
      }
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        broker_name: "",
        broker: "",
        branch: "",
        duplicate_count: "",
        soa_received_from_broker: "",
        name: "",
        email: "",
        phone_number: "",
        broker_branch_location: "",
      },
    ]);
    setSelectedRows([...selectedRows, false]);
  };

  useEffect(() => {
    const selectedRowCount = selectedRows.filter(Boolean).length;
    if (selectedRowCount < rows.length) {
      setSelectAll(false)
    }
  }, [rows, selectedRows])

  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    console.log("rows", rows);
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows(selectedRows.filter((ele) => !ele));
    setSelectAll(false);
  };
  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (!row || !row.broker_name || !row.broker_name.trim() === "") {
      fieldValidations.broker_name = "This field is required";
    }
    if (!row || !row.broker || !row.broker.trim() === "") {
      fieldValidations.broker = "This field is required";
    }
    if (!row || !row.branch || !row.branch.trim() === "") {
      fieldValidations.branch = "This field is required";
    }
    if (!row || !row.duplicate_count || !row.duplicate_count.trim() === "") {
      fieldValidations.duplicate_count = "This field is required";
    }
    if (
      !row ||
      !row.soa_received_from_broker ||
      !row.soa_received_from_broker.trim() === ""
    ) {
      fieldValidations.soa_received_from_broker = "This field is required";
    }
    if (!row || !row.name || !row.name.trim() === "") {
      fieldValidations.name = "This field is required";
    }
    if (!row || !/^\S+@\S+\.\S+$/.test(row.email)) {
      fieldValidations.email = "Please enter a valid email address";
    }
    if (!row || !row.phone_number || !row.phone_number.trim() === "") {
      fieldValidations.phone_number = "This field is required";
    }
    if (
      !row ||
      !row.broker_branch_location ||
      !row.broker_branch_location.trim() === ""
    ) {
      fieldValidations.broker_branch_location = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };
  const handleSubmit = async () => {
    // Validate each row before submission
    const areRowsValid = rows
      .map((_, index) => validateRow(index))
      .every((value) => value);

    let editModeObj = {};
    if (!editMode) {
      // Add Mode
    } else {
      editModeObj.id = editRow?.id;
      editModeObj["updated_by"] = userData?.user_name ?? "";

      editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };
    }
    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      setIsApiCallInprogress(true);
      let response;
      if (editMode) {
        response = await instance.patch(Broker + editId + "/", editModeObj);
        // clear preiously updated stored data
        setCurrentUpdatedFiledsInEditMode({});
      } else {
        rows.forEach((item) => {
          item.created_by = userData?.user_name ?? "";
        });
        response = await instance.post(Broker, rows);
      }

      if (response) {
        setIsApiCallInprogress(false);
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      setIsApiCallInprogress(false);
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      broker_name: "",
      broker: "",
      branch: "",
      duplicate_count: "",
      soa_received_from_broker: "",
      name: "",
      email: "",
      phone_number: "",
      broker_branch_location: "",
    }));
    setRows(emptyRows);
    setSelectAll(false);
    setValidationStatus(Array.from({ length: rows.length }, () => true));
    setSelectedRows(selectedRows.fill(false))
  };
  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Broker Table
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <CommonButton
              text="Add"
              handleClick={() => handleActionButton()}
              sx={bankTransactionAddBtn}
              hoverColor="#FF5A01"
            />
          )}
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 240px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination={false}
          hideFooterPagination 
          hideFooter
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Broker Table Maintenance
              </Stack>
            ),
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen
                ? "106px !important"
                : "123px !important",
            },
          }}
        />
      </div>
      <Dialog
        open={EntityDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", paddingBottom: "0px" }}
        >
          {viewMode ? "View Broker" : editMode ? "Edit Broker" : "Add Broker"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {!editMode && !viewMode && (
              <div style={EditPageContainerTransactionsList}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CommonButton
                    text="Add"
                    handleClick={handleAddRow}
                    icon={<AddIcon style={{ height: "18px" }} />}
                    hoverColor="#FF5A01"
                  />
                  <CommonButton
                    sx={{
                      backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                      color: selectAll ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "17px 10px",
                      borderRadius: "15px",
                      "&:hover": {
                        background: selectAll ? "#FF5A01" : "#CCCCCC",
                      },
                    }}
                    text="Delete"
                    handleClick={handleDeleteRow}
                    disabled={selectedRows.filter(Boolean).length === 0}
                    icon={<DeleteIcon style={{ height: "19px" }} />}
                  />
                </div>
              </div>
            )}
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddBrokerDetails
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  handleCheckboxChange={handleCheckboxChange}
                  validationStatus={validationStatus}
                  selectedRows={selectedRows}
                />
              </TableContainer>
            ) : (
              <>
                {rows?.map((row, index) => (
                  <Grid display={"flex"} flexDirection={"column"}>
                    <ViewAndEditEntity
                      row={row}
                      index={index}
                      viewMode={viewMode}
                      validationStatus={validationStatus}
                      editMode={editMode}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                ))}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ? null : (
            <>
              {isCreated && (
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                  disabled={editMode}
                />
              )}
              {editMode && (
                <CommonButton
                  sx={editEntityUpdate}
                  text="Update"
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />
              )}
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text="Submit"
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                  disabled={isApiCallInprogress}
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
