import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { workflowBankTransaction } from "../../Services/api";
import { NAVIGATION_PAGE_NAME } from "../../constants";

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

const EditPriceDialog = ({
  openEditPriceDialog,
  handlePriceEditCloseDialog,
  txnData,
  navigatedFrom,
  currentWorkflowDetails,
}) => {
  const { userData } = useSelector((state) => state?.user);

  const { Bank_Transaction_Id, Receivable_Amount } = txnData;

  const [fieldsData, setFieldsData] = useState({
    Bank_Transaction_Id: Bank_Transaction_Id,
    Receivable_Amount: Receivable_Amount,
    New_Receivable_Amount: 0,
    comments: ""
  });

  const handleInputChange = (fieldName, value) => {
    setFieldsData({
      ...fieldsData,
      [fieldName]: value,
    });
  };

  const handleInitiateClickBtn = async () => {
    const reqBody = {
      Receivable_Amount: fieldsData?.New_Receivable_Amount,
      bank_txn_id: fieldsData?.Bank_Transaction_Id,
      comments: fieldsData?.comments ?? "",
      initiated_user_id: userData?.id,
      workflow_name: "CHANGE_BANK_TRANSACTION_AMOUNT",
    };

    try {
      const response = await instance.post(workflowBankTransaction, reqBody);
      if (response) {
        toast.success("Workflow Initiated Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handlePriceEditCloseDialog(false);
      }
      // clear preiously updated stored data
      setFieldsData({});
    } catch (err) {
      toast.error("Unable to Initiate Workflow !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(err);
    }
  };

  const handleActionButton = async (buttonType) => {
    const { id, current_step } = currentWorkflowDetails;

    const body = {
      step_name: current_step?.toLowerCase(),
      bank_txn_id: txnData?.Bank_Transaction_Id,
      comments: fieldsData?.comments ?? "",
      status: buttonType,
      user_id: userData?.id,
    };

    try {
      const response = await instance.patch(`${workflowBankTransaction}${id}/`, body);
      if (response) {
        toast.success("Workflow Approved Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handlePriceEditCloseDialog(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to approve Workflow", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const displayNewRequestedAmount = () => {
    if (Object.keys(currentWorkflowDetails)?.length) {
      const { changefields } = currentWorkflowDetails;
      return changefields?.Receivable_Amount ?? changefields?.allocated_amount;
    } else {
      return fieldsData?.New_Receivable_Amount ?? 0;
    }
  };

  const displayAddedComments = () => {
    if (navigatedFrom === NAVIGATION_PAGE_NAME.BANK_TRANSACTION) {
      if (Object.keys(currentWorkflowDetails)?.length) {
        const currentUserId = userData?.id;
        const getWorkflowSteps = currentWorkflowDetails?.workflow_json_data?.workflow_step;
        let currentUserComments = "";
        for (let i = 0; i < getWorkflowSteps?.length; i++) {
          const stepInfo = getWorkflowSteps[i];
          const userInfo = stepInfo?.user;
          const getIndex = userInfo?.findIndex(item => item?.id === currentUserId);
          if (getIndex !== -1) {
            currentUserComments = stepInfo?.comments;
            break;
          }
        }

        return currentWorkflowDetails?.workflow_status === "In Process"
          ? currentUserComments
          : fieldsData?.comments ?? "";

      }
    } else if (navigatedFrom === NAVIGATION_PAGE_NAME.WORK_LIST) {
      const currentUserId = userData?.id;
      const getWorkflowSteps = currentWorkflowDetails?.workflow_json_data?.workflow_step;

      let currentUserComments = "";
      for (let i = 0; i < getWorkflowSteps?.length; i++) {
        const stepInfo = getWorkflowSteps[i];
        const userInfo = stepInfo?.user;
        const getIndex = userInfo?.findIndex(item => item?.id === currentUserId);
        if (getIndex !== -1) {
          currentUserComments = stepInfo?.comments;
          break;
        }
      }

      return currentWorkflowDetails?.workflow_status === "In Process"
        ? currentUserComments
        : fieldsData?.comments ?? "";

    }
  };

  const handleCommentsAreaVisibility = () => {
    if (navigatedFrom === NAVIGATION_PAGE_NAME.BANK_TRANSACTION) {
      return currentWorkflowDetails?.workflow_status === "In Process"
        ? true
        : false;
    } else if (navigatedFrom === NAVIGATION_PAGE_NAME.WORK_LIST) {
      // Move this code reusable method
      const currentUserId = userData?.id;
      const getWorkflowSteps = currentWorkflowDetails?.workflow_json_data?.workflow_step;

      let isDisableComments = false;
      for (let i = 0; i < getWorkflowSteps?.length; i++) {
        const stepInfo = getWorkflowSteps[i];
        const userInfo = stepInfo?.user;
        const getIndex = userInfo?.findIndex(item => item?.id === currentUserId);
        if (getIndex !== -1) {
          if (currentWorkflowDetails?.current_step?.toLowerCase() === "approver") {
            isDisableComments = false;
          } else if (currentWorkflowDetails?.current_step?.toLowerCase() === "reviewer") {
            isDisableComments = false;
          } else {
            isDisableComments = true;
          }
          break;
        }
      }
      return isDisableComments;
    }
  };

  return (
    <Dialog open={openEditPriceDialog} fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
        {"Edit Receivable Amount"}
        <IconButton
          aria-label="close"
          onClick={() => handlePriceEditCloseDialog(false)}
          style={{ position: "absolute", right: 12, top: 12 }}
        >
          <CloseIcon style={CloseIconStyle} />
        </IconButton>
        <hr style={{ width: "100%" }} />
      </DialogTitle>

      <DialogContent
        style={{
          height: "auto",
          width: "auto",
          padding: "10px",
          paddingRight: "20px",
        }}
      >
        <Box
          style={{
            paddingLeft: "12px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Typography>Receivable Amount</Typography>
          <TextField
            placeholder="Transaction amount"
            size="small"
            value={txnData?.Receivable_Amount}
            disabled={true}
          />
        </Box>
        <Box
          style={{
            paddingLeft: "12px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "55px",
          }}
        >
          <Typography>New Amount</Typography>
          <TextField
            value={displayNewRequestedAmount()}
            placeholder="Enter new amount"
            size="small"
            onChange={(e) =>
              handleInputChange("New_Receivable_Amount", e.target.value)
            }
            disabled={
              currentWorkflowDetails?.workflow_status === "In Process" ||
                currentWorkflowDetails?.workflow_status === "Completed"
                ? true
                : false
            }
          />
        </Box>
        <Box
          style={{
            paddingLeft: "12px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "66px",
          }}
        >
          <Typography>Comments</Typography>
          <textarea
            rows="5"
            style={{
              height: "48px",
              minWidth: "190px",
              padding: "5px",
              boerderRadius: "5px",
            }}
            onChange={(e) => handleInputChange("comments", e.target.value)}
            placeholder="Add reason"
            disabled={handleCommentsAreaVisibility()}
            value={displayAddedComments()}
          ></textarea>
        </Box>

        {Object.keys(currentWorkflowDetails)?.length > 0 && (
          <Box
            style={{
              backgroundColor: "#afabaa",
              margin: "20px 0px 0px 10px",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <Typography style={{ color: "#000000" }}>
              Workflow Name : {currentWorkflowDetails?.workflow_json_data?.workflow_name}
            </Typography>
            <Typography style={{ color: "#000000" }}>
              Workflow Status : {currentWorkflowDetails?.workflow_status}
            </Typography>
            {currentWorkflowDetails?.current_step && (
              <Typography style={{ color: "#000000" }}>
                Current Step : {currentWorkflowDetails?.current_step}
              </Typography>
            )}
            <br />
            <DisplayCurrentStepDetails
              workflowStepData={
                currentWorkflowDetails?.workflow_json_data?.workflow_step
              }
              currentStep={currentWorkflowDetails?.current_step}
            />
          </Box>
        )}

        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handlePriceEditCloseDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          {navigatedFrom === "BANK_TRANSACTION"
            ? !Object.keys(currentWorkflowDetails)?.length > 0 && (
              <CommonButton
                text="Initiate Approval "
                hoverColor="#FF5A01"
                handleClick={() => handleInitiateClickBtn()}
                disabled={!(fieldsData?.New_Receivable_Amount && fieldsData?.comments)}
              />
            )
            : currentWorkflowDetails?.workflow_json_data?.workflow_step[0]?.user[0].id !== userData?.id && (
              <>
                <CommonButton
                  text="Reject"
                  hoverColor="#FF5A01"
                  handleClick={() => handleActionButton("Reject")}
                  disabled={!fieldsData?.comments}
                />
                <CommonButton
                  text="Approve"
                  hoverColor="#FF5A01"
                  handleClick={() => handleActionButton("Approve")}
                  disabled={!fieldsData?.comments}
                />
              </>
            )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditPriceDialog;

const DisplayCurrentStepDetails = ({ workflowStepData }) => {
  let element = null;

  const initiaterStepData = workflowStepData.filter(item => item?.step_name?.toLowerCase() === "initiater");
  const reviewerStepData = workflowStepData.filter(item => item?.step_name?.toLowerCase() === "reviewer");
  const approverStepData = workflowStepData.filter(item => item?.step_name?.toLowerCase() === "approver");

  if (workflowStepData?.length) {
    let reviewerUserNames = "";
    reviewerStepData[0]?.user?.forEach((user, index) => {
      const item = user?.user_name;
      if (index === 0) {
        reviewerUserNames = `${item}`;
      } else {
        reviewerUserNames = `${reviewerUserNames}, ${item}`;
      }
    });

    let approverUserNames = "";
    approverStepData[0]?.user?.forEach((user, index) => {
      const item = user?.user_name;
      if (index === 0) {
        approverUserNames = `${item}`;
      } else {
        approverUserNames = `${approverUserNames}, ${item}`;
      }
    });

    element = (
      <>
        <Typography style={{ color: "#000000" }}>
          Step Name : {initiaterStepData[0]?.step_name}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Initiator Comments :{initiaterStepData[0]?.comments}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Initiator Name : {initiaterStepData[0]?.user[0]?.user_name}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Date/Time : {initiaterStepData[0]?.ctime}
        </Typography>
        <br />

        <Typography style={{ color: "#000000" }}>
          Step Name : {reviewerStepData[0]?.step_name}
        </Typography>
        <Typography style={{ color: "#000000", marginTop: "5px" }}>
          Reviewer Comments :{reviewerStepData[0]?.comments}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Reviewer Name : {reviewerUserNames}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Date/Time : {reviewerStepData[0]?.ctime}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Status : {reviewerStepData[0]?.status}
        </Typography>

        <br />

        <Typography style={{ color: "#000000" }}>
          Step Name : {approverStepData[0]?.step_name}
        </Typography>
        <Typography style={{ color: "#000000", marginTop: "5px" }}>
          Approver Comments :{approverStepData[0]?.comments}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Approver Name : {approverUserNames}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Date/Time : {approverStepData[0]?.ctime}
        </Typography>
        <Typography style={{ color: "#000000" }}>
          Status : {approverStepData[0]?.status}
        </Typography>
      </>
    );
  }

  return element;
};
