import React, { useState, useEffect } from "react";
import GenericDonutChart from "./GenericDonutChart";
import { ReactComponent as NODateDonut } from '../../assets/icons/NodataDonuts.svg'

export const AllocationStatusChartNew = ({ data }) => {
    const [unallocatedStatusCount, setUnallocatedStatusCount] = useState({});
    const [noData, setNoData] = useState(true);
    const [totalUnallocatedCountPercent, setTotalUnallocatedCountPercent] = useState(0);
    const [totalUnAllocatedCount, setTotalUnAllocatedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (data?.unallocation_statuswise_count?.length) {
            setNoData(false);
        } else {
            setNoData(true);
        }
        let statusObj = {};
        data?.unallocation_statuswise_count?.forEach((element) => {
            statusObj[element.allocation_status] = {
                count: element.unallocation_status_count,
                amount: element.unallocated_amt
            };
        });
        setUnallocatedStatusCount(statusObj);

        const countPercent = (data?.total_unallocated_count / data?.total_count) * 100;
        setTotalUnallocatedCountPercent(countPercent ? parseFloat(countPercent).toFixed(2) : 0);

        setTotalCount(data?.total_count);

        setTotalUnAllocatedCount(data?.total_unallocated_count);
    }, [data]);

    const formattedData = (allocationData) => {

        let disableTooltipsObj = {};

        if (!allocationData || !allocationData[0]) {
            allocationData = [0, 1];
            disableTooltipsObj = {
                disableTooltips: true
            };
        }

        return {
            ...disableTooltipsObj,
            datasets: [
                {
                    data: allocationData,
                    backgroundColor: ['#0DA59D', '#0DA59D'],
                    hoverBackgroundColor: ['#0DA59D', '#0DA59D'],
                    cutout: 64,
                },
            ],
        };
    };

    const allocationStatusData = formattedData([
        {
            id: 1,
            count: unallocatedStatusCount?.["Query - Bind"]?.count || 0,
            title: "Query - Bind",
            amount: unallocatedStatusCount?.["Query - Bind"]?.amount || 0,
        },
        {
            id: 2,
            count: unallocatedStatusCount?.["Query - UWR"]?.count || 0,
            title: "Query - UWR",
            amount: unallocatedStatusCount?.["Query - UWR"]?.amount || 0,
        },
        {
            id: 3,
            count: unallocatedStatusCount?.["Query - GXB"]?.count || 0,
            title: "Query - GXB",
            amount: unallocatedStatusCount?.["Query - GXB"]?.amount || 0,
        },
        {
            id: 4,
            count: unallocatedStatusCount?.["Query - Binder"]?.count || 0,
            title: "Query - Binder",
            amount: unallocatedStatusCount?.["Query - Binder"]?.amount || 0,
        },
        {
            id: 5,
            count: unallocatedStatusCount?.["Query - Broker"]?.count || 0,
            title: "Query - Broker",
            amount: unallocatedStatusCount?.["Query - Broker"]?.amount || 0,
        },
        {
            id: 6,
            count: unallocatedStatusCount?.["Query - F&A"]?.count || 0,
            title: "Query - F&A",
            amount: unallocatedStatusCount?.["Query - F&A"]?.amount || 0,
        },
        {
            id: 7,
            count: unallocatedStatusCount?.["Query - UW Team"]?.count || 0,
            title: "Query - UW Team",
            amount: unallocatedStatusCount?.["Query - UW Team"]?.amount || 0,
        },
        {
            id: 8,
            count:
                unallocatedStatusCount?.["In Progress - CC"]?.count || 0,
            title: "In Progress - CC",
            amount: unallocatedStatusCount?.["In Progress - CC"]?.amount || 0,
        },
        {
            id: 9,
            count:
                unallocatedStatusCount?.["Query - Correction"]?.count || 0,
            title: "Query - Correction",
            amount: unallocatedStatusCount?.["Query - Correction"]?.amount || 0,
        },
    ]);


    return (
        <>
            {noData ?
                <NODateDonut />
                :
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <GenericDonutChart
                        ariaLabel="Donut for allocation status records"
                        data={allocationStatusData}
                        percentageInnerDonut={`${totalUnallocatedCountPercent}%`}
                        dataCy="allocation_status_doughnut"
                        title={"Allocation Status"}
                    />
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", width: 300 }}>
                            <span style={{ color: '#003B5C', fontWeight: 600 }}>{totalUnAllocatedCount}</span>
                            <span style={{ color: '#425A70' }}>Total UnAllocated Count</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", width: 200 }}>
                            <span style={{ color: '#003B5C', fontWeight: 600 }}>{totalCount}</span>
                            <span style={{ color: '#425A70' }}>Total Count</span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}