import React from 'react'

export default function RefundReport() {
  const reportURL = process.env.REACT_APP_REPORT_REFUND_URL
  return (
    <div style={{ width: "100%" }}>
      <iframe title="Refund" width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
    </div>
  )
}
