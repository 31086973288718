import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { PasswordInput, EmailField } from "../../Components/common";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import { Box, TextField, CircularProgress, Typography } from "@mui/material";
import Image from "../../assets/Images/Image.png";
import { API_URL } from "../../utils/config";
import { SuccessIcon } from "../../assets/icons";
import { CommonButton } from "../../Components/common/commonButton";
import "../../assets/scss/forgotPassword.scss";
import axios from "axios";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    checkPassword: "",
    otp: "",
  });
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);

  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);

  const getEmailVerificationStatus = async (emailId) => {
    try {
      setIsApiInProgress(true);
      const { data } = await axios.post(`${API_URL}users/check-user/`, {
        email: emailId,
      });
      setIsApiInProgress(false);

      const { statusCode } = data;

      if (statusCode === 200) {
        toast.success("OTP sent to the email.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsEmailVerified(true);
        setEmailValidationMessage("");
      } else {
        toast.error("Email is not Valid !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsEmailVerified(false);
      }
    } catch (err) {
      setIsApiInProgress(false);
      setIsEmailVerified(false);
      setEmailValidationMessage(err?.response?.data?.message);
    }
  };

  const getOTPVerificationStatus = async (otpInfo) => {
    try {
      setIsApiInProgress(true);
      const { data } = await axios.post(`${API_URL}users/verify-otp/`, {
        email: formData?.email,
        otp: otpInfo ?? "",
      });
      setIsApiInProgress(false);

      const { statusCode } = data;
      if (statusCode === 200) {
        toast.success("OTP Verified Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsOTPVerified(true);
      } else {
        toast.error("OTP is not valid !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsOTPVerified(false);
      }
    } catch (err) {
      setIsApiInProgress(false);
      setIsOTPVerified(false);
      toast.error("OTP is not valid !!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleFormSubmit = async () => {
    try {
      setIsApiInProgress(true);
      const { data } = await axios.post(`${API_URL}users/create-password/`, {
        email: formData?.email,
        password: formData?.password,
        repassword: formData?.checkPassword,
      });
      setIsApiInProgress(false);

      const { statusCode } = data;
      if (statusCode === 200) {
        setIsResetPasswordSuccess(true);
      } else {
        setIsResetPasswordSuccess(false);
        toast.error("Unable to update new password!!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      setIsApiInProgress(false);
      setIsResetPasswordSuccess(false);
      toast.error("Unable to update new password!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });

    // Verify email and send OTP
    if (key === "email") {
      if (value?.includes(".com")) getEmailVerificationStatus(value);
    }

    if (key === "otp") {
      if (value?.length === 6) getOTPVerificationStatus(value);
    }
  };

  const FlexBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
  }));

  ValidatorForm.addValidationRule(
    "isPasswordMatch",
    (value) => {
      return value === formData.password;
    },
    "Passwords do not match"
  );

  const NavigateToLoginPage = () => {
    navigate("/");
  };

  return (
    <div className="ForgotPasswordBox">
      <img src={Image} alt="image" className="ForgotPasswordImage" />
      {isResetPasswordSuccess ? (
        <div className="ResetPasswordSuccessBox">
          <span>
            <SuccessIcon className="successIcon" />
          </span>
          <p>Success</p>

          <span>Your password details are sent to your </span>
          <span style={{ fontWeight: 600 }}>{formData.email}</span>

          <span>Please check the mail and log in.</span>
          <CommonButton
            className={"forgotSectionDialogOk"}
            type="submit"
            text="OK"
            handleClick={NavigateToLoginPage}
            hoverColor="#FF5A01"
          />
        </div>
      ) : (
        <div className="ForgotPasswordMainPageBox">
          <p className="ForgotPasswordText">Forgot Password</p>
          <ValidatorForm
            onSubmit={handleFormSubmit}
            className="ValidationForms"
          >
            <EmailField
              value={formData.email}
              placeholder="Enter your Email ID"
              handleChange={handleChange}
              isDisabled={isEmailVerified}
              name={"email"}
            />

            {isEmailVerified && (
              <div>
                <TextField
                  sx={{ mb: 3, width: "100%" }}
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  name="otp"
                  value={formData.otp}
                  placeholder="Enter OTP"
                  InputProps={{
                    autoComplete: "new-password",
                    className: "enterOtpField",
                  }}
                />

                {isOTPVerified && (
                  <>
                    <PasswordInput
                      value={formData.password}
                      handleChange={handleChange}
                    />
                    <PasswordInput
                      name="checkPassword"
                      value={formData.checkPassword}
                      placeholder="Confirm password"
                      handleChange={handleChange}
                      validators={["isPasswordMatch"]}
                      errorMessages={[
                        "Confirmation password does not match with the new password.",
                      ]}
                    />
                  </>
                )}
              </div>
            )}

            <Typography sx={{ color: "red" }}>
              {emailValidationMessage}
            </Typography>

            <FlexBox justifyContent="flex-start" mt={2} sx={{ gap: "20px" }}>
              <CommonButton
                // sx={forgotSecCancelBtn}
                className={"forgotSecCancelBtn"}
                text="Cancel"
                handleClick={() => navigate("/")}
                variant="contained"
              />
              <CommonButton
                className={"forgotSecSubmitBtn"}
                text="Submit"
                type="submit"
                variant="contained"
                disabled={
                  !isEmailVerified ||
                  !isOTPVerified ||
                  !formData.password ||
                  !formData.checkPassword ||
                  formData?.password !== formData?.checkPassword
                }
                hoverColor="#FF5A01"
              />
              {isApiInProgress && (
                <CircularProgress
                  style={{ position: "relative", top: "5px", right: "150px", color: "rgb(255, 90, 1)" }}
                />
              )}
            </FlexBox>
          </ValidatorForm>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;
