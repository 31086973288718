/**
 * @desc returns dashboard colors
 */
export const doughnutColors = {
  Mosaic_Blue: '#003B5C',
  Mosaic_gold: '#F2A900',
  Mosaic_gold_secondary: '#C28800',
  Mosaic_Amaranth: '#D1344F',
  Mosaic_teal: '#0DA59D',
  Mosaic_Light_Gray: '#d1d3d4',
  Mosaic_Orange: '#f68d2e',
  Mosaic_Orange_Secondary: '#FF5F42',
  Mosaic_Dark_Blue_Gray: '#42788f',
  Mosaic_Blue_Gray: '#8DB9CA',
  Mosaic_Dark_Gray: '#54585A',
  Mosaic_Yellow: '#FFEC2D',
  Mosaic_Green: '#509E2F',
  Mosaic_Green_Primary: '#005D5D',
  Mosaic_Blue_Gray_Tint1: '#D5E5EB',
  critical_red: '#cc0c00',
  medium_gold: '#FBE9BF',
  Mosaic_Dark_Orange: '#E6740A',
  low_teal: 'c',
  Mosaic_Med_Gray: '#97999B',
  Mosaic_White: '#fff',
  Mosaic_Dark_light_blue: '#075A92',
  Mosaic_light_pink: '#FDE2CB',
  cs_pink_primary: '#EE538B',
  Mosaic_Purple: '#A56EFF',
  Mosaic_Brown: '#9F1853',
  Mosaic_mid_blue: '#1E5879',
  Mosaic_dark_purple: '#6929C4',
  Mosaic_dark_yellow: '#C28800',
  Mosaic_dark_magenta: '#9F1853',
  Mosaic_green: '#509E2F',
  Mosaic_textBlue: '#425A70',
  Mosaic_no_data: '#909094',
  Mosaic_gray: '#E7E7E7',
  Mosaic_violet: '#664ea6',
  Mosaic_cyan: '#00ffff'
};

export const colorSetFor3d = ['#003B5C', '#0DA59D', '#E6740A', '#42788F', '#D1344F', '#1E5879',
  '#A56EFF',
  '#54585A', '#6929C4', '#C28800', '#509E2F', '#005D5D', '#9f1853'];