import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { read } from "xlsx";
import dayjs from "dayjs";
import {
  Radio,
  Checkbox,
  Tooltip,
  Stack,
  FormLabel,
  Input,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Card,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { CommonButton } from "../common/commonButton";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer } from "../common";
import { } from "../../utils/generalFunctions";
import instance from "../../redux/api";
import { premiumBDX, exceptionReport } from "../../Services/api";
import DialogBox from "../common/dialog";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import GetViewTable from "./GetViewTable";
import { getPaginationCount } from "../CashAllocation/utils";
import { CustomLoader } from "../CustomLoader";

function handleAmount(val) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    (Number(val) && Number(val).toLocaleString("en-US", options)) ||
    (val === 0 && parseInt(val).toFixed(2)) ||
    val
  );
}

export const inputStyleObj = {
  border: "2px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  height: 38,
  width: "260px",
  borderRadius: 5,
};
export const selectStyleObj = {
  border: "1px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  width: "100%",
  height: 38,
  borderRadius: 5,
};
export const filterInputStyleObj = {
  border: "2px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  height: 38,
  width: "100%",
  borderRadius: 5,
};

export const buttonsFixations = {
  bottom: 15,
  left: 5,
  right: 0,
  backgroundColor: "white",
  borderTop: "1px solid #ddd",
  padding: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "10px",
  width: "98%",
  position: "absolute",
}
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dialogStyle = {
  maxWidth: "95vw",
  width: "85vw",
  margin: "auto",
  maxHeight: "80vh",
};

const PremiumBDX = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const policyNo = new URLSearchParams(search).get("policyNo");
  const fileName = new URLSearchParams(search).get("fileName");

  const { userData } = useSelector((state) => state?.user);

  const [selectedValue, setSelectedValue] = useState(null);
  const [searchFieldsParams, setSearchFieldsParams] = useState({
    filename: "",
    month: "",
    year: "",
    lob: "",
  });

  const [paginationCount, setPaginationCount] = useState(0);
  const [currentRow, setCurrentRow] = useState("");
  const [txnMode, setTxnMode] = useState("");

  const [isOpenAddFileDialog, setOpenAddFileDialog] = useState(false);
  const [isOpenViewDialog, setOpenViewDialog] = useState(false);

  const toggle = useSelector((state) => state.toggleSideMenu);
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");

  const [isOpenWorkflowDialog, setIsOpenWorkflowDialog] = useState(false);

  const [filenameArray, setFilenameArray] = useState([]);
  const [certificateRefArray, setCertificateRefArray] = useState([]);

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);

  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [filenameToReplace, setFilenameToReplace] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [excelFileSheetNames, setExcelFileSheetNames] = useState(null);
  const [selectedExcelSheetName, setSelectedExcelSheetName] = useState(null);
  const [selectedExcelSheetJson, setSelectedExcelSheetJson] = useState(null);
  const [currentTotalFNP, setCurrentTotalFNP] = useState(0);
  const [currentSelectedSheetRowCount, setCurrentSelectedSheetRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [sheetNameToReplace, setSheetNameToReplace] = useState(null);
  const [isApiProgressBar, setIsApiProgressBar] = useState(false);

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleRadioChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedValue(isChecked ? e.target.value : "");
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      handleClearSearchList();
    }
    console.log("name", name, "value", value);
    setSearchFieldsParams((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { filename, month, year, lob } = searchFieldsParams;

    if (filename) {
      getSearchQueryParams = `${getSearchQueryParams}&file_name=${filename}`;
    }

    if (month) {
      getSearchQueryParams = `${getSearchQueryParams}&month=${month}`;
    }

    if (year) {
      getSearchQueryParams = `${getSearchQueryParams}&year=${year}`;
    }

    if (lob) {
      getSearchQueryParams = `${getSearchQueryParams}&lob=${lob}`;
    }

    if (fromDateValue && toDateValue) {
      let formattedFromDateValue = format(fromDateValue, "yyyy-MM-dd");
      let formattedToDateValue = format(toDateValue, "yyyy-MM-dd");
      getSearchQueryParams = `${getSearchQueryParams}&from_date=${formattedFromDateValue}&to_date=${formattedToDateValue}`;
    }

    return getSearchQueryParams;
  };

  const handleSearchPaymentUpdatesList = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${premiumBDX}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1, // required for sending skip as 0
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.log("error", error);
    }
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);
    setSearchFieldsParams({
      filename: "",
      year: "",
      month: "",
      lob: "",
    });
    setFromDateValue("");
    setToDateValue("");
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { filename, month, year, lob } = searchFieldsParams;
    if (
      filename !== "" ||
      month !== "" ||
      year !== "" ||
      lob !== "" ||
      fromDateValue !== "" ||
      toDateValue !== ""
    ) {
      disabled = false;
    }
    return disabled;
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = ``;

      if (fileName) {
        queryParams = `${queryParams}&file_name=${fileName.replace(
          "&",
          "%26",
        )}`;
      }
      if (policyNo) {
        queryParams = `${queryParams}&Certificate_Ref=${policyNo}`;
      }

      // add search query param if the search is enabled
      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      const response = await instance.get(
        `${premiumBDX}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
        }${queryParams}`,
      );

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const downloadFile = async (currentRowData) => {
    let queryParams = `?`;
    let filename = currentRowData?.filename || "";
    let tab_name = currentRowData?.sheettab || "";
    if (filename) {
      queryParams = `${queryParams}&file_name=${filename.replace("&", "%26")}`;
    }
    if (tab_name) {
      queryParams = `${queryParams}&tab_name=${tab_name}`;
    }
    queryParams = `${queryParams}&action=download`;

    const response = await instance.get(`${premiumBDX}${queryParams}`);
    console.log("download response", response);
    const file_link = Object.values(response.data.Data)[0];
    console.log("file link", file_link);
    let link = document.createElement("a");
    link.href = file_link;
    link.click();
  };

  const handleActionButton = (currentRowData, currentTxnMode) => {
    setCurrentRow(currentRowData);
    setTxnMode(currentTxnMode);
    switch (currentTxnMode) {
      case "VIEW":
        setOpenViewDialog(true);
        handleGetFileToView(currentRowData);

        break;
      case "EDIT":
        setFilenameToReplace(currentRowData.filename);
        setSheetNameToReplace(currentRowData.sheettab);
        setOpenAddFileDialog(true);
        break;

      case "DELETE":
        setFilenameToReplace(currentRowData.filename);
        setOpen(true);
        break;

      case "DOWNLOAD":
        downloadFile(currentRowData);
        break;

      case "WORKFLOW":
        setIsOpenWorkflowDialog(true);
        break;

      default:
        break;
    }
  };

  const handleDeleteConfirmed = async () => {
    try {
      const payload = {
        file_name: filenameToReplace,
        action: "archieve",
        analyst_id: userData.id,
      };

      const response = await instance.post(`${exceptionReport}`, payload);

      if (response.status === 200) {
        toast.success(`${filenameToReplace} deleted successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpen(false);
        setFilenameToReplace(null);
        setSheetNameToReplace(null);
        loadData();
      } else {
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpen(false);
        setFilenameToReplace(null);
        setSheetNameToReplace(null);
      }
    } catch (error) {
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setOpen(false);
      setFilenameToReplace(null);
      setSheetNameToReplace(null);
    }
  };

  const handleDeleteCancelled = () => {
    setOpen(false);
    setFilenameToReplace(null);
    setSheetNameToReplace(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputFileChange = useCallback((e) => {
    const { name, files } = e.target;
    const allowedFormats = [
      "application/pdf",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (name === "file" && files.length > 0) {
      const file = files[0];

      if (allowedFormats.includes(file.type)) {
        setFileToUpload(file);
        if (file.type === allowedFormats[2]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            var data = e.target.result;
            var workbookInner = read(data, { type: "binary" });
            var sheet_name_list = workbookInner.SheetNames;
            setExcelFileSheetNames(sheet_name_list);
          };
          reader.readAsArrayBuffer(file);
        }
      } else {
        alert("Invalid file format. Please select a PDF, CSV, or XLSX file.");
        return;
      }
    }
  });

  const setHandleIntermediateView = async () => {
    try {
      const form = new FormData();
      form.append("tab_name", selectedExcelSheetName);
      form.append("file", fileToUpload);
      form.append("analyst_id", userData.id);
      form.append("action", "parse");

      setIsApiProgressBar(true);
      const response = await instance.post(`${premiumBDX}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setSelectedExcelSheetJson(response.data.Response);
        setCurrentTotalFNP(response.data.total);
        setCurrentSelectedSheetRowCount(response.data.count);
        setOpenViewDialog(true);
        setIsApiProgressBar(false);
      } else {
        toast.error(`Error in retrieving file records`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsApiProgressBar(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsApiProgressBar(false);
      toast.error(error?.data?.Response ?? `Error in retrieving file records`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleGetFileToView = async (row) => {
    let queryParams = `?`;

    if (row?.filename) {
      queryParams = `${queryParams}file_name=${row?.filename.replace(
        "&",
        "%26",
      )}`;
    }
    if (row?.sheettab) {
      queryParams = `${queryParams}&tab_name=${row?.sheettab}`;
    }
    queryParams = `${queryParams}&action=view`;
    setIsApiProgressBar(true);
    try {
      const response = await instance.get(`${premiumBDX}${queryParams}`);
      setSelectedExcelSheetJson(response?.data?.Response);
      setCurrentTotalFNP(response.data.total);
      setCurrentSelectedSheetRowCount(response.data.count);
      setIsApiProgressBar(false);
    } catch (err) {
      setIsApiProgressBar(false);
      console.log("err", err);
    }
  };

  const handleSubmitFile = async () => {
    try {
      setIsSubmitInProgress(true);

      let payload = {
        action: "add",
        analyst_id: userData.id,
        comments: "automatic workflow initiated",
        initiateWF: true,
        response: selectedExcelSheetJson,
      };
      if (txnMode === "EDIT") {
        payload.action = "archieve";
        payload.old_file_name = filenameToReplace;
      } 

      const responseException = await instance.post(`${exceptionReport}`, payload);
      setIsSubmitInProgress(false);

      if (responseException.status === 200 || responseException.status === 201) {
        if (txnMode === "ADD") {
          toast.success(`${responseException.data.Message}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success(
            `File ${txnMode === "ADD" ? "added" : "replaced"} successfully`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            },
          );
        }

        setFileToUpload(null);
        setExcelFileSheetNames(null);
        setSelectedExcelSheetName(null);
        setSelectedExcelSheetJson(null);
        setOpenAddFileDialog(false);
        setOpenViewDialog(false);
        setFilenameToReplace(null);
        setSheetNameToReplace(null);
        setIsSubmitInProgress(false);

        // Navigate Exception Report
        navigate("/BDX/ExceptionReport");
      } else {
        setIsSubmitInProgress(false);
        toast.error(
          `Error ${txnMode === "ADD" ? "adding" : "replacing"} file`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          },
        );
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitInProgress(false);
      toast.error(
        `Error ${txnMode === "ADD" ? "adding" : "replacing"} file: ${error.data.Response || ""
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        },
      );
    }
  };

  const columns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedValue === params?.row?.filename}
            onChange={handleRadioChange}
            value={params?.row?.filename}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "filename",
      headerName: "File Name",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <>
          <Tooltip
            title={params.row.filename}
          >{`${params.row?.filename.substring(0, 24)}...`}</Tooltip>
        </>
      ),
    },
    {
      field: "sheettab",
      headerName: "Sheet Name",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.6,
    },
    {
      field: "month",
      headerName: "Month",
      headerAlign: "center",
      minWidth: "15%",
      flex: 0.3,
    },
    {
      field: "year",
      headerName: "Year",
      headerAlign: "center",
      minWidth: "10%",
      flex: 0.3,
    },
    {
      field: "total",
      headerName: "Final Net Settlement",
      headerAlign: "center",
      minWidth: "10%",
      flex: 0.5,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params.row?.total)}</>,
    },

    {
      field: "count",
      headerName: "BDX Txn#",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => <>{params.row?.count}</>,
    },
    {
      field: "Analyst Name",
      headerName: "Analyst Name",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => <>{params.row["Analyst Name"]}</>,
    },
    {
      field: "processingdate",
      headerName: "Loaded Date",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {dayjs(params.row?.processingdate, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.6,
      description: "This column has a value getter and is not sortable.",
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        actionsList.push(
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
            className="textPrimary"
            label="Edit"
            onClick={() => handleActionButton(row, "EDIT")}
            color="inherit"
          />,
        );

        actionsList.push(
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
            label="delete"
            className="textPrimary"
            onClick={() => handleActionButton(row, "DELETE")}
            color="inherit"
          />,
        );

        actionsList.push(
          <GridActionsCellItem
            icon={
              <Tooltip title="View">
                <ViewIcon />
              </Tooltip>
            }
            label="View"
            className="textPrimary"
            onClick={() => handleActionButton(row, "VIEW")}
            color="inherit"
          />,
        );

        actionsList.push(
          <GridActionsCellItem
            icon={
              <Tooltip title="Download">
                <DownloadIcon className="action-icon" />
              </Tooltip>
            }
            label="download"
            className="textPrimary"
            onClick={() => handleActionButton(row, "DOWNLOAD")}
            color="inherit"
          />,
        );

        return actionsList;
      },
    },
  ];

  useEffect(() => {
    setPaginationCount(
      getPaginationCount(pageState?.total, pageState?.pageSize),
    );
  }, [pageState?.pageSize, pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  return (
    <div style={Container}>
      <Grid
        container
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={"12px"}
      >
        <Grid
          container
          xs={11}
          gap={"6px"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={1.1}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Year</FormLabel>

            <Input
              style={{ ...filterInputStyleObj }}
              name="year"
              onChange={handleFilterChange}
              value={searchFieldsParams.year}
              placeholder="Type year"
              required
              disableUnderline={true}
            />
          </Grid>
          <Grid
            item
            xs={1.4}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel> Month</FormLabel>
            <Select
              style={{ ...selectStyleObj }}
              name="month"
              onChange={handleFilterChange}
              value={searchFieldsParams.month}
            >
              <MenuItem value={""} disabled selected>
                Select Month
              </MenuItem>
              {months.map((ele) => (
                <MenuItem key={ele} value={ele}>
                  {ele}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            xs={1.4}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel> LOB</FormLabel>
            <Input
              style={{ ...filterInputStyleObj }}
              name="lob"
              onChange={handleFilterChange}
              value={searchFieldsParams.lob}
              placeholder="Type LOB"
              required
              disableUnderline={true}
            />
          </Grid>

          <Grid
            item
            xs={2.2}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel> File Name</FormLabel>
            <Input
              style={{ ...filterInputStyleObj }}
              name="filename"
              onChange={handleFilterChange}
              value={searchFieldsParams.filename}
              placeholder="Type filename"
              required
              disableUnderline={true}
            />
          </Grid>

          <Grid
            item
            xs={2.8}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Date Range </FormLabel>
            <CustomDateRangePicker
              startDate={fromDateValue}
              setStartDate={setFromDateValue}
              endDate={toDateValue}
              setEndDate={setToDateValue}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"} gap={"20px"}>
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={handleSearchPaymentUpdatesList}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            gap={"20px"}
            display={"flex"}
            flexDirection={"column"}
            ml={"5px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
        </Grid>

        <Grid
          xs={1}
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
        >
          <Grid item>
            <FormLabel></FormLabel>
            <CommonButton
              text="Add"
              style={{ width: "80px", marginBottom: "5px" }}
              handleClick={() => {
                setTxnMode("ADD");
                setOpenAddFileDialog(true);
              }}
              className="addFileBtn"
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          height: "calc(100vh - 262px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          getRowId={(row) => pageState?.data.indexOf(row)}
          rowCount={pageState?.total}
          columns={columns}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(
                getPaginationCount(pageState?.total, newPageSize),
              );
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>

      <Dialog
        open={isOpenAddFileDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          {txnMode === "ADD"
            ? "Add File"
            : txnMode === "EDIT"
              ? "Replace File"
              : null}
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAddFileDialog(false);
              setOpenViewDialog(false);
              setFileToUpload(null);
              setExcelFileSheetNames(null);
              setSelectedExcelSheetName(null);
              setSelectedExcelSheetJson(null);
            }}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon className="CloseIconStyle" />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "0px 24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>File Name: </TableCell>
                  <TableCell>{fileToUpload?.name ?? ""}</TableCell>
                  <TableCell>Details:</TableCell>
                  <TableCell>{fileToUpload?.size ?? ""}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} sx={{ margin: "10px 0px" }}>
            <Button
              color="inherit"
              variant="outlined"
              component="label"
              style={{ height: "30px", fontWeight: 600 }}
            >
              {txnMode === "ADD"
                ? "Select New File to Upload"
                : txnMode === "EDIT"
                  ? "Select File to Replace"
                  : null}
              <input
                type="file"
                hidden
                name="file"
                accept=".doc,.docx,.xlsx,.csv,.pdf"
                onChange={(e) => {
                  setSelectedExcelSheetName(null);
                  handleInputFileChange(e);
                }}
                required
              />
            </Button>
          </Grid>
          {excelFileSheetNames && (
            <Card
              elevation={5}
              sx={{ marginTop: "10px", overflow: "auto !important" }}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                <span style={{ fontWeight: 600 }}>Please select a sheet:</span>
              </div>
              <div style={{ margin: "8px" }}>
                <RadioGroup
                  name="file-upload-selection"
                  value={selectedExcelSheetName}
                  onChange={(e) => setSelectedExcelSheetName(e.target.value)}
                >
                  {excelFileSheetNames.map((sheetName, index) => (
                    <FormControlLabel
                      key={index}
                      value={sheetName}
                      control={<Radio />}
                      label={sheetName}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Card>
          )}

          {isApiProgressBar && <CustomLoader />}

          <Grid item xs={12} marginTop={4} marginBottom={"50px"}>
            <Button
              color="inherit"
              variant="outlined"
              component="label"
              style={{ height: "30px", fontWeight: 600 }}
              onClick={() =>
                selectedExcelSheetName ? setHandleIntermediateView() : null
              }
              disabled={!selectedExcelSheetName || isApiProgressBar}
            >
              View File
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenViewDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", position: "relative" }}
        >
          {"View File"}
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenViewDialog(false);
              if (txnMode === "VIEW") {
                setSelectedExcelSheetJson(null);
              }
            }}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon className="CloseIconStyle" />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "30px 30px 30px 30px",
            display: "flex",
          }}
        >
          <Grid lg={12} display={"flex"} flexDirection={"column"}>
            {isApiProgressBar && <CustomLoader />}
            <Grid
              xs={12}
              lg={12}
              gap={1}
              container
              className="ViewpageContainerItems"
            >
              {selectedExcelSheetJson && (
                <GetViewTable
                  selectedExcelSheetJson={selectedExcelSheetJson}
                  setSelectedExcelSheetJson={setSelectedExcelSheetJson}
                  setFilenameArray={setFilenameArray}
                  setCertificateRefArray={setCertificateRefArray}
                  currentTotalFNP={currentTotalFNP}
                  currentSelectedSheetRowCount={currentSelectedSheetRowCount}
                />
              )}
            </Grid>
            <Grid>
              {isOpenAddFileDialog && (
                <div
                  style={buttonsFixations}
                >
                  <CommonButton
                    text={
                      txnMode === "ADD"
                        ? "Save and Generate Exception Report"
                        : txnMode === "EDIT"
                          ? "Replace"
                          : null
                    }
                    handleClick={() => handleSubmitFile()}
                    className="bankStatementBtn"
                    hoverColor="#EE3F00"
                    style={{
                      width: txnMode === "ADD" ? "300px" : "auto",
                    }}
                    disabled={isSubmitInProgress}
                  />
                  <CommonButton
                    text="Cancel"
                    handleClick={() => {
                      setOpenAddFileDialog(false);
                      setOpenViewDialog(false);
                      setFileToUpload(null);
                      setExcelFileSheetNames(null);
                      setSelectedExcelSheetName(null);
                      setSelectedExcelSheetJson(null);
                    }}
                    className="bankStatementBtn"
                    hoverColor="#EE3F00"
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenWorkflowDialog}
        onClose={() => setIsOpenWorkflowDialog(false)}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "500px",
          },
        }}
      >
        {currentRow.certificateref === "PCY2812924AD" ? (
          <div>
            <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
              {"Workflow Initiated"}
              <IconButton
                aria-label="close"
                onClick={() => {
                  setIsOpenWorkflowDialog(false);
                }}
                style={{ position: "absolute", right: 12, top: 12 }}
              >
                <CloseIcon className="CloseIconStyle" />
              </IconButton>
              <hr style={{ width: "100%" }} />
            </DialogTitle>
            <DialogContent
              sx={{
                padding: "0px 24px 5px 24px",
                display: "flex",
                fontSize: "12px",
              }}
            >
              <Grid
                lg={12}
                display={"flex"}
                flexDirection={"column"}
                backgroundColor={"rgb(175, 171, 170)"}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  marginBottom={2}
                >
                  <div>Workflow Name : NO_VARIANCE_WORKFLOW</div>
                  <div>Workflow Status : In Process</div>
                  <div>Current Step : reviewer</div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  marginBottom={2}
                >
                  <div>Step Name : initiater</div>
                  <div>Initiator Comments :</div>
                  <div>Initiator Name : vijay kumar reddy</div>
                  <div>Date/Time : 2024-06-21 12:40:01.251541</div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  marginBottom={2}
                >
                  <div>Step Name : reviewer</div>
                  <div>Reviewer Comments :</div>
                  <div>
                    Reviewer Name : Rupesh, lrname, Reviewer0, Shivali123
                  </div>
                  <div>Date/Time :</div>
                  <div>Status :</div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  marginBottom={2}
                >
                  <div>Step Name : approver</div>
                  <div>Approver Comments :</div>
                  <div>
                    Approver Name : Test Treasury, maname, sairam, Kerry Fenn,
                    Approver0
                  </div>
                  <div>Date/Time :</div>
                  <div>Status :</div>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
              {"Workflow Not Initiated"}
              <IconButton
                aria-label="close"
                onClick={() => {
                  setIsOpenWorkflowDialog(false);
                }}
                style={{ position: "absolute", right: 12, top: 12 }}
              >
                <CloseIcon className="CloseIconStyle" />
              </IconButton>
              <hr style={{ width: "100%" }} />
            </DialogTitle>
            <DialogContent
              sx={{
                padding: "0px 24px 5px 24px",
                display: "flex",
                fontSize: "12px",
              }}
            >
              <Grid
                lg={12}
                display={"flex"}
                flexDirection={"column"}
                backgroundColor={"rgb(175, 171, 170)"}
              ></Grid>
              There is variance in data. Please check Exception report or upload
              updated Premium BDX File.
            </DialogContent>
          </div>
        )}
      </Dialog>
      <DialogBox
        title="Delete Confirmation"
        body="Are you sure you want to delete this item? If there are multiple files associated with this file name, all those will be deleted."
        confirmText="Delete"
        isOpen={open}
        onCancel={handleDeleteCancelled}
        onConfirm={handleDeleteConfirmed}
      />
    </div>
  );
};

export default PremiumBDX;
