import React from "react";

export default function CorrectiveTransfers() {
  const reportURL = process.env.REACT_APP_REPORT_CORRECTIVE_TRANSFER_URL
  return (
    <div style={{ width: "100%" }}>
      <iframe
        title="Corrective Transfers"
        width="1140"
        height="541.25"
        src={reportURL ?? ""}
        frameborder="0"
        allowFullScreen="true"
      ></iframe>{" "}
    </div>
  );
}
