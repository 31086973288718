import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const CommonSelect = ({
  customStyles = { minWidth: 100, maxWidth: 'auto' },
  disabled,
  handleChange,
  name,
  options,
  placeholder,
  size = 'small',
  value = '',
  handleInputChage = () => {},
  required = false,
}) => {
  return (
    <Autocomplete
      autoHighlight
      disabled={disabled}
      name={name}
      onChange={(event, value, reason) => handleChange(event, value, reason)}
      options={options}
      sx={customStyles}
      size={size}
      value={value}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          {...params}
          inputProps={{
            ...params.inputProps,
            required: required && !value,
          }}
          error={required && !value}
          helperText={required && !value ? 'This field is required' : ''}
          onChange={(e: any) => {
            handleInputChage(e.target.value);
            console.log("input value", e.target.value);
            // setDataProp(e.target.value);
            // if (typeof props?.func === "function")
            //   props.func(e.target.value);
          }}
        />
      )}
    />
  );
};
