import { Button } from "@mui/material";

export const CommonButton = ({
  size = "small",
  type = "button",
  sx,
  text,
  disabled,
  style,
  handleClick,
  variant = "contained",
  icon,
  className,
  hoverColor,
}) => {
  return (
    <Button
      variant={variant}
      style={style}
      size={size}
      type={type}
      sx={{
        "&:hover": {
          backgroundColor: hoverColor || "inherit",
        },
        ...sx,
      }}
      disabled={disabled}
      onClick={handleClick}
      className={className}
    >
      {text}
      {icon}
    </Button>
  );
};
