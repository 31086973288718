import React from 'react'

export default function ReceivableByEntity() {
  const reportURL = process.env.REACT_APP_REPORT_RECEIVABLE_BY_ENTITY_URL
  return (
    <div>
      <iframe title="Receivable by Entity" width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
    </div>
  )
}
