import { FormLabel, Grid, TextField } from "@mui/material";


export const ViewAndEditEntity = ({

  handleInputChange,
  index,
  row,
  viewMode,
  validationStatus,
}) => {
  //inline style
  const InlineStyle = {
    EntityDivison: {
      width: "230px", height: "32", padding: "0px"
    },
  }
  return (
    <form>
      <Grid container justifyContent={"space-between"} sx={{ width: "80%" }}>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>LOB Code</FormLabel>
          <TextField
            value={row.lob_code}
            onChange={(e) =>
              handleInputChange(index, "lob_code", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.lob_code && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.lob_code}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>LOB </FormLabel>
          <TextField
            value={row.lob}
            onChange={(e) =>
              handleInputChange(index, "lob", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.lob && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.lob}
            </span>
          )}
        </Grid>

      </Grid>
    </form>
  );
}