import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";


//inline styles
const InlineStyles = {
    TableHeader: {
        backgroundColor: '#262671',
        color: "white",
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: "50px"
    },
    TableCells: {
        color: 'black',
        fontSize: '12px',
        height: '44px',
        padding: '0px'
    },
    TableBodyRows: {
        border: '1px solid #ddd', marginTop: "10px", paddingTop: "15px", verticalAlign: "top"
    },
    TableBodyRowCells: {
        height: '20px', padding: '0px'
    },
    PaymentReceiveDate: {
        width: "100px", height: "33px", padding: "0px"
    },
    BrokerBranchTableCell: {
        height: "20px", padding: "3px"
    },
    ReceivableAmountTableCell: {
        height: "20px", padding: "3px"
    },
    PaymentCurrencyCodeTableCell: {
        height: "20px", padding: "3px"
    },
    BankExchangeRateTableCell: {
        height: "20px", padding: "3px"
    },
    BankExchangeChargesTableCell: {
        height: "20px", padding: "3px"
    },
    entity_divisionsTableCell: {
        height: "20px", padding: "3px"
    },
    entity_divisions: {
        position: "relative", width: "123px", height: "32"
    },
    MoreVertIconStyle: {
        color: 'FF5A01',
        background: 'transparent',
        cursor: 'pointer',
        transform: 'rotate(90deg)'
    },
    BankChargesTableCell: {
        height: "20px", padding: "3px"
    }

}
export const AddPremium = ({

    selectAll,
    handleSelectAll,
    handleInputChange,
    handleCheckboxChange,
    rows,
    viewMode,
    validationStatus,
    handleentity_divisionsOpenDialog,
    selectedRows,
}) => {

    return (
        <Table>
            <TableHead
                style={InlineStyles.TableHeader}>
                <TableRow style={{ backgroundColor: "#FFC000" }}>
                    <TableCell style={InlineStyles.TableCells}>
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            style={{ color: "black" }}
                        />
                    </TableCell>
                    <TableCell className='TableCell'>Region</TableCell>
                    <TableCell className='TableCell'>Entity Number</TableCell>
                    <TableCell className='TableCell'>MSD Entity Number</TableCell>
                    <TableCell className='TableCell'>Entity Name</TableCell>
                    <TableCell className='TableCell'>Bank Name</TableCell>
                    <TableCell className='TableCell'>Account Number</TableCell>
                    <TableCell className='TableCell'>Account Opening Date</TableCell>
                    <TableCell className='TableCell'>Account Type</TableCell>
                    <TableCell className='TableCell'>Currency</TableCell>
                    <TableCell className='TableCell'>MSD Account Number</TableCell>
                    <TableCell className='TableCell'>MSD Account Name</TableCell>
                    

                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index} style={InlineStyles.TableBodyRows}>
                        <TableCell sx={InlineStyles.TableBodyRowCells}>
                            <Checkbox
                                checked={selectedRows[index] || false}
                                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            />
                        </TableCell>
                    
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.region}
                                onChange={(e) => handleInputChange(index, 'region', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.region && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.region}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.entity_number}
                                onChange={(e) => handleInputChange(index, 'entity_number', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.entity_number && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.entity_number}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.msd_entity_number}
                                onChange={(e) => handleInputChange(index, 'msd_entity_number', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.msd_entity_number && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.msd_entity_number}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.entity_name}
                                onChange={(e) => handleInputChange(index, 'entity_name', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.entity_name && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.entity_name}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.bank_name}
                                onChange={(e) => handleInputChange(index, 'bank_name', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.bank_name && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.bank_name}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.account_number}
                                onChange={(e) => handleInputChange(index, 'account_number', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.account_number && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.account_number}</span>
                            )}
                        </TableCell>
                        <TableCell sx={{ padding: "3px" }}>
              <input
                type="date"
                value={
                  row.account_opening_date
                    ? row.account_opening_date.substring(0, 10)
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "account_opening_date",
                    e.target.value
                  )
                }
                style={InlineStyles.PaymentReceiveDate}
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.account_opening_date && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.account_opening_date}
                </span>
              )}
            </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.account_type}
                                onChange={(e) => handleInputChange(index, 'account_type', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.account_type && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.account_type}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.currency}
                                onChange={(e) => handleInputChange(index, 'currency', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.currency && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.currency}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.msd_acct_number}
                                onChange={(e) => handleInputChange(index, 'msd_acct_number', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.msd_acct_number && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.msd_acct_number}</span>
                            )}
                        </TableCell>
                        <TableCell sx={InlineStyles.binding_aggrement_type}>
                            <TextField
                                value={row.msd_acct_name}
                                onChange={(e) => handleInputChange(index, 'msd_acct_name', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.msd_acct_name && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.msd_acct_name}</span>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}