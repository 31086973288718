import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CommonButton } from "./Components/common/commonButton";

export default function TimeoutProvider({ children }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isLoggedIn = Cookies.get("isLoggedIn", "");

  const navigate = useNavigate();
  const location = useLocation();
  const sessionTimeOutRef = useRef();
  const sessionWarningTimeOutRef = useRef();

  const sessionTimeOutTime = 60 * 60 * 1000;
  const sessionWarningTime = 59 * 60 * 1000;

  const eventsList = [
    "mousedown",
    "mousemove",
    "click",
    "wheel",
    "keydown",
    "touchstart",
    "scroll",
  ];

  const handleWindowEvents = () => {
    clearTimeout(sessionTimeOutRef.current);
    clearTimeout(sessionWarningTimeOutRef.current);

    // Auto Logout
    sessionTimeOutRef.current = setTimeout(() => {
      handleLogout();
    }, sessionTimeOutTime);

    // Open Session Expire Warning Dailog
    sessionWarningTimeOutRef.current = setTimeout(() => {
      setIsDialogOpen(true);
    }, sessionWarningTime);
  };

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname)) return;

    // listen for specific window events to ensure user is still active
    eventsList.forEach((event) =>
      window.addEventListener(event, handleWindowEvents, { passive: true })
    );

    //remove handlers
    return () => {
      eventsList.forEach((event) =>
        window.removeEventListener(event, handleWindowEvents, { passive: true })
      );
    };
  }, [location.pathname]);

  const handleLogout = () => {
    console.log("Came to handle logout and current Time is", new Date());

    setIsDialogOpen(false);
    localStorage.clear();
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
    navigate("/", { replace: "true" });
  };

  // Todo
  const handleStay = () => {
    setIsDialogOpen(false);
    clearTimeout(sessionTimeOutRef.current);
    clearTimeout(sessionWarningTimeOutRef.current);
  };

  return (
    <>
      {children}
      {isDialogOpen && isLoggedIn && (
        <Dialog open={isDialogOpen} fullWidth>
          <DialogTitle>Session Timeout</DialogTitle>
          <DialogContent>
            <Typography>
              Your session is expired. Do you want to stay signed in?.
            </Typography>
          </DialogContent>
          <DialogActions>
            <CommonButton
              text="Sign out now"
              size="large"
              handleClick={handleLogout}
              hoverColor="#FF5A01"
            />
            <CommonButton
              text="Stay signed in"
              size="large"
              handleClick={handleStay}
              hoverColor="#FF5A01"
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const exemptedRoutes = ["/", "/login", "/forget-password"];
