import axios from "axios";
import { API_URL } from "../utils/config";
import Cookies from "js-cookie";

const instance = axios.create({
    baseURL: API_URL,
    headers: {},
});

instance.interceptors.request.use(
    async (config) => {
        const token = Cookies.get("accessToken", "");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
            return config;
        }
        else {
            const token = Cookies.get("refreshToken", "");
            if (token) {
                const { data } = await axios.post(API_URL + "users/token/refresh/", { refresh: token })
                const accessToken = data?.access
                config.headers["Authorization"] = "Bearer " + accessToken;
                Cookies.set('accessToken', accessToken, { expires: 1 / 288 })
                return config
            }
            return config
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    ({ response }) => {
        if (response && response.status === 401) {
            const token = Cookies.get("refreshToken", "");
            token && axios.post(API_URL + "users/token/refresh/", { refresh: token }).then((res) => { Cookies.set('accessToken', res?.data?.access, { expires: 1 / 288 }); })
        }
        return Promise.reject(response);
    }
);



export default instance;
