import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const CommonFileUpload = ({
  isFileUploadDialogOpen,
  setIsFileUploadDialogOpen,
  uploadedFiles,
  setUploadedFiles,
  onFilesUploaded,
}) => {

  const [formData, setFormData] = useState({
    name: "",
    document_details: "",
    file: null,
  });
  const [fileSizeError, setFileSizeError] = useState(false)

  const handleCloseDialog = useCallback(() => {
    setIsFileUploadDialogOpen(false);
    setFileSizeError(false);
    setFormData({
      document_details: "",
      file: null,
    });
  }, []);

  const handleSubmitUpload = useCallback(
    (e) => {
      e.preventDefault();
      const { file, document_details } = formData;
      if (file) {
        const uploadedFile = {
          name: file.name,
          details: document_details,
          file: file,
        };
        setUploadedFiles([uploadedFile]);
        setFormData({
          document_details: "",
          file: null,
        });
      }
    },
    [formData, onFilesUploaded, uploadedFiles, setUploadedFiles]
  );

  const handleInputChange = useCallback((e) => {
    const { name, value, files } = e.target;

    let newValue = value; // Default value

    if (name === "file" && files.length > 0) {
      const allowedFormats = ["application/pdf", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      const file = files[0];

      if (allowedFormats.includes(file.type)) {
        if (file?.size > 5 * 1024 * 1024) {
          setFileSizeError(true);
        } else {
          newValue = file;
          setFileSizeError(false);
        }
      } else {
        alert("Invalid file format. Please select a PDF, CSV, or XLSX file.");
        return;
      }
    }

    setFormData((data) => ({
      ...data,
      [name]: newValue,
    }));
  }, []);


  // const TextAreaAutoSizeFile = {
  //   height: 50,
  //   width: "calc(100% - 35px)",
  //   padding: 15,
  // };

  return (
    <>
      {/* <div style={{ display: "flex", flexDirection: "column" }}
        onClick={handleOpenDialog}
      >
        <span style={{ display: "flex", justifyContent: "center" }}>
          Files: <AttachFileIcon />
        </span>
        {uploadedFiles[0]?.name}
      </div> */}

      <Dialog open={isFileUploadDialogOpen} onClose={handleCloseDialog} fullWidth>
        <form onSubmit={handleSubmitUpload}>
          <DialogTitle style={{ background: "#FCFCFC" }}>Files</DialogTitle>
          <DialogContent>
            <Grid container>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>File Name</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadedFiles.map((file, index) => (
                      <TableRow key={index}>
                        <TableCell>{file.name}</TableCell>
                        <TableCell>{file.details}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* {uploadedFiles.length == 1 ? null : <Grid item xs={12}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  File Details
                </p>

                <TextareaAutosize
                  style={TextAreaAutoSizeFile}
                  name="document_details"
                  value={formData.document_details}
                  onChange={handleInputChange}
                  placeholder="Write details here"
                />
              </Grid>} */}

              {uploadedFiles.length === 1 ?
                <Grid item xs={12} marginTop={4}>
                  <Button color="inherit" variant="outlined" component="label">
                    Select File to Re Upload
                    <input
                      type="file"
                      hidden
                      name="file"
                      accept=".doc,.docx,.xlsx,.csv,.pdf"
                      onChange={handleInputChange}
                      required
                    />
                  </Button>
                  <span className="file-name">{formData.file?.name}</span>
                </Grid>
                :
                <Grid item xs={12} marginTop={4}>
                  <Button color="inherit" variant="outlined" component="label">
                    Select New File to Upload
                    <input
                      type="file"
                      hidden
                      name="file"
                      accept=".doc,.docx,.xlsx,.csv,.pdf"
                      onChange={handleInputChange}
                      required
                    />
                  </Button>
                  <span className="file-name">{formData.file?.name}</span>
                </Grid>
              }

              {fileSizeError && (
                <Typography color={"red"}>
                  Maximum allowed file size is 5MB
                </Typography>
              )}

            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "flex-end", padding: "15px 25px" }}
          >
            <Button onClick={handleCloseDialog} color="inherit" variant="outlined">
              Close
            </Button>
            {formData.file?.name && (
              <Button variant="contained" color="primary" type="submit">
                Upload
              </Button>)
            }
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CommonFileUpload;
