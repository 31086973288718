import React from 'react'

export default function CorrectionTypes() {
    const reportURL = process.env.REACT_APP_REPORT_CORRECTION_TYPES_URL
    return (
        <div style={{ width: "100%" }}>
            <iframe title="Correction Types" width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
        </div>
    )
}

