export const policyActions = [
  { label: "Write off", id: "write-off" },
  { label: "Refund", id: "refund" },
  { label: "Correction Types", id: "correction-types" },
  { label: "Corrective Transfer", id: "corrective-transfer" },
  { label: "Cross Allocation", id: "cross-allocation" },
  { label: "CFI", id: "cfi" },
  // { label: "Treasury Update", id: "tresury-update" },
  // { label: "MSD Update", id: "msd-update" },
];

export const systemIdList = [
  { value: "", label: "Select" },
  { value: "XFI", label: "XFI" },
  { value: "GXB", label: "GXB" },
  { value: "BOTH", label: "BOTH" },
];

export const allocationStatusList = [
  { value: "", label: "Select" },
  { value: "Allocated", label: "Allocated" },
  { value: "Query - Binder", label: "Query - Binder" },
  { value: "Query - Correction", label: "Query - Correction" },
  { value: "Query - Broker", label: "Query - Broker" },
  { value: "Query - UWR", label: "Query - UWR" },
  { value: "In Progress - CC", label: "In Progress - CC" },
  { value: "Query - F&A", label: "Query - F&A" },
  { value: "Query - GXB", label: "Query - GXB" },
  { value: "Query - XFI IT", label: "Query - XFI IT" },
  { value: "In Progress - GXB Transfer", label: "In Progress - GXB Transfer" },
  { value: "Query - Bind", label: "Query - Bind" },
  { value: "Open", label: "Open" },
];
