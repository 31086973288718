import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../api";

const initialState = {
    selectedCheckboxIds: [],
    data: {},
    isLoading: false,
    error: null,
}

export const paymentPayoutSummary = createAsyncThunk(
    'payment/payout_summary',
    async (ids) => {
        const res = await instance.post(`payment/payout_summary/`, { ids: ids })
        const data = await res.data
        return data
    }
)

export const payoutSummary = createSlice({
    name: "payoutSummary",
    initialState,
    reducers: {
        setIds: (state, action) => {
            const id = action?.payload;
            console.log("id--->", id);
            // Check if the ID exists in the array
            if (state?.selectedCheckboxIds?.includes(id)) {
                // Remove the ID from the array
                state.selectedCheckboxIds = state?.selectedCheckboxIds?.filter(existingId => existingId !== id);
            } else {
                // Add the ID to the array
                state.selectedCheckboxIds = [...state?.selectedCheckboxIds, id];
            }
            console.log("state.selectedCheckboxIds", state.selectedCheckboxIds);
            console.log("action", action);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(paymentPayoutSummary.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(paymentPayoutSummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.data = action.payload;
            state.selectedCheckboxIds = [];
        })
        builder.addCase(paymentPayoutSummary.rejected, (state, action) => {
            state.isLoading = false;
            console.log("action error", action);
            state.error = action;
        })
    },
});

export const { setIds } = payoutSummary.actions;

export default payoutSummary.reducer;
