import { styled } from "@mui/system";
import { TextValidator } from "react-material-ui-form-validator";
import { InputAdornment } from "@mui/material";
import MailIcon from "../../assets/Images/Union.svg";
import ForgotMail from "../../assets/icons/forgotMailIcon.svg";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
  position: "relative",
}));

export function EmailField({
  value,
  handleChange,
  name,
  width = "80%",
  placeholder = "Enter your email",
  isDisabled = false,
  iconType = "Mail",
}) {
  return (
    <TextField
      sx={{ mb: "12px", width: "100%" }}
      variant="outlined"
      placeholder={placeholder}
      size="small"
      onChange={handleChange}
      name={name || "email"}
      value={value || ""}
      validators={["required", "isEmail"]}
      errorMessages={["Required field", "Email is not valid"]}
      disabled={isDisabled}
      InputProps={{
        autoComplete: "email",
        startAdornment: (
          <InputAdornment position="start">
            <img alt="emailicon" src={getFieldIcon(iconType)} />
          </InputAdornment>
        ),
        style: { color: "black" },
      }}
      type="email"
      style={{
        backgroundColor: "white",
        width,
        color: "black",
      }}
    />
  );
}

const getFieldIcon = (iconType) => {
  let icon = "";
  switch (iconType.toUpperCase()) {
    case "MAIL":
      icon = MailIcon;
      break;
    case "USER":
      icon = ForgotMail;
      break;
    default:
  }
  return icon;
};
