import React from "react";
import PropTypes from "prop-types";
import WriteOff from "./WriteOff";
import CrossAllocation from "./CrossAllocation";
import Refund from "./Refund";
import TreasuryUpdate from "./TreasuryUpdate";
import Cfi from "./CFI";
import MsdUpdate from "./MSDUpdate";
import CorrectiveTransfer from "./CorrectiveTransfer";
import CorrectionTypes from "./CorrectionTypes";

const DialogueContainer = ({
  id,
  allocation,
  onClose,
  transaction,
  currencies,
}) => {
  switch (id) {
    case "write-off":
      return (
        <WriteOff
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "cross-allocation":
      return (
        <CrossAllocation
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "refund":
      return (
        <Refund
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "tresury-update":
      return (
        <TreasuryUpdate
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "cfi":
      return (
        <Cfi
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "msd-update":
      return (
        <MsdUpdate
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "correction-types":
      return (
        <CorrectionTypes
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    case "corrective-transfer":
      return (
        <CorrectiveTransfer
          allocation={allocation}
          handleClose={onClose}
          transaction={transaction}
          currencies={currencies}
        />
      );
    default:
      return null;
  }
};

DialogueContainer.propTypes = {
  id: PropTypes.string,
};

export default DialogueContainer;
