export const API_URL = process.env.REACT_APP_API_URL;

export const ROLES = ["CC Processor", "Finance", "Lead Role", "Manager", "System Admin", "Treasury"]

export const BANK_STATEMENT_ROW_DATA = {
    Accounting_Month: '',
    Allocation_Status: 'Open',
    Receiving_Bank_Account: '',
    Broker_Branch: '',
    Payment_Receive_Date: '',
    Payment_Reference: '',
    Payment_Currency_Code: '',
    Bank_Currency_Code: '',
    Bank_Exchange_Rate: '1.00',
    Bank_Exchange_Charges: '0.00',
    Bank_Charges: '0.00',
    Receivable_Amount: '0.00',
    TL_Fees: '0.00',
    Currency: '',
    File_Name: '',
    Created_By: '',
    Analyst_Name: '',
    Uploaded_By: '',
    Broker: [],
}

export const PREMIUM_TABLE_DATA = {
    region: "",
    entity_number: "",
    msd_entity_number: "",
    entity_name: "",
    bank_name: "",
    account_number: "",
    account_opening_date: "",
    account_type: "",
    currency: "",
    msd_acct_number: "",
    msd_acct_name: ""
}