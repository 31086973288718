import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Stack, Grid, Checkbox, FormLabel, TextField } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { payoutSummary } from "../../Services/api";
import { getPaginationCount } from "../CashAllocation/utils";
import { paymentPayoutSummary, setIds } from "../../redux/slice/PayoutSummarySlice";

const PayoutSummary = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const policyNo = new URLSearchParams(search).get("policyNo");
  const fileName = new URLSearchParams(search).get("fileName");
  const paymentType = new URLSearchParams(search).get("paymentType");

  const [paginationCount, setPaginationCount] = useState(0);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);

  const [searchFieldParams, setSearchFieldsParams] = useState({
    type_of_payment: paymentType,
    policy_no: policyNo,
    bdx_file_name: fileName,
    payment_id: "",
  });

  const toggle = useSelector((state) => state.toggleSideMenu);

  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const paymentTypes = ["Syndicate Payment", "Internal Broking", "MGA Commission", "Rebate"];

  const searchQueryParams = () => {
    let getsearchQueryParams = "";

    const { type_of_payment, policy_no, bdx_file_name, payment_id } = searchFieldParams;

    if (type_of_payment) {
      getsearchQueryParams = `&type_of_payment=${type_of_payment}`
    }

    if (policy_no) {
      getsearchQueryParams = `${getsearchQueryParams}&certificate_ref=${policy_no}`;
    }

    if (bdx_file_name) {
      getsearchQueryParams = `${getsearchQueryParams}&file_name=${bdx_file_name}`;
    }

    if (payment_id) {
      getsearchQueryParams = `${getsearchQueryParams}&payment_id=${payment_id}`
    }

    return getsearchQueryParams;
  }

  const loadSearchTxnData = async () => {
    try {
      let queryParams = `skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams()
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${payoutSummary}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ? response?.data?.data : [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
          page: 1,  // required for sending skip as 0
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      } else {
        // If we get information while performing route navigation
        if (!isClearPerformed) {
          if (policyNo) {
            queryParams = `${queryParams}&certificate_ref=${policyNo}`;
            setSearchFieldsParams({ ...searchFieldParams, policy_no: policyNo })
          }

          if (fileName) {
            queryParams = `${queryParams}&file_name=${fileName}`;
            setSearchFieldsParams({ ...searchFieldParams, bdx_file_name: fileName });
          }

          if (paymentType) {
            queryParams = `${queryParams}&type_of_payment=${paymentType}`;
            setSearchFieldsParams({ ...searchFieldParams, type_of_payment: paymentType });
          }
        }
      }

      const response = await instance.get(`${payoutSummary}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: 6,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: 6,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleSelectDropdown = (name, value, reason) => {
    if (reason === "clear") {
      loadData();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value ?? "",
    });
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);

    // clear fields
    setSearchFieldsParams({
      type_of_payment: "",
      policy_no: "",
      bdx_file_name: "",
      payment_id: "",
    });
  };

  const { selectedCheckboxIds, data, isLoading, error } = useSelector((state) => state?.PayoutSummary);

  const handleRadioChange = (id) => {
    dispatch(setIds(id));
  };

  const handleInitiatePaymentClick = () => {  
    dispatch(paymentPayoutSummary(selectedCheckboxIds));
  };

  const partnerPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={() => handleRadioChange(params?.row?.id)}
            // value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_final_net_premium_settlement_currency",
      headerName: "Sum of Final Net Premium Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      width: 160,
    },

  ];

  const syndicatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={() => handleRadioChange(params?.row?.id)}
            // value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    // {
    //   field: "sum_of_final_net_premium_settlement_currency",
    //   headerName: "Sum of Final Net Premium Settlement Currency",
    //   headerAlign: "center",
    //   width: 160,
    // },
    // {
    //   field: "sum_of_rebate",
    //   headerName: "Sum of Rebate",
    //   headerAlign: "center",
    //   width: 160,
    // },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      width: 160,
    },
  ];

  const commissionPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={() => handleRadioChange(params?.row?.id)}
            // value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_final_net_premium_settlement_currency",
      headerName: "Sum of Final Net Premium Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      width: 160,
    },
  ];

  const rebatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={() => handleRadioChange(params?.row?.id)}
            // value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    // {
    //   field: "sum_of_final_net_premium_settlement_currency",
    //   headerName: "Sum of Final Net Premium Settlement Currency",
    //   headerAlign: "center",
    //   width: 160,
    // },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      width: 160,
    },
    // {
    //   field: "sum_of_net_payment",
    //   headerName: "Sum of Net Payment",
    //   headerAlign: "center",
    //   width: 160,
    // },
  ];


  const getColumnsByPaymentType = () => {
    let treasuryPaymentTypes = [];
    switch (searchFieldParams.type_of_payment) {
      case "Syndicate Payment":
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
      case "Internal Broking":
        treasuryPaymentTypes = [...partnerPaymentColumns];
        break;
      case "MGA Commission":
        treasuryPaymentTypes = [...commissionPaymentColumns]
        break;
      case "Rebate":
        treasuryPaymentTypes = [...rebatePaymentColumns]
        break;
      default:
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
    }

    return treasuryPaymentTypes
  }

  const handleFieldsInputChange = (name, value, reason) => {
    if (!value) {
      handleClearSearchList()
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value?.trim(),
    });
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);


  return (
    <div style={Container}>
      <span style={{ color: "#FF5A01", fontSize: "20px", fontWeight: "400", marginTop: 5 }}>
        Payout Summary
      </span>

      {/* Search Fields */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "5px",
        }}
        container
        xs={12}
        gap={"10px"}
      >
        <Grid
          item
          xs={12}
          md={2}
          paddingRight={1}
        >
          <FormLabel>Payment Update Type</FormLabel>
          <CommonSelect
            placeholder="Select Payment Type"
            value={searchFieldParams?.type_of_payment}
            handleChange={(event, value, reason) =>
              handleSelectDropdown(
                "type_of_payment",
                value,
                reason,
                event
              )
            }
            options={paymentTypes?.map((option) => option) || []}
          />
        </Grid>

        {/* <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>Policy No</FormLabel>
          <TextField
            value={searchFieldParams?.policy_no}
            onChange={(e) =>
              handleFieldsInputChange(
                "policy_no",
                e.target.value
              )
            }
            size="small"
          />
        </Grid> */}

        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>BDX File Name</FormLabel>
          <TextField
            value={searchFieldParams?.bdx_file_name}
            onChange={(e) =>
              handleFieldsInputChange(
                "bdx_file_name",
                e.target.value
              )
            }
            size="small"
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          md={2}
          paddingRight={1}
        >
          <FormLabel>Payment ID</FormLabel>
          <CommonSelect
            placeholder="Select Payment Id"
            value={searchFieldParams?.payment_id}
            handleChange={(event, value, reason) =>
              handleSelectDropdown(
                "payment_id",
                value,
                reason,
                event
              )
            }
            options={paymentIds?.map((option) => option) || []}
          />
        </Grid> */}

        <Grid
          container
          xs={2.8}
          display={"contents"}
          flexDirection={"row"}
          gap={"6px"}
          mt={"20px"}
        >
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={() => loadSearchTxnData()}
              hoverColor="#EE3F00"
            // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
            // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Initiate Payment"
              className="bankStatementInitiateBtn"
              hoverColor="#EE3F00"
              disabled={(selectedCheckboxIds?.length <= 0)}
              handleClick={handleInitiatePaymentClick}
            />
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{
          height: "calc(100vh - 293px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          getRowId={(row) => row?.id}
          columns={getColumnsByPaymentType()}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(Math.floor(pageState.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
    </div>
  );
};

export default PayoutSummary;
