import React from 'react'

export default function AmountsByAgingBucket() {
  const reportURL = process.env.REACT_APP_REPORT_AMOUNT_BY_AGING_URL;
  return (
    <div>
      <iframe title="Aging Bucket" width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
    </div>
  )
}
