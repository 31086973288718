import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_URL from '../api'

const initialState = {
    bankStatmentTransactions: [],
    isLoading: false,
    error: null,
    isBanksListLoading: false,
    banksList: [],
    banksListError: null,
    isBrokerBranchesListLoading: false,
    brokerBranchesList: [],
    brokerBranchesListError: null,
    isCurrencyListLoading: false,
    currencyList: [],
    currencyListError: false,
    accountListLoading: false,
    accountsList: [],
    accountsListError: null,
}

export const getAccountsList = createAsyncThunk(
    'documents/bank_data/',
    async () => {
        const response = await API_URL.get("documents/bank_data/")
        return [...response?.data];
    }
)

export const getBanksList = createAsyncThunk(
    'documents/get_banks_list',
    async () => {
        const response = await API_URL.get("documents/get_banks_list/")
        return [...response?.data?.bank_names];
    }
)

export const getBrokerBranchesList = createAsyncThunk(
    'documents/get_broker_list',
    async () => {
        const response = await API_URL.get("documents/get_broker_list/")
        return [...response?.data?.Broker_Branch_Name];
    }
)

export const getCurrencyList = createAsyncThunk(
    'documents/currency_details',
    async () => {
        const response = await API_URL.get('documents/currency_details/')
        return [...response?.data];
    }
)

export const getBankStatmentTransactions = createAsyncThunk(
    'bankStatmentTransactions/List',
    async () => {
        const res = await API_URL.get("bankmanagement/bank_transaction/")
        const data = await res.data
        return data
    }
)

export const bankStatmentTransactionsSlice = createSlice({
    name: 'bankStatmentTransactionsList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAccountsList.pending, (state) => {
            state.accountListLoading = true
        })
        builder.addCase(getAccountsList.fulfilled, (state, action) => {
            state.accountListLoading = false
            state.accountsList = action.payload
            state.accountsListError = null
        })
        builder.addCase(getAccountsList.rejected, (state, action) => {
            state.accountListLoading = false
            state.accountsListError = action.error.message
        })
        builder.addCase(getBanksList.pending, (state) => {
            state.isBanksListLoading = true
        })
        builder.addCase(getBanksList.fulfilled, (state, action) => {
            state.isBanksListLoading = false
            state.banksList = action.payload
            state.banksListError = null
        })
        builder.addCase(getBanksList.rejected, (state, action) => {
            state.isBanksListLoading = false
            state.banksListError = action.error.message
        })
        builder.addCase(getBrokerBranchesList.pending, (state) => {
            state.isBrokerBranchesListLoading = true
        })
        builder.addCase(getBrokerBranchesList.fulfilled, (state, action) => {
            state.isBrokerBranchesListLoading = false
            state.brokerBranchesList = action.payload
            state.brokerBranchesListError = null
        })
        builder.addCase(getBrokerBranchesList.rejected, (state, action) => {
            state.isBrokerBranchesListLoading = false
            state.brokerBranchesListError = action.error.message
        })
        builder.addCase(getCurrencyList.pending, (state) => {
            state.isCurrencyListLoading = true
        })
        builder.addCase(getCurrencyList.fulfilled, (state, action) => {
            state.isCurrencyListLoading = false
            state.currencyList = action.payload
            state.currencyListError = null
        })
        builder.addCase(getCurrencyList.rejected, (state, action) => {
            state.isCurrencyListLoading = false
            state.currencyListError = action.error.message
        })
        builder.addCase(getBankStatmentTransactions.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getBankStatmentTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.bankStatmentTransactions = action.payload
            state.error = null
        })
        builder.addCase(getBankStatmentTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default bankStatmentTransactionsSlice.reducer