import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Box, CircularProgress, Typography } from "@mui/material";
import { PasswordInput, EmailField } from "../../Components/common";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slice/loginSlice";
import styled from "@emotion/styled";
import { FormControlLabel, Checkbox } from "@mui/material";
import ROUTES from "../../utils/routes";
import Image from "../../assets/Images/Image.png";
import Cookies from "js-cookie";
import { CommonButton } from "../../Components/common/commonButton";
import "../../assets/scss/login.scss";

const Login = () => {
  const { isLoggedIn, loginError, isLoading } = useSelector(
    (state) => state?.login
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const FlexBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  const isSignInDisabled = !formData.email || !formData.password || isLoading;

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    const token = Cookies.get("refreshToken", "");
    if (isLoggedIn)
      console.log("user logged in and current Time is", new Date());
    token && isLoggedIn && navigate(ROUTES.MUSU);
  }, [isLoggedIn]);

  return (
    <div className="loginContainer">
      <img src={Image} alt="image" className="loginImageStyle" />
      <div className="signinContainer">
        <div>
          <p className="letsGetStartText">Let's Get Started</p>
          <p className="signinContinueText">Sign in to continue to Mosaic</p>
          <div>
            <ValidatorForm
              onSubmit={() => dispatch(loginUser(formData))}
              onError={() => null}
              className="formValidator"
            >
              <EmailField
                value={formData.email}
                placeholder="Enter your username"
                handleChange={handleChange}
              />

              <PasswordInput
                value={formData.password}
                handleChange={handleChange}
              />

              <FlexBox className="rememberForgotPasswordContainer">
                <FormControlLabel
                  name="agreement"
                  className="dark-color formControlLableStyle"
                  control={<Checkbox size="small" />}
                  label="Remember me"
                />
                <CommonButton
                  size="large"
                  className={"forgotButton"}
                  text="Forgot Password?"
                  handleClick={() => navigate(ROUTES.FORGETPASSWORD)}
                />
              </FlexBox>

              <FlexBox className="signInFlexBox">
                <CommonButton
                  size="large"
                  className={"signInButton"}
                  text="SIGN IN"
                  disabled={isSignInDisabled}
                  type="submit"
                />
              </FlexBox>
              {isLoading && (
                <CircularProgress
                  style={{ position: "absolute", left: "210px", top: "200px", color: "rgb(255, 90, 1)" }}
                />
              )}
              {loginError && !isLoading && (
                <Typography variant="h5" style={{ color: "red" }}>
                  "Invalid credentials."
                </Typography>
              )}
            </ValidatorForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
