import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { eachDayOfInterval, getISOWeek } from "date-fns";
import _ from "lodash";
// import format from "date-fns/format";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

function DateWiseAllocationChart({ data }) {
  const [xData, setXData] = useState([]);
  const [componentData, setComponentData] = useState(null);
  const [yReceivableData, setYReceivableData] = useState([]);
  const [yAllocationData, setYAllocationData] = useState([]);
  const [yUnallocationData, setYUnallocationData] = useState([]);

  const [displayType, setDisplayType] = useState("daily"); //daily, weekly, monthly, yearly

  const [showReceivable, setShowReceivable] = useState(true);
  const [showAllocation, setShowAllocation] = useState(true);
  const [showUnallocation, setShowUnallocation] = useState(true);

  useEffect(() => {
    setComponentData(data);
  }, [data]);

  useEffect(() => {
    let dates = [];
    let dates_receivable = [];
    let dates_allocated = [];
    let dates_unallocated = [];
    let dataInner = componentData;
    if (!componentData) {
      return;
    }

    // console.log(
    //   "orignal data",
    //   componentData,
    //   "datewisekwdmlwkdm receivable",
    //   componentData?.datewise_receivable_amount,
    //   "date wise kwe klwe  allocate",
    //   componentData.datewise_allocated_amount
    // );

    for (let obj of dataInner?.datewise_receivable_amount ?? []) {
      if (!dates.includes(obj?.Payment_Receive_Date)) {
        dates.push(dayjs(obj?.Payment_Receive_Date).format("YYYY-MM-DD"));
      }
      dates_receivable.push(
        dayjs(obj?.Payment_Receive_Date).format("YYYY-MM-DD")
      );
    }
    for (let obj of dataInner?.datewise_allocated_amount ?? []) {
      if (!dates.includes(obj?.allocation_date)) {
        dates.push(obj?.allocation_date);
      }
      dates_allocated.push(obj?.allocation_date);
    }
    for (let obj of dataInner?.datewise_unallocated_amount ?? []) {
      if (!dates.includes(obj?.date)) {
        dates.push(dayjs(obj?.date).format("YYYY-MM-DD"));
      }
      dates_unallocated.push(dayjs(obj?.date).format("YYYY-MM-DD"));
    }
    dates = dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    let date_range = eachDayOfInterval({
      start: new Date(dates[0]),
      end: new Date(dates[dates.length - 1]),
    });
    // ok till here
    // date_range=date_range.sort((a, b)=>Date(b)-Date(a))
    date_range = date_range.reverse();
    date_range = date_range.map((date) => dayjs(date).format("YYYY-MM-DD"));

    let tempData = {
      datewise_receivable_amount: [],
      datewise_allocated_amount: [],
      datewise_unallocated_amount: [],
    };
    date_range.forEach((date) => {
      if (!dates_receivable.includes(date)) {
        tempData.datewise_receivable_amount.push({
          Payment_Receive_Date: date,
          total_receivable_amt: 0,
        });
      } else {
        tempData.datewise_receivable_amount.push(
          dataInner?.datewise_receivable_amount.filter(
            (obj) =>
              dayjs(obj?.Payment_Receive_Date).format("YYYY-MM-DD") === date
          )[0]
        );
      }

      if (!dates_allocated.includes(date)) {
        tempData?.datewise_allocated_amount.push({
          allocation_date: date,
          total_allocated_amt: 0,
        });
      } else {
        tempData.datewise_allocated_amount.push(
          dataInner?.datewise_allocated_amount.filter(
            (obj) => obj.allocation_date === date
          )[0]
        );
      }

      if (!dates_unallocated.includes(date)) {
        tempData.datewise_unallocated_amount.push({
          date: date,
          net_amount: 0,
        });
      } else {
        tempData.datewise_unallocated_amount.push(
          dataInner?.datewise_unallocated_amount.filter(
            (obj) => dayjs(obj.date).format("YYYY-MM-DD") === date
          )[0]
        );
      }
    });

    dataInner = tempData;

    if (displayType === "daily") {
      setXData(date_range.map((date) => dayjs(date).format("DD-MMM-YYYY")));
      setYReceivableData(
        dataInner.datewise_receivable_amount.map(
          (obj) => obj?.total_receivable_amt
        )
      );
      setYAllocationData(
        dataInner.datewise_allocated_amount.map(
          (obj) => obj?.total_allocated_amt
        )
      );
      setYUnallocationData(
        dataInner.datewise_unallocated_amount.map((obj) => obj?.net_amount)
      );
    } else if (displayType === "weekly") {
      let dataWeekly = {};

      const getWeeklyLabel = (date) => {
        const week = getISOWeek(date);
        const year = dayjs(date).format("YYYY");
        return `Week ${week} ${year}`;
      };

      dataWeekly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            weekYear: getWeeklyLabel(obj.Payment_Receive_Date),
          };
          return obj;
        });
      dataWeekly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = {
            ...obj,
            weekYear: getWeeklyLabel(obj.allocation_date),
          };
          return obj;
        });
      dataWeekly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = {
            ...obj,
            weekYear: getWeeklyLabel(obj.date),
          };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataWeekly.datewise_receivable_amount,
        "weekYear"
      );
      let groupedAllocatedObj = _.groupBy(
        dataWeekly.datewise_allocated_amount,
        "weekYear"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataWeekly.datewise_unallocated_amount,
        "weekYear"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    } else if (displayType === "Bi-weekly") {
      let dataBiWeekly = {};

      const getBiWeeklyLabel = (date) => {
        const week = Math.round(getISOWeek(date) / 2);
        const year = dayjs(date).format("YYYY");
        return `BiWeekly ${week} ${year}`;
      };

      dataBiWeekly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            weekBiYear: getBiWeeklyLabel(obj.Payment_Receive_Date),
          };
          return obj;
        });
      dataBiWeekly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = {
            ...obj,
            weekBiYear: getBiWeeklyLabel(obj.allocation_date),
          };
          return obj;
        });
      dataBiWeekly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = {
            ...obj,
            weekBiYear: getBiWeeklyLabel(obj.date),
          };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataBiWeekly.datewise_receivable_amount,
        "weekBiYear"
      );
      let groupedAllocatedObj = _.groupBy(
        dataBiWeekly.datewise_allocated_amount,
        "weekBiYear"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataBiWeekly.datewise_unallocated_amount,
        "weekBiYear"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    } else if (displayType === "monthly") {
      let dataMonthly = {};
      dataMonthly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            monthYear: dayjs(obj.Payment_Receive_Date).format("MMM-YY"),
          };
          return obj;
        });
      dataMonthly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = {
            ...obj,
            monthYear: dayjs(obj.allocation_date).format("MMM-YY"),
          };
          return obj;
        });
      dataMonthly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = {
            ...obj,
            monthYear: dayjs(obj.date).format("MMM-YY"),
          };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataMonthly.datewise_receivable_amount,
        "monthYear"
      );
      let groupedAllocatedObj = _.groupBy(
        dataMonthly.datewise_allocated_amount,
        "monthYear"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataMonthly.datewise_unallocated_amount,
        "monthYear"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    } else if (displayType === "Quarterly") {
      let dataQuarterly = {};

      const getQuarterlyLabel = (date) => {
        const quarter = Math.ceil((dayjs(date).month() + 1) / 3);
        const year = dayjs(date).format("YYYY");
        return `Q ${quarter} ${year}`;
      };

      dataQuarterly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            quarterYear: getQuarterlyLabel(obj.Payment_Receive_Date),
          };
          return obj;
        });
      dataQuarterly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = {
            ...obj,
            quarterYear: getQuarterlyLabel(obj.allocation_date),
          };
          return obj;
        });
      dataQuarterly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = {
            ...obj,
            quarterYear: getQuarterlyLabel(obj.date),
          };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataQuarterly.datewise_receivable_amount,
        "quarterYear"
      );
      let groupedAllocatedObj = _.groupBy(
        dataQuarterly.datewise_allocated_amount,
        "quarterYear"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataQuarterly.datewise_unallocated_amount,
        "quarterYear"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    } else if (displayType === "HalfYearly") {
      let dataHalfYearly = {};

      const getHalfYearly = (date) => {
        const halfQuarter = Math.ceil((dayjs(date).month() + 5) / 6);
        const year = dayjs(date).format("YYYY");
        return `${halfQuarter} ${year}`;
      };

      dataHalfYearly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            halfYear: getHalfYearly(obj.Payment_Receive_Date),
          };
          return obj;
        });
      dataHalfYearly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = {
            ...obj,
            halfYear: getHalfYearly(obj.allocation_date),
          };
          return obj;
        });
      dataHalfYearly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = {
            ...obj,
            halfYear: getHalfYearly(obj.date),
          };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataHalfYearly.datewise_receivable_amount,
        "halfYear"
      );
      let groupedAllocatedObj = _.groupBy(
        dataHalfYearly.datewise_allocated_amount,
        "halfYear"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataHalfYearly.datewise_unallocated_amount,
        "halfYear"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    } else if (displayType === "yearly") {
      let dataYearly = {};
      dataYearly.datewise_receivable_amount =
        dataInner?.datewise_receivable_amount.map((obj) => {
          obj = {
            ...obj,
            year: dayjs(obj.Payment_Receive_Date).format("YYYY"),
          };
          return obj;
        });
      dataYearly.datewise_allocated_amount =
        dataInner?.datewise_allocated_amount.map((obj) => {
          obj = { ...obj, year: dayjs(obj.allocation_date).format("YYYY") };
          return obj;
        });
      dataYearly.datewise_unallocated_amount =
        dataInner?.datewise_unallocated_amount.map((obj) => {
          obj = { ...obj, year: dayjs(obj.date).format("YYYY") };
          return obj;
        });
      let groupedReceivableObj = _.groupBy(
        dataYearly.datewise_receivable_amount,
        "year"
      );
      let groupedAllocatedObj = _.groupBy(
        dataYearly.datewise_allocated_amount,
        "year"
      );
      let groupedUnallocatedObj = _.groupBy(
        dataYearly.datewise_unallocated_amount,
        "year"
      );
      let summedReceivableObj = {};
      let summedAllocatedObj = {};
      let summedUnallocatedObj = {};
      for (let key of Object.keys(groupedReceivableObj)) {
        let sum = 0;
        let array = groupedReceivableObj[key];
        for (let obj of array) {
          sum = sum + obj.total_receivable_amt;
        }
        summedReceivableObj[key] = sum;
      }
      for (let key of Object.keys(groupedAllocatedObj)) {
        let sum = 0;
        let array = groupedAllocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.total_allocated_amt;
        }
        summedAllocatedObj[key] = sum;
      }
      for (let key of Object.keys(groupedUnallocatedObj)) {
        let sum = 0;
        let array = groupedUnallocatedObj[key];
        for (let obj of array) {
          sum = sum + obj.net_amount;
        }
        summedUnallocatedObj[key] = sum;
      }
      setXData(Object.keys(summedAllocatedObj));
      setYReceivableData(Object.values(summedReceivableObj));
      setYAllocationData(Object.values(summedAllocatedObj));
      setYUnallocationData(Object.values(summedUnallocatedObj));
    }
  }, [componentData, displayType]);

  const toggleReceivableVisibility = () => {
    setShowReceivable(!showReceivable);
  };

  const toggleAllocationVisibility = () => {
    setShowAllocation(!showAllocation);
  };

  const toggleUnallocationVisibility = () => {
    setShowUnallocation(!showUnallocation);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
    },
  };
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px",
        marginLeft: "10px",
        marginBottom: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', marginBottom: 5, paddingRight: 5 }}>
        <div
          style={{
            backgroundColor: "#2560F9",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            color: "#FFFFFF",
            lineHeight: "10px",
            borderRadius: 5,
            fontSize: 12,
            padding: "10px",
            gap: "5px",
            height: "15px",
            cursor: "pointer",
          }}
        >
          <div
            className={displayType === "daily" ? "selected" : "not-selected"}
            onClick={() => setDisplayType("daily")}
          >
            Daily
          </div>
          <div
            className={displayType === "weekly" ? "selected" : "not-selected"}
            onClick={() => setDisplayType("weekly")}
          >
            Weekly
          </div>
          <div
            className={
              displayType === "Bi-weekly" ? "selected" : "not-selected"
            }
            onClick={() => setDisplayType("Bi-weekly")}
          >
            BiWeekly
          </div>
          <div
            className={displayType === "monthly" ? "selected" : "not-selected"}
            onClick={() => setDisplayType("monthly")}
          >
            Monthly
          </div>
          <div
            className={
              displayType === "Quarterly" ? "selected" : "not-selected"
            }
            onClick={() => setDisplayType("Quarterly")}
          >
            Quarterly
          </div>
          <div
            className={
              displayType === "HalfYearly" ? "selected" : "not-selected"
            }
            onClick={() => setDisplayType("HalfYearly")}
          >
            HalfYearly
          </div>
          <div
            className={displayType === "yearly" ? "selected" : "not-selected"}
            onClick={() => setDisplayType("yearly")}
          >
            Yearly
          </div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            fontSize: "12px",
            marginRight: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginLeft: "5px",
              marginRight: "5px",
              cursor:"pointer",
              textDecoration: showReceivable ? "none" : "line-through",
            }}
            onClick={toggleReceivableVisibility}
          >
            <div
              className="legend"
              style={{ backgroundColor: "#2560F9", borderRadius: 5 }}
            />
            <div style={{ marginLeft: "2px", fontSize: 14 }}>
              Receivable Amt
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "5px",
              marginRight: "5px",
              cursor:"pointer",
              textDecoration: showAllocation ? "none" : "line-through",
            }}
            onClick={toggleAllocationVisibility}
          >
            <div
              className="legend"
              style={{ backgroundColor: "#008000", borderRadius: 5 }}
            />
            <div style={{ marginLeft: "2px", fontSize: 14 }}>Allocated Amt</div>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "5px",
              marginRight: "5px",
              textDecoration: showUnallocation ? "none" : "line-through",
              cursor:"pointer",
            }}
            onClick={toggleUnallocationVisibility}
          >
            <div
              className="legend"
              style={{ backgroundColor: "#f1c40f", borderRadius: 5 }}
            />
            <div style={{ marginLeft: "2px", fontSize: 14 }}>
              UnAllocated Amt
            </div>
          </div>
        </div>
      </div>
      <Line
        options={options}
        data={{
          labels: xData,
          datasets: [
            {
              label: "Receivable Amt",
              data: yReceivableData,
              borderColor: "#2560F9",
              fill: false,
              hidden: !showReceivable,
            },
            {
              label: "Allocated Amt",
              data: yAllocationData,
              borderColor: "#008000",
              fill: false,
              hidden: !showAllocation,
            },
            {
              label: "UnAllocated Amt",
              data: yUnallocationData,
              borderColor: "#f1c40f",
              fill: false,
              hidden: !showUnallocation,
            },
          ],
        }}
      />
    </div>
  );
}
export default DateWiseAllocationChart;
