import { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import dayjs from "dayjs";
import { CalendarIcon } from "../../assets/icons";
import useOnClickOutside from "./useOnClickOutside";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../assets/scss/dateRangePicker.scss";

const CustomDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxDate,
  handleSubmit,
  displayActionBar = false,
  defaultActionBarItem = "Daily",
  setSelectedActionBarItem,
}) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: startDate ? new Date(startDate) : new Date(),
    endDate: endDate ? new Date(endDate) : new Date(),
    key: "selection",
  });

  const [currentSelectedActionBarItem, setCurrentSelectedActionBarItem] =
    useState(defaultActionBarItem);

  const getDate = (value, format = "DD/MM/YYYY") => dayjs(value).format(format);
  const [input, setInput] = useState(
    startDate && endDate ? getDate(startDate) + " - " + getDate(endDate) : ""
  );

  useEffect(() => {
    setSelectionRange({
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: "selection",
    });
    setInput(
      startDate && endDate ? getDate(startDate) + " - " + getDate(endDate) : ""
    );
  }, [startDate, endDate]);

  const dateRangeRef = useRef(null);
  const calenderRef = useRef(null);

  const [open, setOpen] = useState(false);

  useOnClickOutside(dateRangeRef, () => setOpen(false));

  const [error, setError] = useState(false);

  const getStartEndDates = (rangeString) => {
    const convert = (value) => {
      const data = value.split("/");
      return [data[1], data[0], data[2]].join("/");
    };
    const data = rangeString.split(" - ");

    const startDate = new Date(convert(data[0]));
    const endDate = new Date(convert(data[1]));
    return { startDate, endDate };
  };

  const isValidDate = (rangeString) => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    };
    const data = rangeString.split(" - ");
    const { startDate, endDate } = getStartEndDates(rangeString);
    return (
      data[0] === formatDate(startDate) &&
      data[1] === formatDate(endDate) &&
      endDate - startDate >= 0 &&
      (maxDate ? maxDate - endDate >= 0 : true)
    );
  };

  const isValidDateRange = (rangeString) => {
    const regex =
      /^(3[01]|0[1-9]|[12][0-9])\/(0[1-9]|1[0-2])\/\d{4}\s*-\s*(3[01]|0[1-9]|[12][0-9])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(rangeString);
  };

  const isValidValue = (value) => {
    const regex = /^[0-9\s\-/]+$/;
    return regex.test(value);
  };

  const handleChange = (e) => {
    const value = e.target.value
      ? isValidValue(e.target.value)
        ? e.target.value
        : input
      : e.target.value;
    if (!value) {
      setStartDate("");
      setEndDate("");
    }
    setInput(value);
    if (isValidDateRange(value) && isValidDate(value)) {
      setError(false);
      const { startDate, endDate } = getStartEndDates(value);
      setStartDate(getDate(startDate, "MM-DD-YYYY"));
      setEndDate(getDate(endDate, "MM-DD-YYYY"));
      setSelectionRange({
        ...selectionRange,
        startDate,
        endDate,
      });
    } else if (!value) {
      setError(false);
      setSelectionRange({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      });
    } else {
      setError(true);
    }
  };

  const handleSelect = (ranges) => {
    if (ranges.selection) {
      setSelectionRange({
        ...selectionRange,
        ...ranges.selection,
      });
      setStartDate(getDate(ranges.selection.startDate, "MM-DD-YYYY"));
      setEndDate(getDate(ranges.selection.endDate, "MM-DD-YYYY"));
      setInput(
        getDate(ranges.selection.startDate) +
          " - " +
          getDate(ranges.selection.endDate)
      );
    } else {
      setSelectionRange({
        startDate: null,
        endDate: null,
        key: "selection",
      });
    }
    setError(false);
  };

  const handleDateViewChange = (value) => {
    const classList = [...calenderRef.current.classList];
    maxDate &&
      !classList?.includes("disableNext") &&
      dayjs(maxDate).format("MM/YYYY") === dayjs(value).format("MM/YYYY") &&
      calenderRef.current.classList.add("disableNext");
    maxDate &&
      classList?.includes("disableNext") &&
      dayjs(maxDate).format("MM/YYYY") !== dayjs(value).format("MM/YYYY") &&
      calenderRef.current.classList.remove("disableNext");
  };

  const dateOptions = [
    "Daily",
    "Weekly",
    "Bi Weekly",
    "Monthly",
    "Quarterly",
    "Half Yearly",
    "Yearly",
  ];

  const handleActionBarItemClick = (event) => {
    const { textContent } = event.target;
    setCurrentSelectedActionBarItem(textContent);

    let PrevDays = 1;

    switch (textContent) {
      case "Daily":
        PrevDays = 1;
        break;
      case "Weekly":
        PrevDays = 7;
        break;
      case "Bi Weekly":
        PrevDays = 14;
        break;
      case "Monthly":
        PrevDays = 30;
        break;
      case "Quarterly":
        PrevDays = 90;
        break;
      case "Half Yearly":
        PrevDays = 180;
        break;
      case "Yearly":
        PrevDays = 365;
        break;
      default:
        break;
    }

    // start date
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - PrevDays);
    // end date
    const endDate = new Date();

    setStartDate(getDate(startDate, "MM-DD-YYYY"));
    setEndDate(getDate(endDate, "MM-DD-YYYY"));
    setSelectionRange({
      ...selectionRange,
      startDate,
      endDate,
    });
  };

  return (
    <div
      className="customDateRangeContainer"
      style={{ display: "flex" }}
      // key={startDate + endDate}
      ref={dateRangeRef}
    >
      <div className="customDateRangeInputContainer">
        <input
          type="text"
          className={`customDateRangeInput ${error ? "inputError" : ""}`}
          value={input}
          placeholder="DD/MM/YYYY - DD/MM/YYYY"
          onClick={() => setOpen(false)}
          onChange={handleChange}
        />
        <CalendarIcon
          style={{ width: 20, height: 20 }}
          className="customDateRangeIcon svgIcon"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
            handleDateViewChange(selectionRange?.startDate);
          }}
        />
      </div>

      <div
        className={`customDateRangeCard`}
        style={{ display: open ? "block" : "none" }}
        ref={calenderRef}
      >
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <DateRange
            className="customDateRange"
            ranges={[selectionRange]}
            onChange={handleSelect}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            onShownDateChange={handleDateViewChange}
            showDateDisplay={false}
            maxDate={maxDate}
          />

          {displayActionBar && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "baseline",
                marginTop: 5,
              }}
            >
              {dateOptions?.map((option) => {
                return (
                  <button
                    className="options-btn"
                    onClick={(e) => handleActionBarItemClick(e)}
                    style={{
                      textDecoration:
                        option === currentSelectedActionBarItem
                          ? "underline"
                          : "none",
                      opacity:
                        option === currentSelectedActionBarItem ? 1 : 0.6,
                    }}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          )}
        </div>

        <button
          className="transparent-btn"
          onClick={() => {
            if (displayActionBar) {
              setSelectedActionBarItem(currentSelectedActionBarItem);
              handleSubmit();
            }
            setOpen(!open);
          }}
        >
          OK
        </button>

        <button
          className="transparent-btn"
          onClick={() => {
            handleChange({ target: { value: "" } });
            setStartDate("");
            setEndDate("");
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default CustomDateRangePicker;
