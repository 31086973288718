import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";
import dayjs from "dayjs";

export const CreditDebitNewBarChart = ({ data, selectedActionBarItem }) => {
    const [formattedData, setformattedData] = useState([]);
    const [banks, setBanks] = useState([])

    const processData = (data) => {
        const banks = _.uniq(_.map(data, 'Receiving_Bank_Account'))
        const dates = _.groupBy(data, 'Payment_Receive_Date')
        const dataToFormat = _.values(dates);
        const finalData = dataToFormat?.map(items => {
            let temp_data = {}
            items?.forEach(({ Payment_Receive_Date, Receiving_Bank_Account, bank_name, credit_total_amount, debit_total_amount }) => {
                temp_data = { ...temp_data, year: dayjs(Payment_Receive_Date).format("DD/MM/YYYY"), [`${Receiving_Bank_Account}-Credit`]: credit_total_amount, [`${Receiving_Bank_Account}-Debit`]: debit_total_amount, [`${Receiving_Bank_Account}-BankName`]: bank_name }
            })
            return temp_data

        })
        setBanks(banks)
        setformattedData(finalData)
    }

    useEffect(() => {
        processData(data)
    }, [data])

    function getMonthName(monthIndex) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[monthIndex];
    }

    function getWeekNumber(date, value) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

        if (value === "Weekly") {
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        } else {
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 14);
        }
    }

    const customizeDataForChart = () => {
        data.sort((a, b) => new Date(a.Payment_Receive_Date) - new Date(b.Payment_Receive_Date));

        if (selectedActionBarItem === "Yearly") {
            const yearly = {};

            data?.forEach(current => {
                const year = new Date(current?.Payment_Receive_Date).getFullYear();
                if (!yearly[year]) {
                    yearly[year] = {
                        year: year.toString(),
                        credit_total_amount: 0,
                        debit_total_amount: 0,
                    };
                }
                yearly[year].credit_total_amount += current?.credit_total_amount;
                yearly[year].debit_total_amount += current?.debit_total_amount;
            });

            setformattedData(Object.values(yearly))

        } else if (selectedActionBarItem === "Monthly" || "Querterly" || "Half Yearly") {
            const monthlyData = {};

            data?.forEach(current => {
                const date = new Date(current?.Payment_Receive_Date);
                const year = date.getFullYear();
                const month = date.getMonth();

                const monthKey = `${year}-${(month + 1).toString().padStart(2, '0')}`;
                if (!monthlyData[monthKey]) {
                    monthlyData[monthKey] = {
                        year: getMonthName(month),
                        credit_total_amount: 0,
                        debit_total_amount: 0
                    };
                }

                monthlyData[monthKey].credit_total_amount += current?.credit_total_amount;
                monthlyData[monthKey].debit_total_amount += current?.debit_total_amount;
            });

            setformattedData(Object.values(monthlyData))

        } else if (selectedActionBarItem === "Weekly") {
            const weeklyData = {};

            data.forEach(current => {
                const date = new Date(current.Payment_Receive_Date);
                const year = date.getFullYear();
                const week = getWeekNumber(date, selectedActionBarItem);

                const weekKey = `${year}-Week${week.toString().padStart(2, '0')}`;

                if (!weeklyData[weekKey]) {
                    weeklyData[weekKey] = {
                        year: weekKey,
                        credit_total_amount: 0,
                        debit_total_amount: 0
                    };
                }

                weeklyData[weekKey].credit_total_amount += current.credit_total_amount;
                weeklyData[weekKey].debit_total_amount += current.debit_total_amount;
            });

            setformattedData(Object.values(weeklyData))
        } else if (selectedActionBarItem === "Bi Weekly") {
            const weeklyData = {};

            data.forEach(current => {
                const date = new Date(current.Payment_Receive_Date);
                const year = date.getFullYear();
                const week = getWeekNumber(date, selectedActionBarItem);

                const weekKey = `${year}-Bi-Week${week.toString().padStart(2, '0')}`;

                if (!weeklyData[weekKey]) {
                    weeklyData[weekKey] = {
                        year: weekKey,
                        credit_total_amount: 0,
                        debit_total_amount: 0
                    };
                }

                weeklyData[weekKey].credit_total_amount += current.credit_total_amount;
                weeklyData[weekKey].debit_total_amount += current.debit_total_amount;
            });

            setformattedData(Object.values(weeklyData))
        } else if (selectedActionBarItem === "Daily") {
            const dailyData = {};
            data.forEach(current => {
                const date = new Date(current.Payment_Receive_Date);
                const day = date.toISOString().split('T')[0];
                console.log(day);
                if (!dailyData[day]) {
                    dailyData[day] = {
                        year: day,
                        credit_total_amount: 0,
                        debit_total_amount: 0
                    };
                }

                dailyData[day].credit_total_amount += current.credit_total_amount;
                dailyData[day].debit_total_amount += current.debit_total_amount;
            });

            return Object.values(dailyData)
        }
    }
    const displayCreditDebitChart = (root) => {
        root._logo.dispose();

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        // let legend = chart.children.push(am5.Legend.new(root, {
        //     centerX: am5.p50,
        //     x: am5.p50
        // }));

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true
        });

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 14, // Adjust this value to your desired font size
        });

        xRenderer.grid.template.setAll({
            location: 1
        });

        xAxis.data.setAll(formattedData);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            }),
            numberFormat: "#.##a", // Format numbers to millions with 2 decimal places
        }));

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(fieldName, stacked, Bank_Account, type) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                stacked: stacked,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "year"
            }));

            series.columns.template.setAll({
                width: 35,
                tooltipY: am5.percent(10)

            });

            series.columns.template.adapters.add("tooltipText", function (_, target) {
                return `Bank Name: ${target?._dataItem?.dataContext[`${Bank_Account}-BankName`]}\nBank Account: ${Bank_Account}\n${type} Total:{valueY}`
            });

            let tooltip = am5.Tooltip.new(root, {
                getFillFromSprite: true,
                getStrokeFromSprite: true,
                autoTextColor: true,
                getLabelFillFromSprite: true,
                pointerOrientation: "left"
            });

            tooltip.get("background").setAll({
                fill: am5.color(0xffffff),
                fillOpacity: 0.8
            });

            series.set("tooltip", tooltip);
            series.data.setAll(formattedData);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.percent(50),
                        centerX: am5.percent(50),
                        populateText: true
                    })
                });
            });

            // legend.data.push(series);
        }

        banks?.forEach((bank, index) => makeSeries(`${bank}-Credit`, !!index, bank, "Credit"))
        banks?.forEach((bank, index) => makeSeries(`${bank}-Debit`, !!index, bank, "Debit"))

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
    };

    useLayoutEffect(() => {
        const root = am5.Root.new("BarChartDiv");
        if (formattedData) {
            displayCreditDebitChart(root, formattedData);
        } else {
            customizeDataForChart();
        }

        return () => {
            root.dispose();
        };
    });

    return (
        <div id="BarChartDiv" style={{ height: 480 }} />
    );
};
