import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  Grid,
  IconButton,
  Input,
  TextField,
  Card,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { CommonSelect } from "../common";
import React, { useEffect, useState } from "react";
import { CommonButton } from "../common/commonButton";
import EditPriceDialog from "./EditPriceDialog";
import instance from "../../redux/api";
import {
  BankTransaction,
  getBankTxnWorkflowStatus,
  getBrokerBranchDetailsByBrokerBranchName,
} from "../../Services/api";
import { numberFormatter } from "../../utils/generalFunctions";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const editBankTransactionUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
};

const dialogStyle = {
  maxWidth: "90vw",
  width: "90vw",
  margin: "auto",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

const EditPageContainer = {
  marginTop: 5,
  cursor: "default",
};

const EditpageContainerItems = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "baseline",
  padding: "15px",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};

const EditPageContainerTransactionsList = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
};

const PaymentRefrenceIconStyle = {
  color: "FF5A01",
  background: "transparent",
  cursor: "pointer",
  transform: "rotate(90deg)",
};

export const ViewAndEditBankTransaction = ({
  isOpenTxnDialog,
  setIsOpenTxnDialog,
  txnMode,
  txnData,
  allocation,
  navigatedFrom = "BANK_TRANSACTION",
  handleReloadAccountsData = () => { }, // re check
}) => {
  // Selectors
  const { userData } = useSelector((state) => state?.user);
  const accountsList = useSelector(
    (state) => state?.bankStatmentTransactions?.accountsList
  );
  const brokerBranches = useSelector(
    (state) => state?.bankStatmentTransactions?.brokerBranchesList
  );
  const dropdownValuesCurreny = useSelector(
    (state) => state?.bankStatmentTransactions?.currencyList
  );

  const [fieldsData, setFieldsData] = useState(
    JSON.parse(JSON.stringify(txnData))
  );
  const [validationStatus, setValidationStatus] = useState({});
  const [currentUpdatedFields, setCurrentUpdatedFields] = useState({});
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState(false);
  const [isDisableUpdateBtn, setIsDisableUpdateBtn] = useState(false);
  const [currentWorkflowDetails, setCurrentWorkflowDetails] = useState([]);
  const [openPaymentRefDialog, setOpenPaymentRefDialog] = useState(false);
  const [dialogInputValue, setDialogInputValue] = useState("");
  const [openCommentsDialog, setOpenCommentsDialog] = useState(false)
  const [dialogInputCommentsValue, setDialogInputCommentsValue] = useState(null);
  const [initialTransactionStatus] = useState(txnData.transaction_status)
  const handlePriceEditCloseDialog = () => {
    setOpenEditPriceDialog(false);
  };

  const handleOpenPaymentRefDialog = (currentRowPaymentRefData) => {
    setOpenPaymentRefDialog(true);
    setDialogInputValue(currentRowPaymentRefData);
  };

  const handleOpenCommentsDialog = (currentRowCommentsData) => {
    setOpenCommentsDialog(true);
    setDialogInputCommentsValue(currentRowCommentsData);
  };

  const handleClosePaymentRefDialog = () => {
    setOpenPaymentRefDialog(false);
  };

  const handleCloseCommentsDialog = () => {
    setOpenCommentsDialog(false);
    setDialogInputCommentsValue(null);
  };

  const handleDialogInputChange = (e) => {
    setDialogInputValue(e.target.value);
  };

  const handleDialogCommentsInputChange = (e) => {
    setDialogInputCommentsValue(e.target.value);
  };

  const handleSavePaymentReference = () => {
    setOpenPaymentRefDialog(false);
    handleInputChange("Payment_Reference", dialogInputValue);
  };

  const handleSaveComments = () => {
    setOpenCommentsDialog(false);
    handleInputChange("comment", dialogInputCommentsValue);
  };

  //inline styles
  const inlineStyles = {
    paymentReceiveDate: {
      height: "38px",
      backgroundColor: "white",
      padding: "0px 10px",
      color: txnMode?.toUpperCase() === "VIEW" ? "grey" : "#000000de",
      border: "1px solid #b8b8b8",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize: "15px",
      fontWeight: 400,
    },
    receivableAmountEdit: {
      position: "absolute",
      top: "28px",
      left: "210px",
      color: "#3b3f44",
    },
    paymentReference: {
      height: "32",
      padding: "0px",
    },
  };

  const validateTxnDetails = () => {
    const fieldValidations = {};

    // Perform your validations here
    if (
      !fieldsData ||
      !fieldsData.Payment_Receive_Date ||
      !fieldsData.Payment_Receive_Date.trim() === ""
    ) {
      fieldValidations.Payment_Receive_Date = "This field is required";
    }

    if (
      !fieldsData ||
      !fieldsData.Payment_Reference ||
      !fieldsData.Payment_Reference.trim() === ""
    ) {
      fieldValidations.Payment_Reference = "This field is required";
    }

    // Update the logical OR conditions
    if (
      !fieldsData ||
      !fieldsData.Payment_Currency_Code ||
      !fieldsData.Payment_Currency_Code.trim() === ""
    ) {
      fieldValidations.Payment_Currency_Code = "This field is required";
    }

    if (
      !fieldsData ||
      !fieldsData.Receivable_Amount ||
      !fieldsData.Receivable_Amount.trim() === ""
    ) {
      fieldValidations.Receivable_Amount = "This field is required";
    }

    if (
      !fieldsData ||
      !fieldsData.Receiving_Bank_Account ||
      !fieldsData.Receiving_Bank_Account.trim() === ""
    ) {
      fieldValidations.Receiving_Bank_Account = "This field is required";
    }

    if (
      !fieldsData ||
      !fieldsData.Broker_Branch ||
      !fieldsData.Broker_Branch.trim() === ""
    ) {
      if (fieldsData.txn_category !== "Non Premium") {
        fieldValidations.Broker_Branch = "This field is required";
      }
    }

    if (fieldsData?.txn_category === "Non Premium" && (!fieldsData.comment || fieldsData.comment.trim() === "")) {
      fieldValidations.comment = "Comment is required when Txn Categorization is 'Non Premium'";
    }

    // Disable Update Button
    setIsDisableUpdateBtn(Object.keys(fieldValidations).length === 0 ? false : true)

    // Update alidation status
    setValidationStatus({ ...fieldValidations });

    return Object.keys(fieldValidations).length === 0;
  };

  const handleBlur = (colName, value) => {
    // If the value is float
    let parsedValue = value;
    if (!Number.isInteger(value)) {
      switch (colName) {
        case "Bank_Charges":
        case "Bank_Exchange_Charges":
        case "Receivable_Amount":
          parsedValue = parseFloat(value).toFixed(2);
          break;
        case "Bank_Exchange_Rate":
          parsedValue = parseFloat(value).toFixed(6);
          break;
        default:
      }
    }
    setFieldsData({
      ...fieldsData,
      [colName]: parsedValue,
    });
  };

  useEffect(() => {
    validateTxnDetails();
  }, []);
  
  useEffect(() => {
    if (currentUpdatedFields["Broker_Branch"]) {
      instance
        .post(getBrokerBranchDetailsByBrokerBranchName, {
          Broker_Branch_Name: currentUpdatedFields?.["Broker_Branch"],
        })
        .then((response) => {
          setCurrentUpdatedFields({
            ...currentUpdatedFields,
            broker_information: response?.data?.id,
            Broker: response?.data?.broker_name
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (currentUpdatedFields["Broker_Branch"] === "") {
      setCurrentUpdatedFields({
        ...currentUpdatedFields,
      });
    }
  }, [currentUpdatedFields["Broker_Branch"]]);

  const getBankDetails = (accNo) => {
    if (accNo) {
      const getInfo = accountsList?.filter(
        (item) => item.account_number === accNo
      );
      const { bank_name, entity_name, currency, id } = getInfo[0];

      const objToUpdate = {
        Bank_Account_Name_Entity: bank_name,
        PT_Receving_Bank_Name: entity_name,
        Bank_Currency_Code: currency,
        bank_details: id,
      };

      setFieldsData({
        ...fieldsData,
        ...objToUpdate,
      });
      setCurrentUpdatedFields({
        ...currentUpdatedFields,
        ...objToUpdate,
      });
    }
  };

  const editBankFieldsErrorMessages = {
    Bank_Exchange_Rate: "Exchange Rate cannot be negative.",
    Bank_Exchange_Charges: "Exchange Charges cannot be negative.",
    Bank_Charges: "Bank Charges cannot be negative.",
  };

  const handleInputChange = (colName, value, reason, event) => {
    // Prevent clearing the value on blur
    if (reason === "blur") {
      return;
    }
    if (
      ["Bank_Exchange_Rate", "Bank_Exchange_Charges", "Bank_Charges"].includes(
        colName
      ) &&
      value < 0
    ) {
      toast.error(editBankFieldsErrorMessages[colName], {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    setFieldsData({
      ...fieldsData,
      [colName]: reason === "clear" ? "" : value ?? "",
    });

    // To send only updated information for API
    setCurrentUpdatedFields({
      ...currentUpdatedFields,
      [colName]: reason === "clear" ? "" : value ?? "",
    });

    if (colName === "Receiving_Bank_Account") {
      if (value) {
        getBankDetails(value);
      } else {
        setFieldsData({
          ...fieldsData,
          Receiving_Bank_Account: "",
          Bank_Account_Name_Entity: "",
          PT_Receving_Bank_Name: "",
          Bank_Currency_Code: "",
        });
      }
    }

    if (colName === "Broker_Branch") {
      if (reason === "clear") {
        setIsDisableUpdateBtn(true);
      } else {
        setIsDisableUpdateBtn(false);
      }
    }

    // Auto-select logic for Txn Categorization and Broker Branch
    if (colName === "txn_category") {
      if (value === "Non Premium") {
        setFieldsData({
          ...fieldsData,
          Broker_Branch: "Investment",
          txn_category: "Non Premium",
        });
        setCurrentUpdatedFields({
          ...currentUpdatedFields,
          Broker_Branch: "Investment",
          txn_category: "Non Premium",
        });
      }
    } else if (colName === "Broker_Branch") {
      if (value === "Investment") {
        setFieldsData({
          ...fieldsData,
          Broker_Branch: "Investment",
          txn_category: "Non Premium",
        });
        setCurrentUpdatedFields({
          ...currentUpdatedFields,
          Broker_Branch: "Investment",
          txn_category: "Non Premium",
        });
      }
    }
    
    if (colName === "comment") {
      validateTxnDetails(); // Re-validate whenever comment changes
    }
  };

  useEffect(() => {
    if (fieldsData.Broker_Branch === "Investment") {
      validateTxnDetails();
    }
  }, [fieldsData, currentUpdatedFields]);

  const handleUpdateBtn = async () => {
    if (fieldsData.txn_category === "Non Premium") {
      currentUpdatedFields.transaction_status = "Completed";
    }
    if (validateTxnDetails()) {
      const reqBody = {
        ...currentUpdatedFields,
        id: fieldsData.id,
        updated_by: userData?.user_name ?? "",
      };
      try {
        setIsDisableUpdateBtn(true);
        const response = await instance.patch(
          BankTransaction + fieldsData?.id + "/",
          reqBody
        );

        if (response) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsDisableUpdateBtn(false);
          setIsOpenTxnDialog(false);
          handleReloadAccountsData();
          // clear previously updated stored data
          setCurrentUpdatedFields({});
        }
      } catch (error) {
        setIsDisableUpdateBtn(false);
        toast.error("Failed to update. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsDisableUpdateBtn(false);
      }
    }
  };


  const handleEditPriceButton = async () => {
    const currentWorkflowName = "CHANGE_BANK_TRANSACTION_AMOUNT";
    const queryPrams = `workflow_name=${currentWorkflowName}&bank_txn_id=${txnData.Bank_Transaction_Id}`;
    const response = await instance.get(getBankTxnWorkflowStatus + queryPrams);

    if (response?.data) {
      setCurrentWorkflowDetails(
        response?.data?.length > 0 ? response?.data[0] : []
      );
      setOpenEditPriceDialog(true);
    }
  };

  return (
    <>
      <Dialog
        open={isOpenTxnDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          {txnMode === "EDIT"
            ? "Edit Bank Transaction"
            : "View Bank Transaction"}
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenTxnDialog(false)}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>

        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <Grid display={"flex"} flexDirection={"column"}>
            <Card elevation={5} style={EditPageContainer}>
              <Grid container style={EditpageContainerItems} spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel>
                    Select Account No
                    {txnMode === "EDIT" && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </FormLabel>
                  <CommonSelect
                    name="accountNo"
                    placeholder="Select Account No"
                    value={fieldsData?.Receiving_Bank_Account}
                    handleChange={(event, value, reason) =>
                      handleInputChange(
                        "Receiving_Bank_Account",
                        value,
                        reason,
                        event
                      )
                    }
                    options={
                      accountsList?.map((option) =>
                        option?.account_number?.trim()
                      ) || []
                    }
                    disabled={txnMode === "VIEW" || txnMode === "EDIT"}
                  />
                  {validationStatus?.Receiving_Bank_Account && (
                    <Typography sx={{ color: "red", fontSize: "13px" }}>
                      {validationStatus?.Receiving_Bank_Account}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel>Bank Name</FormLabel>
                  <TextField
                    value={fieldsData?.bank_details.bank_name ?? ""}
                    size="small"
                    disabled={txnMode === "VIEW" || txnMode === "EDIT"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel>Entity Name</FormLabel>
                  <TextField
                    value={fieldsData?.bank_details?.entity_name ?? ""}
                    size="small"
                    disabled={txnMode === "VIEW" || txnMode === "EDIT"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel>Payment/Bank Ccy</FormLabel>
                  <TextField
                    value={fieldsData?.Bank_Currency_Code ?? ""}
                    size="small"
                    disabled={txnMode === "VIEW" || txnMode === "EDIT"}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  {
                    fieldsData?.document_files[0]?.document_url && (
                      <>
                        <FormLabel sx={{ marginRight: "5px" }}>
                          Uploaded File
                        </FormLabel>
                        <a
                          href={fieldsData?.document_files[0]?.document_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <DownloadIcon />{" "}
                          {fieldsData?.document_files[0]?.document_name ?? ""}
                        </a>
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <div elevation={6} style={EditPageContainerTransactions}>
            <div style={EditPageContainerTransactionsList}>
              <p style={{ color: "black" }}>Transactions</p>
              <Grid xs={12} container gap={1}>
                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel id="type-label">Payment Receive Date</FormLabel>
                  <Input
                    type="date"
                    value={
                      fieldsData?.Payment_Receive_Date?.substring(0, 10) ?? ""
                    }
                    onChange={(e) =>
                      handleInputChange("Payment_Receive_Date", e.target.value)
                    }
                    style={inlineStyles.paymentReceiveDate}
                    placeholder="Enter Details Here"
                    disabled={txnMode === "VIEW"}
                  />
                  {validationStatus?.Payment_Receive_Date && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Payment_Receive_Date}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Payment Reference</FormLabel>
                  <TextField
                    value={fieldsData?.Payment_Reference ?? ""}
                    onChange={(e) =>
                      handleInputChange("Payment_Reference", e.target.value)
                    }
                    sx={inlineStyles.paymentReference}
                    size="small"
                    placeholder="Enter Details Here"
                    disabled={txnMode === "VIEW"}
                    InputProps={{
                      endAdornment: (
                        <MoreVertIcon
                          onClick={() =>
                            handleOpenPaymentRefDialog(
                              fieldsData.Payment_Reference
                            )
                          }
                          style={PaymentRefrenceIconStyle}
                        />
                      ),
                    }}
                  />
                  {validationStatus?.Payment_Reference && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Payment_Reference}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Payment/Bank Ccy</FormLabel>
                  <CommonSelect
                    disabled={txnMode === "VIEW"}
                    handleChange={(event, value, reason) =>
                      handleInputChange(
                        "Payment_Currency_Code",
                        value,
                        reason,
                        event
                      )
                    }
                    name="payment currncy code"
                    // Map through your list of dropdown values
                    options={
                      dropdownValuesCurreny?.map(
                        (option) => option.currency_code
                      ) ?? []
                    }
                    placeholder="Select Currency"
                    value={fieldsData?.Payment_Currency_Code ?? ""}
                  />
                  {validationStatus?.Payment_Currency_Code && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Payment_Currency_Code}
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  position={"relative"}
                  mt={"20px"}
                >
                  <FormLabel>Rcvd Settlement Amt</FormLabel>
                  <TextField
                    value={numberFormatter(
                      parseFloat(fieldsData?.Receivable_Amount)
                    )}
                    onChange={(e) =>
                      handleInputChange("Receivable_Amount", e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur("Receivable_Amount", e.target.value)
                    }
                    size="small"
                    type="text" // Set the input type to "number"
                    InputProps={{
                      inputProps: {
                        min: 0, // Set minimum value to 0 if needed
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                          if (regex.test(value) || value === "") {
                            handleInputChange("Receivable_Amount", value);
                          }
                        },
                      },
                    }}
                    disabled={true}
                  />

                  {txnMode === "EDIT" && (
                    <IconButton
                      onClick={() => handleEditPriceButton()}
                      sx={inlineStyles.receivableAmountEdit}
                    >
                      <EditIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  )}

                  {validationStatus?.Receivable_Amount && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Receivable_Amount}
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Broker Branch</FormLabel>
                  <CommonSelect
                    disabled={txnMode === "VIEW"}
                    handleChange={(event, value, reason) =>
                      handleInputChange("Broker_Branch", value, reason, event)
                    }
                    name="brokerBranch"
                    options={brokerBranches?.map((option) => option) ?? []}
                    placeholder="Select Broker Branch"
                    value={fieldsData.Broker_Branch ?? ""}
                  />
                  {txnMode === 'EDIT' && validationStatus?.Broker_Branch && (
                    <span  style={{ color: "red" }}>
                      {validationStatus?.Broker_Branch}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Exchange Rate</FormLabel>
                  <TextField
                    value={txnMode === "VIEW" ? numberFormatter(fieldsData?.Bank_Exchange_Rate) ?? "" : fieldsData?.Bank_Exchange_Rate ?? ""}
                    onChange={(e) =>
                      handleInputChange("Bank_Exchange_Rate", e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur("Bank_Exchange_Rate", e.target.value)
                    }
                    size="small"
                    type="number" // Set the input type to "number"
                    InputProps={{
                      inputProps: {
                        min: 0, // Set minimum value to 0 if needed
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                          if (regex.test(value) || value === "") {
                            handleInputChange("Bank_Exchange_Rate", value);
                          }
                        },
                      },
                    }}
                    disabled={txnMode === "VIEW"}
                    className="right-align"
                  />
                  {validationStatus?.Bank_Exchange_Rate && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Bank_Exchange_Rate}
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Exchange Charges</FormLabel>
                  <TextField
                    value={txnMode === "VIEW" ? numberFormatter(fieldsData?.Bank_Exchange_Charges) ?? "" : fieldsData?.Bank_Exchange_Charges ?? ""}
                    onChange={(e) =>
                      handleInputChange("Bank_Exchange_Charges", e.target.value)
                    }
                    onBlur={(e) =>
                      handleBlur("Bank_Exchange_Charges", e.target.value)
                    }
                    size="small"
                    type="number" // Set the input type to "number"
                    InputProps={{
                      inputProps: {
                        min: 0, // Set minimum value to 0 if needed
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                          if (regex.test(value) || value === "") {
                            handleInputChange("Bank_Exchange_Charges", value);
                          }
                        },
                      },
                    }}
                    disabled={txnMode === "VIEW"}
                    className="right-align"
                  />
                  {validationStatus?.Bank_Exchange_Charges && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Bank_Exchange_Charges}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Bank Charges</FormLabel>
                  <TextField
                    value={txnMode === "VIEW" ? numberFormatter(fieldsData?.Bank_Charges) ?? "" : fieldsData?.Bank_Charges ?? ""}
                    onChange={(e) =>
                      handleInputChange("Bank_Charges", e.target.value)
                    }
                    onBlur={(e) => handleBlur("Bank_Charges", e.target.value)}
                    size="small"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          const regex = /^\d*\.?\d*$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange("Bank_Charges", value);
                          }
                        },
                      },
                    }}
                    disabled={txnMode === "VIEW"}
                    className="right-align"
                  />
                  {validationStatus?.Bank_Charges && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Bank_Charges}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel id="type-label">Batch Ref</FormLabel>
                  <TextField
                    type="text"
                    value={fieldsData?.batch_ref ?? ""}
                    onChange={(e) =>
                      handleInputChange("batch_ref", e.target.value)
                    }
                    size="small"
                    placeholder="Enter Batch Ref Here"
                    disabled={txnMode === "VIEW"}
                  />
                  {validationStatus?.batch_ref && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.batch_ref}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel>Txn Categorization</FormLabel>
                  <CommonSelect
                    disabled={txnMode === "VIEW"}
                    handleChange={(event, value, reason) =>
                      handleInputChange("txn_category", value, reason, event)
                    }
                    name="Txn Categorization"
                    // Include your new dropdown values
                    options={[
                      "Bank Charges",
                      "Broker Comission",
                      "Internal Transfer",
                      "M &A Fees",
                      "Premium Payment",
                      "Non Premium",
                      "Manual Refund Correction",
                      "Others",
                      "Refund",
                      "Rounding",
                    ]}
                    placeholder="Select Currency"
                    value={fieldsData?.txn_category ?? ""}
                  />

                  {validationStatus?.Payment_Currency_Code && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.Payment_Currency_Code}
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel id="type-label">Txn Status</FormLabel>
                  <CommonSelect
                    disabled={txnMode === "VIEW" || initialTransactionStatus !== "Open"}
                    handleChange={(event, value, reason) =>
                      handleInputChange("transaction_status", value, reason, event)
                    }
                    name="transaction_status"
                    // Include your new dropdown values
                    options={initialTransactionStatus === "Open" ? [
                      "Open",
                      "Query - Broker",
                      "Query - F&A"
                    ] : []}
                    placeholder="Select Transaction Status"
                    value={fieldsData?.transaction_status ?? ""}
                  />
                  {validationStatus?.transaction_status && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.transaction_status}
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                  mt={"20px"}
                >
                  <FormLabel id="type-label">Comment</FormLabel>
                  <TextField
                    type="text"
                    value={fieldsData?.comment ?? ""}
                    onChange={(e) =>
                      handleInputChange("comment", e.target.value)
                    }
                    size="small"
                    placeholder="Enter Comment Here"
                    disabled={txnMode === "VIEW"}
                    InputProps={{
                      endAdornment: (
                        <MoreVertIcon
                          onClick={() =>
                            handleOpenCommentsDialog(
                              fieldsData.comment
                            )
                          }
                          style={PaymentRefrenceIconStyle}
                        />
                      ),
                    }}
                  />
                  {validationStatus?.comment && (
                    <span style={{ color: "red" }}>
                      {validationStatus?.comment}
                    </span>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {txnMode === "EDIT" && (
            <CommonButton
              sx={editBankTransactionUpdate}
              text="Update"
              handleClick={() => handleUpdateBtn()}
              disabled={isDisableUpdateBtn}
              hoverColor="#FF5A01"
            />
          )}
        </DialogActions>
      </Dialog>

      {openEditPriceDialog && (
        <EditPriceDialog
          openEditPriceDialog={openEditPriceDialog}
          handlePriceEditCloseDialog={handlePriceEditCloseDialog}
          currentWorkflowDetails={currentWorkflowDetails}
          txnData={fieldsData}
          disabled={false}
          navigatedFrom={navigatedFrom}
        />
      )}

      <Dialog open={openPaymentRefDialog} onClose={handleClosePaymentRefDialog}>
        <DialogTitle>Payment Reference</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogInputValue}
            onChange={handleDialogInputChange}
            className="PaymentReferenceDialogue"
            disabled={txnMode === "VIEW"}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleClosePaymentRefDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSavePaymentReference}
            hoverColor="#FF5A01"
            disabled={txnMode === "VIEW"}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openCommentsDialog} onClose={handleCloseCommentsDialog}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogInputCommentsValue}
            onChange={handleDialogCommentsInputChange}
            className="PaymentReferenceDialogue"
            disabled={txnMode === "VIEW"}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseCommentsDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSaveComments}
            hoverColor="#FF5A01"
            disabled={txnMode === "VIEW"}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
