// common
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { doughnutColors, colorSetFor3d } from "./ChartColors";

const GenericDonutChart = ({
  colorsList,
  data,
  dataCy,
  percentageInnerDonut,
  title,
  labelSuffix = "",
  displayDonutInnerText = true,
}) => {
  const formattedDonutData = data?.datasets?.at(0)?.data;

  const colors = () => {
    if (colorsList?.length > 0) {
      return colorsList;
    } else {
      return colorSetFor3d.map((item) => item);
    }
  };

  useEffect(() => {
    const root = am5.Root.new(title);

    displayDoughnutChart(root);

    return () => {
      root.dispose();
    };
  }, [formattedDonutData]);

  const getBgColors = (index) => {
    if (colorsList?.length > 0) {
      return { backgroundColor: `${colorsList[index]}` };
    } else {
      return { backgroundColor: `${colorSetFor3d[index]}` };
    }
  };

  const CustomLegendLabels = () => {
    return (
      <div style={{ marginLeft: "20px" }}>
        {formattedDonutData.map((item, index) => (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span
              key={item.title}
              className="legendLabel ms-2 me-2"
              style={{ ...getBgColors(index), borderRadius: 3 }}
            />
            <span style={{ fontSize: "14px" }}>{item.title}</span>
          </div>
        ))}
      </div>
    );
  };

  const displayDoughnutChart = (root) => {
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        centerX: am5.percent(0),
        centerY: am5.percent(1),
        radius: am5.percent(99),
        innerRadius: am5.percent(68),
        hoverOnFocus: true,
        isMeasered: true,
        autoMargins: false,
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "count",
        categoryField: "title",
        alignLabels: true,
      })
    );

    // slice colors
    series.get("colors").set("colors", colors());

    // If we click on a slice in a pie series it will pull out of the place:disabled
    series.slices.template.set("toggleKey", "none");

    // disables slice ticks
    series.ticks.template.setAll({ forceHidden: true });
    // disabled tick labels
    series.labels.template.setAll({ forceHidden: true });
    // disabled hovering effect
    series.slices.template.states.create("hover", { scale: 1 });

    if (displayDonutInnerText) {
      series.children.push(
        am5.Label.new(root, {
          text: percentageInnerDonut,
          inside: true,
          isMeasured: false,
          fontSize: 18,
          fontWeight: 700,
          paddingLeft: percentageInnerDonut.length >= 2 ? 2 : 1,
          fontFamily: "Avenir, Arial, san-Serif",
          textBaseline: "bottom",
          lineHeight: 0.5,
          textAlign: "center",
          fill: "#003B5C",
          background:
            percentageInnerDonut.length > 0
              ? am5.Circle.new(root, {
                  shadowColor: am5.color("#0xd9d9d9"),
                  fill: doughnutColors.Mosaic_White,
                  shadowBlur: 10,
                  shadowOffsetX: 2,
                  shadowOffsetY: 10,
                  scale: 3.8,
                  opacity: 1,
                })
              : null,
        })
      );
    }

    series.slices.template.setAll({
      focusable: false,
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    // Display tooltip
    if (!data?.disableTooltips) {
      // configure tooltip
      let tooltip = am5.Tooltip.new(root, {
        autoTextColor: false,
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        layer: 20,
        layerMargin: {
          left: 100,
          right: 100,
          top: 0,
          bottom: 10,
        },
        dy: 8,
        hoverOnFocus: false,
        getLabelFillFromSprite: false,
      });

      tooltip.label.adapters.add("text", function (labelText, target) {
        if (target && target?._dataItem?.dataContext?.providers) {
          return getOtherProvidersList(
            target?._dataItem?.dataContext?.providers
          );
        } else {
          return `${target?._dataItem?.dataContext?.title} : ${target?._dataItem?.dataContext?.count}${labelSuffix}`;
        }
      });

      tooltip.get("background").setAll({
        fill: doughnutColors.Mosaic_White,
        fillOpacity: 1,
        strokeWidth: 1,
      });

      tooltip.label.setAll({
        fill: doughnutColors.Mosaic_textBlue,
        fontSize: 10,
        fontFamily: "$base-font-family",
      });

      // set tooltip
      series.set("tooltip", tooltip);
    }

    function getOtherProvidersList(providersData) {
      return providersData.join("\n");
    }

    series.data.setAll(formattedDonutData);

    // added doughnut inner text with styles
    root._logo.dispose();
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        id={title}
        style={{ width: 300, height: 200, marginBottom: 10 }}
        data-cy={dataCy}
      />

      <CustomLegendLabels />
    </div>
  );
};

export default GenericDonutChart;
