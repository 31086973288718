const initialState = {
    isOpen: true
}

const toggleSideMenu = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_ICONS':
            return {
                ...state,
                isOpen: !state.isOpen
            }
        default:
            return state;
    }
}

export default toggleSideMenu